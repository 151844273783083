import React, { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { showLoading, hideLoading } from "../../../redux/LoaderSlice";
import ApiService from "../../../services/ApiService";
import {
  FormObjectProcessor,
  FormatInput,
} from "../../../services/DataProcessor";
import ErrorValidation from "../../../services/ErrorValidation";
import {
  AlphabetRegex,
  PhoneRegex,
  PinCodeRegex,
} from "../../../static/RegexPattern";
import { EncodeString } from "../../../services/TableUtilities";
import TopBar from "../../../components/topBar/TopBar";
import { Form, Input, Select, Button, Row, Col } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import toast from "react-hot-toast";
import "./EmployeeAccount.scss";

const EmployeeAccount = ({ type = "add" }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [employeeData, setEmployeeData] = useState([]);
  const [dataIsReady, setDataIsReady] = useState(false);
  var Buffer = require("buffer/").Buffer;
  let responseObj = localStorage.getItem("token");
  if (responseObj) {
    responseObj = JSON.parse(Buffer.from(responseObj, "base64").toString());
  }
  const employeeID = responseObj?.employeeData?._id;
  const apiURL = "/employees/" + employeeID;
  let encodedEntityID = null;
  if (employeeID) {
    encodedEntityID = EncodeString(employeeID);
  }

  const getEmployeeData = async () => {
    dispatch(showLoading());
    const apiResponse = await ApiService("get", apiURL, null);
    const apiData = apiResponse?.response;
    if (apiData) {
      setEmployeeData(apiData);
      setDataIsReady(true);
      dispatch(hideLoading());
    } else {
      const apiError = apiResponse?.error;
      let errorMessage = ErrorValidation(apiError?.response?.status);
      toast.error(errorMessage);
    }
  };

  const onFormSubmit = async (values) => {
    if (values) {
      let requestObj = FormObjectProcessor(values);

      if (type === "add") {
        requestObj["IsAccountSetup"] = true;
      }

      dispatch(showLoading());
      const apiResponse = await ApiService("put", apiURL, requestObj);
      const apiData = apiResponse?.response;
      if (apiData) {
        setEmployeeData(apiData);
        setDataIsReady(true);
        dispatch(hideLoading());
        toast.success("Profile Data Saved Successfully!");
        // navigate("../employees");
      } else {
        const apiError = apiResponse?.error;
        let errorMessage = ErrorValidation(apiError?.response?.status);
        toast.error(errorMessage);
        dispatch(hideLoading());
      }
    }
  };

  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    let responseObj = localStorage.getItem("token");
    if (responseObj) {
      responseObj = JSON.parse(Buffer.from(responseObj, "base64").toString());
    } else {
      navigate("/login");
    }

    let employeeLoggedInStatus = responseObj?.employeeLoginStatus;
    if (employeeLoggedInStatus) {
      getEmployeeData();
    }
  }, []);

  return (
    <>
      {dataIsReady && (
        <div
          className={
            type === "edit"
              ? "employeeAccountContainer"
              : "employeeAccountContainer add"
          }
        >
          {type === "add" && <TopBar disableLink={true} />}
          <div className="employeeAccount">
            <div className="headerStart">
              {type === "edit" && (
                <ArrowLeftOutlined
                  className="backArrowButton"
                  onClick={() => {
                    navigate("/employees");
                  }}
                />
              )}

              <h2
                className={
                  type === "edit"
                    ? "employeeAccountTitle"
                    : "employeeAccountTitle add"
                }
              >
                {type === "add" ? "Create a Profile" : "Edit Profile"}
              </h2>
              {type !== "add" && (
                <Button
                  type="primary"
                  onClick={() => {
                    navigate("../resetpassword/" + encodedEntityID);
                  }}
                >
                  Change Password
                </Button>
              )}
            </div>
            {type === "add" ? (
              <p>Let us know more about you by filling the below fields</p>
            ) : null}

            <Form
              form={form}
              className="employeeAccountForm"
              layout="vertical"
              onFinish={onFormSubmit}
              onFinishFailed={onFinishFailed}
            >
              <div className="employeeAccountFormFields">
                <Row
                  gutter={{
                    xs: 8,
                    sm: 16,
                    md: 24,
                    lg: 32,
                  }}
                >
                  <Col className="gutter-row" span={6}>
                    <Form.Item
                      label="Email"
                      name="Email"
                      initialValue={employeeData?.Email}
                      onBlur={(e) => FormatInput(e, form, "email")}
                    >
                      <Input placeholder="Email" disabled={true} />
                    </Form.Item>
                  </Col>

                  <Col className="gutter-row" span={6}>
                    <Form.Item
                      label="Role"
                      name="Role"
                      initialValue={employeeData?.Role}
                    >
                      <Select disabled={true} />
                    </Form.Item>
                  </Col>
                  <Col className="gutter-row" span={6}>
                    <Form.Item
                      label="First Name"
                      name="Name-FNAME"
                      initialValue={employeeData?.Name?.FNAME}
                      rules={[
                        {
                          required: true,
                          message: "Enter First Name",
                        },
                        {
                          pattern: AlphabetRegex,
                          message: "Enter valid First Name",
                        },
                        {
                          max: 50,
                          message: "First Name limit exceeded!",
                        },
                      ]}
                      onBlur={(e) => FormatInput(e, form)}
                    >
                      <Input placeholder="Example: Raj" />
                    </Form.Item>
                  </Col>
                  <Col className="gutter-row" span={6}>
                    <Form.Item
                      label="Last Name"
                      name="Name-LName"
                      initialValue={employeeData?.Name?.LName}
                      rules={[
                        {
                          required: true,
                          message: "Enter Last Name",
                        },
                        {
                          pattern: AlphabetRegex,
                          message: "Enter valid Last Name",
                        },
                        {
                          max: 50,
                          message: "Last Name limit exceeded!",
                        },
                      ]}
                      onBlur={(e) => FormatInput(e, form)}
                    >
                      <Input placeholder="Example: Kumar" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row
                  gutter={{
                    xs: 8,
                    sm: 16,
                    md: 24,
                    lg: 32,
                  }}
                >
                  <Col className="gutter-row" span={6}>
                    <Form.Item
                      label="Primary Phone Number"
                      name="Phone-Primary"
                      initialValue={employeeData?.Phone?.Primary}
                      rules={[
                        {
                          required: true,
                          message: "Enter Phone number",
                        },
                        {
                          pattern: PhoneRegex,
                          message: "Enter valid Phone number",
                        },
                      ]}
                    >
                      <Input placeholder="Primary Phone Number" />
                    </Form.Item>
                  </Col>

                  <Col className="gutter-row" span={6}>
                    <Form.Item
                      label="Alternate Phone Number"
                      name="Phone-Alternate"
                      initialValue={employeeData?.Phone?.Alternate}
                      rules={[
                        {
                          pattern: PhoneRegex,
                          message: "Enter valid Phone number",
                        },
                      ]}
                    >
                      <Input placeholder="Alternate Phone Number" />
                    </Form.Item>
                  </Col>

                  <Col className="gutter-row" span={6}>
                    <Form.Item
                      label="Street Address"
                      name="Address-Street"
                      initialValue={employeeData?.Address?.Street}
                      rules={[
                        {
                          required: true,
                          message: "Enter Street Address",
                        },
                        {
                          max: 50,
                          message: "Street Address limit exceeded!",
                        },
                      ]}
                      onBlur={(e) => FormatInput(e, form)}
                    >
                      <Input placeholder="Street Address" />
                    </Form.Item>
                  </Col>
                  <Col className="gutter-row" span={6}>
                    <Form.Item
                      label="City / Town / Village"
                      name="Address-City"
                      initialValue={employeeData?.Address?.City}
                      rules={[
                        {
                          required: true,
                          message: "Enter City Address",
                        },
                        {
                          max: 50,
                          message: "City Address limit exceeded!",
                        },
                        {
                          pattern: AlphabetRegex,
                          message: "Enter valid City Address",
                        },
                      ]}
                      onBlur={(e) => FormatInput(e, form)}
                    >
                      <Input placeholder="City" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row
                  gutter={{
                    xs: 8,
                    sm: 16,
                    md: 24,
                    lg: 32,
                  }}
                >
                  <Col className="gutter-row" span={6}>
                    <Form.Item
                      label="Taluka / Subdivision"
                      name="Address-Taluka"
                      initialValue={employeeData?.Address?.Taluka}
                      rules={[
                        {
                          required: true,
                          message: "Enter Taluka Address",
                        },
                        {
                          max: 50,
                          message: "Taluka Address limit exceeded!",
                        },
                        {
                          pattern: AlphabetRegex,
                          message: "Enter valid Taluka Address",
                        },
                      ]}
                      onBlur={(e) => FormatInput(e, form)}
                    >
                      <Input placeholder="Taluka" />
                    </Form.Item>
                  </Col>

                  <Col className="gutter-row" span={6}>
                    <Form.Item
                      label="District"
                      name="Address-District"
                      initialValue={employeeData?.Address?.District}
                      rules={[
                        {
                          required: true,
                          message: "Enter District Address",
                        },
                        {
                          max: 50,
                          message: "District Address limit exceeded!",
                        },
                        {
                          pattern: AlphabetRegex,
                          message: "Enter valid District Address",
                        },
                      ]}
                      onBlur={(e) => FormatInput(e, form)}
                    >
                      <Input placeholder="District" />
                    </Form.Item>
                  </Col>

                  <Col className="gutter-row" span={6}>
                    <Form.Item
                      label="State"
                      name="Address-State"
                      initialValue={employeeData?.Address?.State}
                      rules={[
                        {
                          required: true,
                          message: "Enter State Address",
                        },
                        {
                          max: 50,
                          message: "State Address limit exceeded!",
                        },
                        {
                          pattern: AlphabetRegex,
                          message: "Enter valid State Address",
                        },
                      ]}
                      onBlur={(e) => FormatInput(e, form)}
                    >
                      <Input placeholder="State" />
                    </Form.Item>
                  </Col>
                  <Col className="gutter-row" span={6}>
                    <Form.Item
                      label="Pincode"
                      name="Address-PinCode"
                      initialValue={employeeData?.Address?.PinCode}
                      rules={[
                        {
                          required: true,
                          message: "Enter Pincode",
                        },
                        {
                          pattern: PinCodeRegex,
                          message: "Enter valid PinCode",
                        },
                      ]}
                    >
                      <Input placeholder="Pin Code" />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
              <Button
                className="employeeAccountFormButton"
                type="primary"
                htmlType="submit"
              >
                Save
              </Button>
            </Form>

            {/* )} */}

            {/* {setupComplete && <Navigate to="" replace={true} />} */}
          </div>
        </div>
      )}
    </>
  );
};

export default EmployeeAccount;
