import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { showLoading, hideLoading } from "../../redux/LoaderSlice";
import bcryptjs from "bcryptjs";
import sha256 from "crypto-js/sha256";
import ApiService from "../../services/ApiService";
import { FormatInput } from "../../services/DataProcessor";
import ErrorValidation from "../../services/ErrorValidation";
import { Link, useNavigate } from "react-router-dom";
import {
  UserOutlined,
  MailOutlined,
  SecurityScanOutlined,
} from "@ant-design/icons";
import { Form, Input, Select, Button } from "antd";
// import { InputOTP } from "antd-input-otp";
import toast from "react-hot-toast";
import dayjs from "dayjs";
import "./Login.scss";
import { PasswordRegex } from "../../static/RegexPattern";
import { EncodeString } from "../../services/TableUtilities";

const dateFormat = "DD-MM-YYYY";
var Buffer = require("buffer/").Buffer;
export default function Login() {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [formValues, setFormValues] = useState(null);
  const [roleType, setRoleType] = useState("");
  const [displayForgotPassword, setDisplayForgotPassword] = useState(false);
  const [disableFormButton, setDisableFormButton] = useState(false);
  const navigate = useNavigate();

  let apiURL = "/sessions";

  const dropDownOptions = [
    { value: "disabled", label: "Select a Role Type", disabled: true },
    { value: "Admin", label: "Admin" },
    { value: "Accountant", label: "Accountant" },
    { value: "CRM", label: "CRM" },
    { value: "HR", label: "HR" },
  ];

  const onSelectChange = (value) => {
    setRoleType(value);
  };

  const getEmployeesData = async (employeeEmail) => {
    let employeeID = null;
    apiURL = "/employees/";
    const apiResponse = await ApiService("get", apiURL);
    const apiData = apiResponse?.response;
    if (apiData) {
      apiData.forEach((record) => {
        if (employeeEmail !== "" && employeeEmail === record?.Email) {
          employeeID = record?._id;
        }
      });
    }
    return employeeID;
  };

  const updateEmployeeAccessResetLink = async (employeeID) => {
    let requestObj = {};
    let apiStatus = false;
    let accessResetLink = sha256(employeeID).toString();
    requestObj["AccessResetLink"] = accessResetLink;
    requestObj["AccessLinkValidity"] = new Date().toJSON();
    apiURL = "/employees/" + employeeID;
    const apiResponse = await ApiService("put", apiURL, requestObj);
    const apiData = apiResponse?.response;
    if (apiData) {
      apiStatus = true;
    }
    return apiStatus;
  };

  const onFinish = async (values) => {
    if (values) {
      dispatch(showLoading());
      if (!displayForgotPassword) {
        let requestObject = {
          EmployeeEmail: values?.email,
          Role: values?.role,
        };
        try {
          apiURL = "/sessions";
          const apiResponse = await ApiService("post", apiURL, requestObject);
          const apiData = apiResponse?.response;
          if (apiData && apiData.length === 2) {
            const postStatus = apiData[0];
            const isMatch = await bcryptjs.compare(
              values?.password,
              apiData[1]?.Access
            );

            if (postStatus && isMatch) {
              if (apiData[0] && apiData[1] !== null) {
                toast.success("You have logged in successfully!");
                const employeeData = apiData[1];
                let localStorageObject = {
                  employeeLoginStatus: true,
                  employeeData: employeeData,
                  dateToday: dayjs().format(dateFormat),
                  runAutoFunctions: true,
                };
                localStorageObject = EncodeString(
                  JSON.stringify(localStorageObject)
                );

                localStorage.setItem("token", localStorageObject);
                const accountSetup = employeeData?.IsAccountSetup;
                setTimeout(() => {
                  if (!accountSetup) {
                    navigate("/account");
                  } else {
                    navigate("/");
                  }
                }, 2000);
              }
            } else {
              toast.error("Invalid credentials entered! Please try again...");
              dispatch(hideLoading());
            }
          } else {
            toast.error("Something went wrong! Please try again...");
            dispatch(hideLoading());
          }
        } catch (error) {
          let errorMessage = ErrorValidation(error?.response?.status);
          toast.error(errorMessage);
          dispatch(hideLoading());
        }
      } else {
        let employeeID = await getEmployeesData(values?.email);
        if (employeeID) {
          let apiStatus = await updateEmployeeAccessResetLink(employeeID);
          if (apiStatus) {
            let requestObject = {
              EmployeeEmail: values?.email,
            };
            apiURL = "/sessions/forgotpassword";
            const apiResponse = await ApiService("post", apiURL, requestObject);
            const apiData = apiResponse?.response;
            if (apiData) {
              toast.success("Reset password link sent to registered email");
            } else {
              toast.error("Something went wrong! Please try again...");
            }
            dispatch(hideLoading());
          }
        } else {
          toast.error("Entered email is not registered!");
          dispatch(hideLoading());
        }
      }
    }
  };

  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
  };

  const onReset = () => {
    form.resetFields();
    // setDisplayOTPComponent(false);
  };

  useEffect(() => {
    let responseObj = localStorage.getItem("token");
    if (responseObj) {
      responseObj = JSON.parse(Buffer.from(responseObj, "base64").toString());
      let empStatus = responseObj?.employeeLoginStatus;
      if (empStatus) {
        navigate("/");
      }
    }
    dispatch(showLoading());
    setTimeout(() => {
      dispatch(hideLoading());
    }, 1000);
  }, []);

  return (
    <>
      <div className="login">
        <div className="loginBackground"></div>
        <div className="loginFormContainer">
          <div className="loginForm">
            <div className="logoContainer"></div>
            <h3 className="loginFormTitle">LOGIN</h3>
            <div className="loginFormFieldsContainer card">
              <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
              >
                <>
                  <Form.Item
                    label="Email"
                    name="email"
                    validateTrigger="onBlur"
                    rules={[
                      {
                        type: "email",
                        message: "Please enter a valid email!",
                      },
                      {
                        required: true,
                        message: "Please enter your email",
                      },
                      {
                        max: 50,
                        message:
                          "Email exceeded permitted length! Please try again...",
                      },
                    ]}
                    hasFeedback
                    onBlur={(e) => FormatInput(e, form, "email")}
                  >
                    <Input
                      prefix={<MailOutlined className="site-form-item-icon" />}
                      placeholder="Enter a registered email"
                      // disabled={displayOTPComponent}
                    />
                  </Form.Item>

                  {!displayForgotPassword && (
                    <>
                      <Form.Item
                        label="Role"
                        name="role"
                        rules={[
                          {
                            required: true,
                            message: "Please select a role",
                          },
                        ]}
                        hasFeedback
                      >
                        <Select
                          prefix={
                            <UserOutlined className="site-form-item-icon" />
                          }
                          defaultValue="disabled"
                          options={dropDownOptions}
                          value={roleType}
                          onChange={onSelectChange}
                          // disabled={displayOTPComponent}
                        />
                      </Form.Item>

                      <Form.Item
                        label="Password"
                        name="password"
                        rules={[
                          {
                            required: true,
                            message: "Please enter your Password",
                          },
                          {
                            pattern: PasswordRegex,
                            message: "Password does not match criteria!",
                          },
                        ]}
                        onBlur={(e) => FormatInput(e, form)}
                      >
                        <Input.Password
                          prefix={
                            <SecurityScanOutlined className="site-form-item-icon" />
                          }
                          placeholder="Enter a valid Password"
                        />
                      </Form.Item>
                    </>
                  )}
                </>

                {/* {displayOTPComponent && (
                  <div className="OTPComponent ">
                    <p className="OTPTextMessage">
                      Enter OTP sent to{" "}
                      <span className="OTPTextRecepientEmail">
                        {formValues?.email}
                      </span>
                    </p>

                     <Form.Item
                      name="otp"
                      className="center-error-message"
                      rules={[{ validator: async () => Promise.resolve() }]}
                    >
                      <InputOTP
                        autoFocus
                        inputType="alphabet-numeric"
                        length={6}
                      />
                    </Form.Item> 
                  </div>
                )} */}

                <div className="formButtons">
                  {displayForgotPassword && (
                    <Button
                      // type="primary"
                      danger
                      onClick={() => {
                        onReset();
                        setDisplayForgotPassword(false);
                      }}
                    >
                      BACK
                    </Button>
                  )}

                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={disableFormButton}
                  >
                    {displayForgotPassword ? "RESET" : "LOGIN"}
                  </Button>
                </div>
              </Form>
              {!displayForgotPassword && (
                <Link
                  className="forgotPasswordLink"
                  onClick={() => {
                    setDisplayForgotPassword(true);
                  }}
                >
                  Forgot Password? Click here to reset
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
      <ul className="circles">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>
    </>
  );
}
