import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import ErrorValidation from "../../../services/ErrorValidation";
import ApiService from "../../../services/ApiService";
import { Form, Input, Select, Button, DatePicker, Col, Row } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import dayOfYear from "dayjs/plugin/dayOfYear";
import "./UnassignStaffModalForm.scss";
import { FormatInput } from "../../../services/DataProcessor";

const { TextArea } = Input;
dayjs.extend(customParseFormat);
dayjs.extend(dayOfYear);
const dateFormat = "DD-MM-YYYY";

function UnassignStaffModalForm({
  type,
  title,
  action,
  exitAction,
  assignedStaffID = null,
  patientID = null,
}) {
  const [form] = Form.useForm();
  const [unassignmentReason, setUnassignmentReason] = useState("");
  const [selectedStaffType, setSelectedStaffType] = useState("");
  const [selectedStaff, setSelectedStaff] = useState();
  const [staffDropDownOptions, setStaffDropDownOptions] = useState([
    {
      value: "disabled",
      label: "Select a Staff",
      disabled: true,
    },
  ]);
  const [patientData, setPatientData] = useState(null);

  const unassignmentReasonSelectOptions = [
    { value: "disabled", label: "Select a Reason", disabled: true },
    { value: "Swap Staff Member", label: "Swap Staff Member" },
    {
      value: "Swap Assigned Staff Member on Leave",
      label: "Swap Assigned Staff Member on Leave",
    },
    { value: "Stop Billing Cycle", label: "Stop Billing Cycle" },
  ];

  const staffTypeDropDownOptions = [
    { value: "disabled", label: "Select Staff Type", disabled: true },
    { value: "Nurse", label: "Nurse" },
    { value: "House Maid", label: "House Maid" },
    { value: "Caretaker", label: "Caretaker" },
  ];

  const onReasonSelectChange = (value) => {
    setUnassignmentReason(value);
    form.resetFields();
    form.setFieldsValue({ Reason: value });
  };

  const onStaffTypeSelectChange = (value) => {
    setSelectedStaffType(value);
    getAllStaffData(value);
  };

  const onStaffSelectChange = (value) => {
    setSelectedStaff(value);
  };

  const onDateRangeChange = (value) => {
    // console.log(`onDateRangeChange`, value);
  };

  const filterStaffOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const onStaffSearch = (value) => {};

  const onFinish = async (values) => {
    if (values) {
      // action(values);
      unassignStaffActions(values);
    }
  };

  const parseStaffData = (allStaffData, selectedStaffTypeValue) => {
    let staffSelectionData = [
      { value: "disabled", label: "Select Staff Member", disabled: true },
    ];

    for (let i = 0; i < allStaffData.length; i++) {
      let record = allStaffData[i];
      let extractedStaff = {};
      if (
        record["Designation"] === selectedStaffTypeValue &&
        record["Status"] === "Unassigned" &&
        record["_id"] !== assignedStaffID
      ) {
        extractedStaff["value"] = record["_id"];
        extractedStaff["label"] =
          record["Name"]?.FNAME + " " + record["Name"]?.LName;
        staffSelectionData.push(extractedStaff);
      }
    }

    setStaffDropDownOptions(staffSelectionData);
  };

  const getAllStaffData = async (selectedStaffTypeValue) => {
    let apiURL = "/staff/";
    let requestObj = null;
    const apiResponse = await ApiService("get", apiURL);
    const apiData = apiResponse?.response;
    if (apiData) {
      parseStaffData(apiData, selectedStaffTypeValue);
    } else {
      const apiError = apiResponse?.error;
      let errorMessage = ErrorValidation(apiError?.response?.status);
      toast.error(errorMessage);
    }

    return requestObj;
  };

  const unassignStaffActions = async (values) => {
    let createLeaves = false;
    let leavesObject = null;
    let stopBillingCycle = false;
    let endDateError = false;
    let requestObj = {};
    let swapReason = values?.Reason;

    //For normal swap
    let swapDate = values?.SwapDate;

    //For leave swap
    let leaveStartDate = values?.LeaveStartDate;
    let leaveEndDate = values?.LeaveEndDate;
    let leaveReason = values?.LeaveReason;

    let selectedSwapStaffID = values?.SelectedStaffMember;
    let BillingCycleHistory = patientData["BillingCycleHistory"];

    //Check if Billing History is Active else
    // let objectFound = false;
    // for (let i = 0; i < BillingCycleHistory.length; i++) {
    //   let BillingCycleHistoryObject = BillingCycleHistory[i];
    //   if (BillingCycleHistoryObject?.Status === "In Progress") {
    //     let AssignmentHistory = BillingCycleHistoryObject?.AssignmentHistory;
    //     for (let j = 0; j < AssignmentHistory.length; j++) {
    //       let AssignmentHistoryObject = AssignmentHistory[j];
    //       if (AssignmentHistoryObject?.Status === "In Progress") {
    //         console.log(
    //           `Here`,
    //           dayjs(AssignmentHistoryObject["EndDate"]).add(1, "day"),
    //           dayjs(),
    //           dayjs(AssignmentHistoryObject["EndDate"]).add(1, "day") ===
    //             dayjs()
    //         );
    //         if (
    //           dayjs(AssignmentHistoryObject["EndDate"]).add(1, "day") ===
    //           dayjs()
    //         ) {
    //           endDateError = true;
    //         }
    //       }
    //       objectFound = true;
    //       break;
    //     }
    //   }
    //   if (objectFound) {
    //     break;
    //   }
    // }

    if (endDateError) {
      toast.error("Cannot swap Staff!");
    } else {
      if (swapReason === "Swap Staff Member") {
        requestObj = await swapStaff(
          BillingCycleHistory,
          patientData,
          selectedSwapStaffID,
          swapDate
        );
      }

      if (swapReason === "Swap Assigned Staff Member on Leave") {
        createLeaves = true;
        requestObj = await swapStaffOnLeave(
          BillingCycleHistory,
          patientData,
          selectedSwapStaffID,
          leaveStartDate,
          leaveEndDate,
          leaveReason
        );

        leavesObject = requestObj[1];
        requestObj = requestObj[0];
      }

      if (swapReason === "Stop Billing Cycle") {
        stopBillingCycle = true;
        requestObj = await stopBillingCycleHistory(
          BillingCycleHistory,
          patientData
        );
      }

      // console.log(`leavesObject`, leavesObject);

      // console.log("unassignStaffActions", requestObj);
      // requestObj["BillingCycleHistory"] = BillingCycleHistory;

      let apiURL = "/patients/" + patientData["_id"];
      const apiResponse = await ApiService("put", apiURL, requestObj);
      const apiData = apiResponse?.response;
      if (apiData) {
        if (!stopBillingCycle && !createLeaves) {
          await updateSwappedNAssignedStaff(assignedStaffID, "Unassigned");
          await updateSwappedNAssignedStaff(selectedSwapStaffID, "Assigned");
          toast.success("Staff was swapped successfully");
        }
        if (stopBillingCycle) {
          await updateSwappedNAssignedStaff(assignedStaffID, "Unassigned");
          toast.success("Billing Cycle was stopped successfully");
        }
        if (createLeaves) {
          await updateSwappedNAssignedStaff(
            assignedStaffID,
            "On Leave",
            createLeaves,
            leavesObject
          );
          await updateSwappedNAssignedStaff(selectedSwapStaffID, "Assigned");
          toast.success("Leaves recorded, Staff was swapped successfully");
        }
        exitAction();
      } else {
        const apiError = apiResponse?.error;
        let errorMessage = ErrorValidation(apiError?.response?.status);
        // toast.error(errorMessage);
      }
    }
  };

  const swapStaff = async (
    billingCycleHistory,
    requestObj,
    selectedSwapStaffID,
    swapDate
  ) => {
    let swapComplete = false;
    for (let i = 0; i < billingCycleHistory.length; i++) {
      let BillingCycleHistoryObject = billingCycleHistory[i];
      if (BillingCycleHistoryObject?.Status === "In Progress") {
        let AssignmentHistory = BillingCycleHistoryObject?.AssignmentHistory;
        let newAssignmentHistoryObject = {};
        for (let j = 0; j < AssignmentHistory.length; j++) {
          let AssignmentHistoryObject = AssignmentHistory[j];
          if (AssignmentHistoryObject?.Status === "In Progress") {
            const totalWorkingDays = dayjs(
              AssignmentHistoryObject["EndDate"]
            ).diff(dayjs(AssignmentHistoryObject["StartDate"]), "day");
            const remainingBillingCycleDays = dayjs(
              AssignmentHistoryObject["EndDate"]
            ).diff(swapDate, "day");

            //Update Assignment of previous staff
            AssignmentHistoryObject["TotalWorked"] =
              totalWorkingDays - remainingBillingCycleDays;
            AssignmentHistoryObject["Status"] = "Completed";
            AssignmentHistoryObject["EndDate"] = swapDate.subtract(1, "day");

            //Create new Assignment for new staff
            newAssignmentHistoryObject = {
              StaffID: selectedSwapStaffID,
              PatientID: requestObj["_id"],
              StartDate: swapDate,
              EndDate: swapDate.add(remainingBillingCycleDays, "day"),
              TotalWorked: 0,
              Status: "In Progress",
              Notes: "",
              Timestamp: new Date().toJSON(),
            };

            BillingCycleHistoryObject?.AssignmentHistory?.push(
              newAssignmentHistoryObject
            );
            swapComplete = true;
            break;
          }
        }
      }
      if (swapComplete) {
        break;
      }
    }

    if (swapComplete) {
      requestObj["CurrentStaffID"] = selectedSwapStaffID;
      requestObj["Status"] = "Assigned";
      requestObj["BillingCycleHistory"] = billingCycleHistory;
    }
    return requestObj;
  };

  const swapStaffOnLeave = async (
    billingCycleHistory,
    requestObj,
    selectedSwapStaffID,
    leaveStartDate,
    leaveEndDate,
    leaveReason
  ) => {
    let swapComplete = false;
    let staffLeaves = {};
    for (let i = 0; i < billingCycleHistory.length; i++) {
      let BillingCycleHistoryObject = billingCycleHistory[i];
      if (BillingCycleHistoryObject?.Status === "In Progress") {
        let AssignmentHistory = BillingCycleHistoryObject?.AssignmentHistory;
        let newAssignmentHistoryObject = {};
        for (let j = 0; j < AssignmentHistory.length; j++) {
          let AssignmentHistoryObject = AssignmentHistory[j];
          if (AssignmentHistoryObject?.Status === "In Progress") {
            const totalWorkingDays =
              dayjs(AssignmentHistoryObject["EndDate"]).diff(
                dayjs(AssignmentHistoryObject["StartDate"]),
                "day"
              ) + 1;
            const remainingBillingCycleDays =
              dayjs(AssignmentHistoryObject["EndDate"]).diff(dayjs(), "day") +
              1;

            let singleDay =
              dayjs(leaveStartDate).format(dateFormat) ===
              dayjs(leaveEndDate).format(dateFormat);

            if (singleDay) {
              staffLeaves[dayjs(leaveStartDate).format(dateFormat)] =
                leaveReason;
            } else {
              let leavesDuration = dayjs(leaveEndDate?.$d).diff(
                dayjs(leaveStartDate?.$d),
                "day"
              );

              for (let k = 0; k < leavesDuration; k++) {
                if (dayjs(leaveStartDate).format(dateFormat) in staffLeaves) {
                  //skip
                } else {
                  staffLeaves[dayjs(leaveStartDate).format(dateFormat)] =
                    leaveReason;
                }

                leaveStartDate = dayjs(leaveStartDate).add(1, "day");
              }
            }

            //Update Assignment of previous staff
            AssignmentHistoryObject["TotalWorked"] =
              totalWorkingDays - remainingBillingCycleDays;
            AssignmentHistoryObject["Status"] = "Completed";
            AssignmentHistoryObject["EndDate"] = dayjs().subtract(1, "day");

            //Create new Assignment for new staff
            newAssignmentHistoryObject = {
              StaffID: selectedSwapStaffID,
              PatientID: requestObj["_id"],
              StartDate: dayjs(),
              EndDate: dayjs().add(remainingBillingCycleDays, "day"),
              TotalWorked: 0,
              Status: "In Progress",
              Notes: "",
              Timestamp: new Date().toJSON(),
            };

            BillingCycleHistoryObject?.AssignmentHistory?.push(
              newAssignmentHistoryObject
            );
            swapComplete = true;
            break;
          }
        }
      }
      if (swapComplete) {
        break;
      }
    }

    if (swapComplete) {
      requestObj["CurrentStaffID"] = selectedSwapStaffID;
      requestObj["Status"] = "Assigned";
      requestObj["BillingCycleHistory"] = billingCycleHistory;
    }
    return [requestObj, staffLeaves];
  };

  const stopBillingCycleHistory = async (billingCycleHistory, requestObj) => {
    requestObj["CurrentStaffID"] = "";
    requestObj["Status"] = "Unassigned";
    // let pendingAssignmentSpliceIndex = {};
    for (let i = 0; i < billingCycleHistory.length; i++) {
      let BillingCycleHistoryObject = billingCycleHistory[i];
      if (BillingCycleHistoryObject?.Status === "In Progress") {
        let AssignmentHistory = BillingCycleHistoryObject?.AssignmentHistory;
        for (let j = 0; j < AssignmentHistory.length; j++) {
          let AssignmentHistoryObject = AssignmentHistory[j];
          if (AssignmentHistoryObject?.Status === "In Progress") {
            if (AssignmentHistoryObject?.Status === "In Progress") {
              const totalWorkingDays =
                dayjs(AssignmentHistoryObject["EndDate"]).diff(
                  dayjs(AssignmentHistoryObject["StartDate"]),
                  "day"
                ) + 1;
              const remainingBillingCycleDays =
                dayjs(AssignmentHistoryObject["EndDate"]).diff(dayjs(), "day") +
                1;

              AssignmentHistoryObject["TotalWorked"] =
                totalWorkingDays - remainingBillingCycleDays;
              AssignmentHistoryObject["EndDate"] = dayjs();
              AssignmentHistoryObject["Status"] = "Completed";
            }
          }

          // if (AssignmentHistoryObject?.Status === "Pending") {
          //   pendingAssignmentSpliceIndex = {
          //     BillingCycleHistoryObjectIndex: i,
          //     AssignmentHistoryObjectIndex: j,
          //   };
          // }
        }

        BillingCycleHistoryObject["EndDate"] = dayjs();
        BillingCycleHistoryObject["Status"] = "Completed";
      }
    }

    //Delete Pending Assignment
    // for (let i = 0; i < billingCycleHistory.length; i++) {
    //   if (pendingAssignmentSpliceIndex?.BillingCycleHistoryObjectIndex === i) {
    //     let AssignmentHistory = billingCycleHistory[i];
    //     for (let j = 0; j < AssignmentHistory.length; j++) {
    //       if (
    //         pendingAssignmentSpliceIndex?.AssignmentHistoryObjectIndex === j &&
    //         AssignmentHistory[j] === "Pending"
    //       ) {
    //         AssignmentHistory?.splice(j, 1);
    //       }
    //     }
    //   }
    // }
    requestObj["BillingCycleHistory"] = billingCycleHistory;
    return requestObj;
  };

  const getPatientData = async () => {
    let apiURL = "/patients/" + patientID;
    const apiResponse = await ApiService("get", apiURL);
    const apiData = apiResponse?.response;
    if (apiData) {
      setPatientData(apiData);
    } else {
      const apiError = apiResponse?.error;
      let errorMessage = ErrorValidation(apiError?.response?.status);
      toast.error(errorMessage);
    }
  };

  const getStaffData = async (staffID) => {
    let staffData = null;
    let apiURL = "/staff/" + staffID;
    const apiResponse = await ApiService("get", apiURL);
    const apiData = apiResponse?.response;
    if (apiData) {
      staffData = apiData;
    } else {
      const apiError = apiResponse?.error;
      let errorMessage = ErrorValidation(apiError?.response?.status);
      toast.error(errorMessage);
    }

    return staffData;
  };

  const updateSwappedNAssignedStaff = async (
    swappedNAssignedStaffID = null,
    statusChange = null,
    createLeaves = false,
    leavesObject = null
  ) => {
    let requestObj = await getStaffData(swappedNAssignedStaffID);
    requestObj["Status"] = statusChange;
    if (createLeaves) {
      let existingStaffLeaves = requestObj["Leaves"];
      let newStaffLeaves = { ...existingStaffLeaves, ...leavesObject };
      requestObj["Leaves"] = newStaffLeaves;
      // console.log(
      //   leavesObject,
      //   existingStaffLeaves,
      //   newStaffLeaves,
      //   requestObj
      // );
    }
    let apiURL = "/staff/" + swappedNAssignedStaffID;
    const apiResponse = await ApiService("put", apiURL, requestObj);
    const apiData = apiResponse?.response;
    if (apiData) {
      // toast.success("Staff Data updated successfully");
    } else {
      const apiError = apiResponse?.error;
      let errorMessage = ErrorValidation(apiError?.response?.status);
      toast.error(errorMessage);
    }
  };

  const getBillingCycleStartNEndDates = (dateType) => {
    let date = null;
    let BillingCycleHistory = patientData?.BillingCycleHistory;
    for (let i = 0; i < BillingCycleHistory.length; i++) {
      let BillingCycleHistoryObject = BillingCycleHistory[i];
      if (BillingCycleHistoryObject?.Status === "In Progress") {
        if (dateType === "StartDate") {
          date = BillingCycleHistoryObject?.StartDate;
        }

        if (dateType === "EndDate") {
          date = BillingCycleHistoryObject?.EndDate;
        }

        break;
      }
    }

    return date;
  };

  const validateDateRange = (date1, date2) => {
    let validatedDateRange = false;
    let startDate = dayjs(date1);
    let endDate = dayjs(date2);
    let datesDifference = startDate.diff(endDate, "day");
    if (datesDifference < 0) {
      validatedDateRange = false;
    }

    if (datesDifference >= 0) {
      validatedDateRange = true;
    }

    return validatedDateRange;
  };

  useEffect(() => {
    getPatientData();
  }, []);

  return (
    <>
      {type === "Unassign Staff Member" && (
        <div className="modalForm">
          <h3 className="titleText">{title}</h3>
          <Form
            form={form}
            className="unassignStaffForm"
            layout="vertical"
            onFinish={onFinish}
          >
            <div className="unassignStaffFormFields">
              <Row
                gutter={{
                  xs: 8,
                  sm: 16,
                  md: 24,
                  lg: 32,
                }}
              >
                <Col span={24}>
                  <Form.Item
                    label="Reason for Unassignment"
                    name="Reason"
                    rules={[
                      {
                        required: true,
                        message: "Please select a Reason",
                      },
                    ]}
                    hasFeedback
                  >
                    <Select
                      defaultValue="disabled"
                      options={unassignmentReasonSelectOptions}
                      value={unassignmentReason}
                      onChange={onReasonSelectChange}
                    />
                  </Form.Item>
                </Col>
              </Row>
              {(unassignmentReason === "Swap Staff Member" ||
                unassignmentReason ===
                  "Swap Assigned Staff Member on Leave") && (
                <>
                  <Row
                    gutter={{
                      xs: 8,
                      sm: 16,
                      md: 24,
                      lg: 32,
                    }}
                  >
                    <Col span={12}>
                      <Form.Item
                        label="Select Staff Type"
                        name="SelectedStaffType"
                        rules={[
                          {
                            required: true,
                            message: "Please select a Staff Type",
                          },
                        ]}
                        hasFeedback
                      >
                        <Select
                          defaultValue="disabled"
                          options={staffTypeDropDownOptions}
                          value={selectedStaffType}
                          onChange={onStaffTypeSelectChange}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={12}>
                      <Form.Item
                        label="Select Staff Member"
                        name="SelectedStaffMember"
                        rules={[
                          {
                            required: true,
                            message: "Please select a Staff Member",
                          },
                        ]}
                        hasFeedback
                      >
                        <Select
                          showSearch
                          optionFilterProp="children"
                          defaultValue="disabled"
                          options={staffDropDownOptions}
                          value={selectedStaff}
                          onChange={onStaffSelectChange}
                          onSearch={onStaffSearch}
                          filterOption={filterStaffOption}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              )}

              {unassignmentReason === "Swap Staff Member" && (
                <Row
                  gutter={{
                    xs: 8,
                    sm: 16,
                    md: 24,
                    lg: 32,
                  }}
                >
                  <Col className="gutter-row" span={24}>
                    <Form.Item
                      label="Select the Date of Swap"
                      name="SwapDate"
                      rules={[
                        {
                          required: true,
                          message: "Select Swap Date",
                        },
                      ]}
                    >
                      <DatePicker
                        onChange={(value) => {
                          form.setFieldsValue({
                            LeaveStartDate: dayjs(value),
                          });
                        }}
                        minDate={dayjs(
                          getBillingCycleStartNEndDates("StartDate")
                        )}
                        maxDate={dayjs()}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              )}

              {unassignmentReason === "Swap Assigned Staff Member on Leave" && (
                <>
                  <Form.Item
                    label="Reason for Leave"
                    name="LeaveReason"
                    rules={[
                      {
                        max: 500,
                        message: "Word limit exceeded!",
                      },
                      {
                        required: true,
                        message: "Enter reason for leave",
                      },
                    ]}
                    onBlur={(e) => FormatInput(e, form)}
                  >
                    <TextArea
                      rows={1}
                      placeholder="Enter reason for leave"
                      maxLength={500}
                    />
                  </Form.Item>

                  <Row
                    gutter={{
                      xs: 8,
                      sm: 16,
                      md: 24,
                      lg: 32,
                    }}
                  >
                    <Col className="gutter-row" span={12}>
                      <Form.Item
                        label="Select Leave Start Date"
                        name="LeaveStartDate"
                        rules={[
                          {
                            required: true,
                            message: "Select Leave Start Date",
                          },
                        ]}
                      >
                        <DatePicker
                          onChange={(value) => {
                            form.setFieldsValue({
                              LeaveStartDate: dayjs(value),
                            });
                          }}
                          minDate={dayjs(
                            getBillingCycleStartNEndDates("StartDate")
                          )}
                          maxDate={dayjs(
                            getBillingCycleStartNEndDates("EndDate")
                          )}
                        />
                      </Form.Item>
                    </Col>

                    <Col className="gutter-row" span={12}>
                      <Form.Item
                        label="Select Leave End Date"
                        name="LeaveEndDate"
                        rules={[
                          {
                            validator: async (_, value) => {
                              let validatedDateRange = validateDateRange(
                                value?.$d,
                                form.getFieldValue("LeaveStartDate")?.$d
                              );
                              if (validatedDateRange) {
                                return Promise.resolve();
                              } else {
                                return Promise.reject(
                                  "End Date before Start Date!"
                                );
                              }
                            },
                            message: "End Date before Start Date!",
                          },
                          {
                            required: true,
                            message: "Select Leave End Date",
                          },
                        ]}
                      >
                        <DatePicker
                          minDate={dayjs(
                            getBillingCycleStartNEndDates("StartDate")
                          )}
                          maxDate={dayjs(
                            getBillingCycleStartNEndDates("EndDate")
                          )}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              )}

              {unassignmentReason === "Stop Billing Cycle" && (
                <p className="warningText">
                  Stopping the Billing Cycle will cause the Patient and Staff to
                  get Unassigned! Are you sure you want to end this Billing
                  Cycle?
                </p>
              )}
            </div>

            <div className="unassignStaffFormButton">
              <Button
                className="addEmployeeFormButton"
                type="primary"
                htmlType="submit"
              >
                CONFIRM
              </Button>
              <br />
              <Button
                danger
                className="cancelEmployeeFormButton"
                onClick={() => {
                  form.resetFields();
                  exitAction();
                }}
              >
                CANCEL
              </Button>
            </div>
          </Form>
        </div>
      )}
    </>
  );
}

export default UnassignStaffModalForm;
