import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { showLoading, hideLoading } from "../../../redux/LoaderSlice";
import ApiService from "../../../services/ApiService";
import { TableSearch } from "../../../services/DataFilter";
import {
  DataProcessor,
  ExtractNewEntityID,
  FormObjectProcessor,
  FormatInput,
} from "../../../services/DataProcessor";
import ErrorValidation from "../../../services/ErrorValidation";
import {
  AlphabetRegex,
  PhoneRegex,
  PinCodeRegex,
} from "../../../static/RegexPattern";
import {
  Breadcrumb,
  Form,
  Input,
  InputNumber,
  Select,
  Button,
  Row,
  Col,
  Tabs,
  Table,
  Card,
  DatePicker,
  Space,
  Tag,
} from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import dayOfYear from "dayjs/plugin/dayOfYear";
import { ArrowLeftOutlined, UnorderedListOutlined } from "@ant-design/icons";
import toast from "react-hot-toast";
import "./PatientFormPage.scss";
import ModalComponent from "../../../components/modalComponent/ModalComponent";
import UnassignStaffModalForm from "../UnassignStaffModalForm/UnassignStaffModalForm";
import Search from "antd/es/transfer/search";

const { TextArea } = Input;
dayjs.extend(customParseFormat);
dayjs.extend(dayOfYear);
const dateFormat = "DD-MM-YYYY";
const dateToday = new Date().toJSON().slice(0, 10);

const PatientFormPage = ({ type }) => {
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const { patientID } = useParams();
  var Buffer = require("buffer/").Buffer;
  const decodedPatientIDString = Buffer.from(patientID, "base64").toString();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [patientData, setPatientData] = useState([]);
  const [assignedStaffData, setAssignedPatientData] = useState([]);
  const [dataIsReady, setDataIsReady] = useState(false);
  const [access, setAccess] = useState(true);
  const [editMode, setEditMode] = useState(false);
  const [genderType, setGenderType] = useState("");
  const [patientStatusType, setPatientStatusType] = useState("");
  const [staffType, setStaffType] = useState();
  const [staffSelection, setStaffSelection] = useState();
  const [allStaffData, setAllStaffData] = useState([]);
  const [staffSelectionDataReady, setStaffSelectionDataReady] = useState(false);
  const [staffDropDownOptions, setStaffDropDownOptions] = useState([
    {
      value: "disabled",
      label: "Select Staff Member",
      disabled: true,
    },
  ]);
  const [formStep, setFormStep] = useState(1);
  const [stepDescriptionText, setStepDescriptionText] = useState(
    "Step 1: Patient details"
  );
  const [displayPatientDetailsTab, setDisplayPatientDetailsTab] =
    useState(true);
  const [displayAssignedStaffTab, setDisplayAssignedStaffTab] = useState(false);
  const [displayPatientPaymentsTab, setDisplayPatientPaymentsTab] =
    useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const [filterIsReady, setFilterIsReady] = useState(false);
  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const [tableData, setTableData] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [paymentTableData, setPaymentTableData] = useState([]);
  const [entityTableFilters, setEntityTableFilters] = useState([]);

  let unprocessedData = [];
  let processedData = [];
  let processedPaymentTableData = [];

  let responseObj = localStorage.getItem("token");
  if (responseObj) {
    responseObj = JSON.parse(Buffer.from(responseObj, "base64").toString());
  }
  let role = null;

  if (responseObj?.employeeData) {
    role = responseObj?.employeeData?.Role;
  }

  const genderDropDownOptions = [
    { value: "disabled", label: "Select Gender", disabled: true },
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" },
    { value: "Other", label: "Other" },
  ];

  const staffTypeDropDownOptions = [
    { value: "disabled", label: "Select Staff Type", disabled: true },
    { value: "Nurse", label: "Nurse" },
    { value: "House Maid", label: "House Maid" },
    { value: "Caretaker", label: "Caretaker" },
  ];

  const tabItems = [
    {
      key: "1",
      label: "Details",
      children: "",
    },
    {
      key: "2",
      label: "Assigned Staff",
      children: "",
    },
    {
      key: "3",
      label: "Payments",
      children: "",
    },
  ];

  const onGenderSelectChange = (value) => {
    setGenderType(value);
  };

  const onStaffTypeChange = (value) => {
    form.setFieldsValue({
      StaffSelection: {
        value: "disabled",
        label: "Select Staff Member",
        disabled: true,
      },
    });
    parseStaffData(value);
  };

  const onStaffChange = (value) => {
    setStaffSelection(value);
  };

  const filterStaffOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const onStaffSearch = (value) => {};

  const onTabChange = async (key) => {
    if (key === "1") {
      setDisplayPatientDetailsTab(true);
      setDisplayAssignedStaffTab(false);
      setDisplayPatientPaymentsTab(false);
      setFormStep(1);
    }

    if (key === "2") {
      getAllStaffData();
      setDisplayPatientDetailsTab(false);
      setDisplayAssignedStaffTab(true);
      setDisplayPatientPaymentsTab(false);
    }

    if (key === "3") {
      setDisplayPatientDetailsTab(false);
      setDisplayAssignedStaffTab(false);
      setDisplayPatientPaymentsTab(true);
    }
  };

  let apiURL = "/patients/";
  let apiType = "post";
  if (type === "edit") {
    apiType = "put";
    apiURL = "/patients/" + decodedPatientIDString;
  }

  const getPatientData = async () => {
    dispatch(showLoading());
    const apiResponse = await ApiService("get", apiURL);
    const apiData = apiResponse?.response;
    if (apiData) {
      setPatientData(apiData);
      if (apiData?.Status === "Assigned") {
        getAssignedStaffData(apiData?.CurrentStaffID);
      }
      unprocessedData = apiData;
      processedData = await DataProcessor([unprocessedData]);
      processedPaymentTableData = await parsePaymentData(processedData);
      setTableData(processedPaymentTableData);
      setPaymentTableData(processedPaymentTableData);
      dispatch(hideLoading());
      setDataIsReady(true);
      if (type === "edit") {
      }
    } else {
      const apiError = apiResponse?.error;
      let errorMessage = ErrorValidation(apiError?.response?.status);
      toast.error(errorMessage);
    }
  };

  const parsePaymentData = async (processedData) => {
    if (processedData?.length > 0) {
      let processedPaymentTableData = [];
      processedData.map((item) => {
        if (item?.Payments?.length > 0) {
          item?.Payments?.map((paymentItem, paymentItemIndex) => {
            let paymentTableRecord = null;
            paymentTableRecord = {
              _id: item?._id,
              paymentID: paymentItem?.PaymentID,
              paymentItemIndex: paymentItemIndex,
              Name: item?.Name,
              Phone: item?.Phone,
              Status: paymentItem?.PaymentStatus,
              amount: paymentItem?.Amount,
              paidOn: paymentItem?.PaidOn,
              dueDate: paymentItem?.DueDate,
              billingCycle: paymentItem?.BillingCycle,
            };
            processedPaymentTableData.push(paymentTableRecord);
          });
        }
      });

      processedPaymentTableData.sort(function (a, b) {
        return new Date(b.paidOn) - new Date(a.paidOn);
      });

      processedPaymentTableData = processedPaymentTableData.map(
        (payment, index) => ({
          key: index,
          ...payment,
        })
      );
      return processedPaymentTableData;
    }
  };

  const getSelectedStaffData = async (selectedStaffID) => {
    let selectedStaffData = null;
    apiURL = "/staff/" + selectedStaffID;
    const apiResponse = await ApiService("get", apiURL);
    const apiData = apiResponse?.response;
    if (apiData) {
      selectedStaffData = apiData;
    } else {
      const apiError = apiResponse?.error;
      let errorMessage = ErrorValidation(apiError?.response?.status);
      toast.error(errorMessage);
    }
    return selectedStaffData;
  };

  const getAssignedStaffData = async (assignedStaffID) => {
    apiURL = "/staff/" + assignedStaffID;
    const apiResponse = await ApiService("get", apiURL);
    const apiData = apiResponse?.response;
    if (apiData) {
      setAssignedPatientData(apiData);
      setStaffType({
        value: apiData?.Designation,
        label: apiData?.Designation,
      });
      setStaffSelection({
        value: apiData?._id,
        label: apiData?.Name?.FNAME + " " + apiData?.Name?.LName,
      });
    } else {
      const apiError = apiResponse?.error;
      let errorMessage = ErrorValidation(apiError?.response?.status);
      toast.error(errorMessage);
    }
  };

  const getAllStaffData = async () => {
    apiURL = "/staff/";
    dispatch(showLoading());
    const apiResponse = await ApiService("get", apiURL);
    const apiData = apiResponse?.response;
    if (apiData) {
      setAllStaffData(apiData);
      setStaffSelectionDataReady(true);
      if (type === "add") {
        setStepDescriptionText("Step 2: Assign staff");
        setFormStep(2);
        setStaffDropDownOptions([
          {
            value: "disabled",
            label: "Select Staff Member",
            disabled: true,
          },
        ]);
      }
      setDataIsReady(true);
      dispatch(hideLoading());
    } else {
      const apiError = apiResponse?.error;
      let errorMessage = ErrorValidation(apiError?.response?.status);
      toast.error(errorMessage);
      dispatch(hideLoading());
    }
  };

  const onFormSubmit = async (values) => {
    dispatch(showLoading());
    if (values) {
      let requestObj = FormObjectProcessor(values);
      if (type === "add" && formStep === 1) {
        requestObj["Status"] = "Unassigned";
        requestObj["BillingCycleHistory"] = [];
        requestObj["Payments"] = [];
        requestObj["CurrentStaffID"] = "";
      }

      let apiResponse = await ApiService(apiType, apiURL, requestObj);
      let apiData = apiResponse?.response;
      if (apiData) {
        form.resetFields();
        apiURL = "/patients/";
        let extractedEntityID = await ExtractNewEntityID({
          apiURL: apiURL,
          primaryPhoneNumber: requestObj["Phone"]?.Primary,
        });
        apiURL = "/patients/" + extractedEntityID;
        getPatientData();
        getAllStaffData();
        setDataIsReady(true);
        // dispatch(hideLoading());
        type === "add" && formStep === 1
          ? toast.success("Patient was added Successfully!")
          : toast.success("Patient was updated Successfully!");

        if (type === "add" || (type === "edit" && patientData?.Age === 0)) {
          //Generate Automatic Payments for new and converted patients
          let totalDaysInYear = 365;
          let initialBillingCycle = values?.InitialBillingCycle;
          let remainingDays = totalDaysInYear - dayjs().date(1).dayOfYear();
          let totalNosOfBillingCycles = Math.floor(
            remainingDays / initialBillingCycle
          );
          let endDate = dayjs(values?.EndDate);
          for (let i = 0; i < totalNosOfBillingCycles; i++) {
            endDate = endDate.add(initialBillingCycle, "day");
            let PaymentObject = {
              Amount: values?.InitialAmount,
              PaymentStatus: "Pending",
              DueDate: endDate.add(5, "day"),
              PaidOn: endDate,
              BillingCycle: values?.InitialBillingCycle?.toString(),
              BillingCycleDuration: "",
            };

            apiType = "post";
            apiURL = "/patients/" + extractedEntityID + "/payments";
            let apiResponse = await ApiService(apiType, apiURL, PaymentObject);
            let apiData = apiResponse?.response;
            if (apiData) {
            } else {
            }
          }
          toast.success("Payments generated for Patient successfully!");
        }

        if (type === "edit") {
          let originalBillingCycle =
            patientData?.InitialBillingCycle.toString();
          let originalAmount = patientData?.InitialAmount.toString();

          if (values?.InitialBillingCycle !== originalBillingCycle) {
            await updatePatientPayments(
              "billingCycle",
              patientData,
              values?.InitialBillingCycle
            );
          }

          if (values?.InitialAmount !== originalAmount) {
            await updatePatientPayments(
              "amount",
              patientData,
              values?.InitialAmount
            );
          }

          dispatch(hideLoading());
          window.location.reload();
          setActiveTab("2");
        }
      }
    }
  };

  const onFinishFailed = (errorInfo) => {
    toast.error("Please fill all required form fields!");
  };

  const assignStaffToPatient = async (values) => {
    dispatch(showLoading());
    getPatientData();
    if (patientData?.Status === "Unassigned") {
      let assignmentIsValid = false;
      let requestObj = {};
      let AssignmentHistory = [];
      let AssignmentHistoryObject = {
        StaffID: values?.StaffSelection,
        PatientID: patientData?._id,
        StartDate: values?.StartDate?.$d,
        EndDate: values?.EndDate?.$d,
        // Leaves: {},
        TotalWorked: 0,
        Status: "In Progress",
        Notes: "",
        Timestamp: new Date().toJSON(),
      };

      AssignmentHistory.push(AssignmentHistoryObject);

      let BillingCycleHistory = patientData?.BillingCycleHistory;
      let BillingCycleHistoryObject = {
        AssignmentHistory: AssignmentHistory,
        StartDate: values?.StartDate?.$d,
        EndDate: values?.EndDate?.$d,
        Status: "In Progress",
      };

      if (BillingCycleHistory.length > 0) {
        for (let i = 0; i < BillingCycleHistory.length; i++) {
          let BillingCycleHistoryObject = BillingCycleHistory[i];
          if (BillingCycleHistoryObject?.Status === "Completed") {
            assignmentIsValid = true;
          } else {
            toast.error(
              "Cannot assign Staff when previous billing cycle is in progress!"
            );
          }
        }
      } else {
        assignmentIsValid = true;
      }

      if (assignmentIsValid) {
        BillingCycleHistory.push(BillingCycleHistoryObject);
        requestObj["Status"] = "Assigned";
        requestObj["CurrentStaffID"] = values?.StaffSelection;
        requestObj["BillingCycleHistory"] = BillingCycleHistory;

        apiURL = "/patients/" + patientData?._id;
        const apiResponse = await ApiService("put", apiURL, requestObj);
        const apiData = apiResponse?.response;
        if (apiData) {
          toast.success("Staff was assigned to Patient successfully");
          let updatedAssignedStaff = await updateAssignedStaff(
            values?.StaffSelection
          );
          if (updatedAssignedStaff) {
            window.location.reload();
            // toast.success("Updated assigned Staff details");
          } else {
            toast.error("Could not update assigned Staff, Please try again");
          }
          dispatch(hideLoading());
        } else {
          const apiError = apiResponse?.error;
          let errorMessage = ErrorValidation(apiError?.response?.status);
          toast.error(errorMessage);
          dispatch(hideLoading());
        }
      } else {
      }
    }
  };

  const updateAssignedStaff = async (selectedStaffID) => {
    let updatedAssignedStaff = false;
    let requestObj = await getSelectedStaffData(selectedStaffID);
    requestObj["Status"] = "Assigned";
    apiURL = "/staff/" + selectedStaffID;
    const apiResponse = await ApiService("put", apiURL, requestObj);
    const apiData = apiResponse?.response;
    if (apiData) {
      // toast.success("Updated Assigned Staff!");
      updatedAssignedStaff = true;
      dispatch(hideLoading());
    } else {
      const apiError = apiResponse?.error;
      let errorMessage = ErrorValidation(apiError?.response?.status);
      toast.error(errorMessage);
      dispatch(hideLoading());
    }

    return updatedAssignedStaff;
  };

  const parseStaffData = (selectedStaffType) => {
    let staffSelectionData = [
      { value: "disabled", label: "Select Staff Member", disabled: true },
    ];
    // console.log(`selectedStaffType`, selectedStaffType, allStaffData);
    for (let i = 0; i < allStaffData.length; i++) {
      let record = allStaffData[i];
      let extractedStaff = {};
      if (
        record["Designation"] === selectedStaffType &&
        record["Status"] === "Unassigned"
      ) {
        extractedStaff["value"] = record["_id"];
        extractedStaff["label"] =
          record["Name"]?.FNAME + " " + record["Name"]?.LName;
        staffSelectionData.push(extractedStaff);
      }
    }

    // console.log(`staffSelectionData`, staffSelectionData);

    setStaffDropDownOptions(staffSelectionData);
  };

  const validateStaffSelection = (value) => {
    // console.log(`Here`, value);
    let validatedStaffSelection = false;
    if (value?.value !== "disabled") {
      validatedStaffSelection = true;
    }

    return validatedStaffSelection;
  };

  const validateDateRange = (date1, date2) => {
    let validatedDateRange = false;
    let startDate = dayjs(date1);
    let endDate = dayjs(date2);
    let datesDifference = startDate.diff(endDate, "day");
    if (datesDifference < 0) {
      validatedDateRange = false;
    }

    if (datesDifference >= 0) {
      validatedDateRange = true;
    }

    return validatedDateRange;
  };

  const validateBillingCycleRange = (date1, date2, billingCycle) => {
    let validatedDateRange = false;
    let startDate = dayjs(date1);
    let endDate = dayjs(date2);
    let datesDifference = startDate.diff(endDate, "day") + 1;
    if (datesDifference === billingCycle) {
      validatedDateRange = true;
    }
    return validatedDateRange;
  };

  const confirmFormSubmission = async (values) => {};

  const confirmCancel = () => {
    setOpenModal(false);
    window.location.reload();
  };

  const getBillingCycleStartNEndDates = (dateType) => {
    let date = null;
    if (patientData?.Status === "Assigned") {
      let BillingCycleHistory = patientData?.BillingCycleHistory;
      for (let i = 0; i < BillingCycleHistory.length; i++) {
        let BillingCycleHistoryObject = BillingCycleHistory[i];
        if (BillingCycleHistoryObject?.Status === "In Progress") {
          if (dateType === "StartDate") {
            date = BillingCycleHistoryObject?.StartDate;
          }

          if (dateType === "EndDate") {
            date = BillingCycleHistoryObject?.EndDate;
          }

          break;
        }
      }
    } else {
      if (dateType === "StartDate") {
        date = dayjs(new Date().toString());
      }

      if (dateType === "EndDate") {
        date = dayjs(new Date().toString()).add(
          parseInt(patientData.InitialBillingCycle) - 1,
          "day"
        );
      }
    }

    return date;
  };

  const getAssignmentHistoryData = (dateType = null) => {
    let data = null;
    let BillingCycleHistory = patientData?.BillingCycleHistory;
    let objectFound = false;
    for (let i = 0; i < BillingCycleHistory.length; i++) {
      let BillingCycleHistoryObject = BillingCycleHistory[i];
      if (BillingCycleHistoryObject?.Status === "In Progress") {
        let AssignmentHistory = BillingCycleHistoryObject?.AssignmentHistory;
        for (let j = 0; j < AssignmentHistory.length; j++) {
          let AssignmentHistoryObject = AssignmentHistory[j];
          if (AssignmentHistoryObject?.Status === "In Progress") {
            if (dateType) {
              if (dateType === "StartDate") {
                data = AssignmentHistoryObject?.StartDate;
              }
              if (dateType === "EndDate") {
                data = AssignmentHistoryObject?.EndDate;
              }

              if (dateType === "Working Days") {
                let totalNosOfDaysTillNow = null;
                //If EndDate is a future date
                if (
                  dayjs(AssignmentHistoryObject["EndDate"]).isAfter(dayjs())
                ) {
                  totalNosOfDaysTillNow = dayjs().diff(
                    dayjs(AssignmentHistoryObject["StartDate"]),
                    "day"
                  );
                } else {
                  totalNosOfDaysTillNow = dayjs(
                    AssignmentHistoryObject["EndDate"]
                  ).diff(dayjs(AssignmentHistoryObject["StartDate"]), "day");
                }

                data = totalNosOfDaysTillNow + 1;
              }
            } else {
              data = AssignmentHistoryObject;
            }
            objectFound = true;
            break;
          }
        }
      }
      if (objectFound) {
        break;
      }
    }

    return data;
  };

  const validateUserRole = (type = null) => {
    let validatedUserRole = false;
    if (role === "Admin" || role === "HR" || role === "CRM") {
      validatedUserRole = true;
    }

    return validatedUserRole;
  };

  let columns = [
    {
      title: "Billing Cycle",
      dataIndex: "billingCycle",
      key: "billingCycle",
      sorter: (a, b) => a.billingCycle.length - b.billingCycle.length,
      render: (value, record) => record?.billingCycle + " Days",
    },
    {
      title: "Fees",
      dataIndex: "amount",
      key: "Amount",
      sorter: (a, b) => a.amount.length - b.amount.length,
      render: (value, record) => "₹ " + record?.amount,
    },
    {
      title: "Due Date",
      dataIndex: "dueDate",
      key: "dueDate",
      sorter: (a, b) => a.dueDate.length - b.dueDate.length,
      render: (value) => dayjs(value).format(dateFormat),
    },
    {
      title: "Paid On",
      dataIndex: "paidOn",
      key: "paidOn",
      sorter: (a, b) => a.paidOn.length - b.paidOn.length,
      render: (value) => dayjs(value).format(dateFormat),
    },
    {
      title: "Status",
      dataIndex: "Status",
      key: "Status",
      filters: filterIsReady ? entityTableFilters["Status"] : null,
      onFilter: (value, record) => onTableFilter(value, record),
      filteredValue: filteredInfo.Status || null,
      sorter: (a, b) => a.Status.length - b.Status.length,
      render: (value, record) =>
        record?.Status === "Paid" ? (
          <Tag color="green">{record?.Status}</Tag>
        ) : (
          <Tag color="red">{record?.Status}</Tag>
        ),
    },
  ];

  const onChange = (pagination, filters, sorter, extra) => {
    setFilteredInfo(filters);
    setSortedInfo(sorter);
  };

  const clearFilters = () => {
    setFilteredInfo({});
    setSortedInfo({});
  };
  const onMonthChange = (date, dateString) => {
    if (!date) {
      // getData();
    } else {
      let selectedMonth = dayjs(dateString).month() + 1;
      let selectedYear = dayjs(dateString).year();
      let paymentTableMonthData = [];
      paymentTableData.filter((payment) => {
        let paymentDate = dayjs(payment?.paidOn).format(dateFormat).split("-");
        let paymentMonth = paymentDate[1];
        let paymentYear = paymentDate[2];
        if (
          parseInt(paymentMonth) === selectedMonth &&
          parseInt(paymentYear) === selectedYear
        ) {
          payment["key"] = paymentTableMonthData.length;
          paymentTableMonthData.push(payment);
        }
      });
      if (paymentTableMonthData.length > 0) {
        setPaymentTableData(paymentTableMonthData);
      } else {
        toast.error("No payments were found for selected month!");
      }
    }
  };

  const onTableSearch = (value) => {
    let searchResult = TableSearch(value, paymentTableData);
    if (searchResult?.length > 0) {
      setSearchData(searchResult);
    } else {
      toast.error("No matching records found!");
    }

    if (value === "") {
      setSearchData([]);
      // setPaymentTableData(tableData);
    }
  };

  const onTableFilter = (value, record) => {
    if (record?.Name?.indexOf(value) === 0) {
      return true;
    }

    if (record?.Address?.includes(value) === true) {
      return true;
    }

    if (record?.Designation?.indexOf(value) === 0) {
      return true;
    }
  };

  const validateDatesInBillingCycleMonth = (days) => {
    let validatedDatesInBillingCycleMonth = false;
    let startDate = dayjs();
    let endDate = dayjs().add(days - 1, "day");
    if (startDate.month() === endDate.month()) {
      validatedDatesInBillingCycleMonth = true;
    }

    return validatedDatesInBillingCycleMonth;
  };

  const updatePatientPayments = async (
    updateType,
    patientData,
    changedAttribute
  ) => {
    let patientID = patientData?._id;
    let patientStatus = patientData?.Status;
    let patientPayments = patientData?.Payments;
    let updatedPayments = [];
    let deletedPatientPayments = [];

    if (patientStatus === "Unassigned") {
      for (let i = 0; i < patientPayments.length; i++) {
        let patientPayment = patientPayments[i];

        if (patientPayment?.PaymentStatus === "Pending") {
          if (updateType === "billingCycle") {
            deletedPatientPayments.push(patientPayment?.PaymentID);
          }

          if (updateType === "amount") {
            patientPayment["Amount"] = changedAttribute;
            updatedPayments.push(patientPayment);
          }
        }
      }

      if (updateType === "billingCycle") {
        if (deletedPatientPayments.length > 0) {
          //Delete old payments due to invalid billing cycle
          for (let i = 0; i < deletedPatientPayments.length; i++) {
            let deletedPaymentID = deletedPatientPayments[i];
            let apiURL = "/patients/payments/" + deletedPaymentID;
            let apiResponse = await ApiService("delete", apiURL);
            let apiData = apiResponse?.response;
          }
        }

        //Generate new Payments with updated billing cycle values
        let totalDaysInYear = 365;
        let initialBillingCycle = changedAttribute;
        let remainingDays = totalDaysInYear - dayjs().date(1).dayOfYear();
        let totalNosOfBillingCycles = Math.floor(
          remainingDays / initialBillingCycle
        );
        let endDate = dayjs();
        for (let i = 0; i < totalNosOfBillingCycles; i++) {
          endDate = endDate.add(changedAttribute, "day");
          let PaymentObject = {
            Amount: patientData?.InitialAmount,
            PaymentStatus: "Pending",
            DueDate: endDate.add(5, "day"),
            PaidOn: endDate,
            BillingCycle: changedAttribute.toString(),
            BillingCycleDuration:
              endDate.subtract(changedAttribute, "day").format(dateFormat) +
              " - " +
              endDate.format(dateFormat),
          };

          apiType = "post";
          apiURL = "/patients/" + patientID + "/payments";
          let apiResponse = await ApiService(apiType, apiURL, PaymentObject);
          let apiData = apiResponse?.response;
          if (apiData) {
          } else {
          }
        }
      }

      if (updateType === "amount") {
        patientData["Payments"] = updatedPayments;
        let apiURL = "/patients/" + patientID;
        let apiResponse = await ApiService("put", apiURL, patientData);
      }
    }
  };

  useEffect(() => {
    if (type === "edit") {
      getPatientData();
    }
  }, []);

  return (
    <>
      {(type === "add" || dataIsReady) && (
        <div className="patientFormContainer">
          <div className="patientFormDiv">
            <Breadcrumb
              className="breadCrumb"
              items={[
                {
                  title: <a href="../../=">Patients</a>,
                },
                {
                  title: type === "edit" ? "Edit Patient" : "Add Patient",
                },
              ]}
            />

            {type !== "add" && (
              <Tabs
                className="tabs"
                defaultActiveKey={activeTab}
                items={tabItems}
                onChange={onTabChange}
              />
            )}

            <div className={type === "edit" ? "header edit" : "header"}>
              <div className="headerStart">
                <ArrowLeftOutlined
                  className="backArrowButton"
                  onClick={() => {
                    navigate("/patients/=");
                  }}
                />
                <h3 className="patientFormTitle">
                  {type === "add"
                    ? "Add Patient"
                    : patientData?.Name?.FNAME + " " + patientData?.Name?.LName}
                </h3>
                {type === "edit" ? (
                  <span
                    className={
                      patientData?.Status === "Unassigned"
                        ? "patientStatusTag unassigned"
                        : patientData?.Status === "Assigned"
                        ? "patientStatusTag assigned"
                        : "patientStatusTag inactive"
                    }
                  >
                    Status: {patientData?.Status}
                  </span>
                ) : null}
              </div>

              {displayPatientDetailsTab && (
                <div>
                  {editMode && (
                    <Button
                      className="patientFormButton cancel"
                      danger
                      onClick={() => {
                        setEditMode(false);
                      }}
                    >
                      CANCEL
                    </Button>
                  )}
                  {/* 
                {!editMode && type !== "add" && (
                  <Button className="patientFormButton cv" onClick={() => {}}>
                    Download C.V
                  </Button>
                )} */}

                  {!editMode && type === "edit" && (
                    <Button
                      className="patientFormButton"
                      type="primary"
                      onClick={() => {
                        let validatedUser = validateUserRole();
                        if (validatedUser) {
                          setEditMode(true);
                        } else {
                          toast.error("Unauthorized Access!");
                        }
                      }}
                      disabled={access ? false : true}
                    >
                      EDIT
                    </Button>
                  )}

                  {((type === "add" && formStep === 1) || editMode) && (
                    <Button
                      className="patientFormButton"
                      type="primary"
                      htmlType={access ? "submit" : null}
                      onClick={() => {
                        let validatedUser = validateUserRole();
                        if (validatedUser) {
                          form.submit();
                          if (editMode) {
                            setEditMode(false);
                          }
                          dispatch(showLoading());
                          setTimeout(() => {
                            dispatch(hideLoading());
                          }, 500);
                        } else {
                          toast.error("Unauthorized Access!");
                        }
                      }}
                      disabled={access ? false : true}
                    >
                      {editMode ? "SAVE" : "NEXT"}
                    </Button>
                  )}
                </div>
              )}

              {(formStep === 2 || displayAssignedStaffTab) && (
                <Button
                  className="patientFormButton"
                  type="primary"
                  htmlType={access ? "submit" : null}
                  onClick={() => {
                    let validatedUser = validateUserRole();
                    if (validatedUser) {
                      patientData?.Status === "Unassigned"
                        ? form2.submit()
                        : setOpenModal(true);
                      if (editMode) {
                        setEditMode(false);
                      }
                      dispatch(showLoading());
                      setTimeout(() => {
                        dispatch(hideLoading());
                      }, 500);
                    } else {
                      toast.error("Unauthorized Access!");
                    }
                  }}
                  disabled={access ? false : true}
                >
                  {patientData?.Status === "Unassigned"
                    ? "ASSIGN STAFF"
                    : "UNASSIGN STAFF"}
                </Button>
              )}
            </div>

            {displayPatientDetailsTab &&
              (formStep === 1 || type === "edit") && (
                <Form
                  form={form}
                  className={
                    type === "edit" ? "patientForm edit" : "patientForm"
                  }
                  layout="vertical"
                  onFinish={onFormSubmit}
                  onFinishFailed={onFinishFailed}
                >
                  <div className="patientFormFields">
                    {type === "add" ? (
                      <div className="stepDescriptionText">
                        <h5>{stepDescriptionText}</h5>
                      </div>
                    ) : null}
                    <div className="patientFormFieldsScrollBox">
                      {formStep === 1 && (
                        <>
                          <Row
                            gutter={{
                              xs: 8,
                              sm: 16,
                              md: 24,
                              lg: 32,
                            }}
                          >
                            <Col className="gutter-row" span={6}>
                              <Form.Item
                                label="Patient ID"
                                name="PatientRollNo"
                                initialValue={patientData?.PatientRollNo}
                                rules={[
                                  {
                                    required: true,
                                    message: "Enter Patient ID",
                                  },
                                  {
                                    max: 10,
                                    message: "Patient ID limit exceeded!",
                                  },
                                ]}
                                onBlur={(e) => FormatInput(e, form)}
                              >
                                <Input
                                  placeholder="Example: LS-101"
                                  disabled={
                                    type === "edit"
                                      ? editMode
                                        ? false
                                        : true
                                      : false
                                  }
                                />
                              </Form.Item>
                            </Col>

                            <Col className="gutter-row" span={6}>
                              <Form.Item
                                label="First Name"
                                name="Name-FNAME"
                                initialValue={patientData?.Name?.FNAME}
                                rules={[
                                  {
                                    required: true,
                                    message: "Enter First Name",
                                  },
                                  {
                                    pattern: AlphabetRegex,
                                    message: "Enter valid First Name",
                                  },
                                  {
                                    max: 50,
                                    message: "First Name limit exceeded!",
                                  },
                                ]}
                                onBlur={(e) => FormatInput(e, form)}
                              >
                                <Input
                                  placeholder="Example: Raj"
                                  disabled={
                                    type === "edit"
                                      ? editMode
                                        ? false
                                        : true
                                      : false
                                  }
                                />
                              </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={6}>
                              <Form.Item
                                label="Last Name"
                                name="Name-LName"
                                initialValue={patientData?.Name?.LName}
                                rules={[
                                  {
                                    required: true,
                                    message: "Enter Last Name",
                                  },
                                  {
                                    pattern: AlphabetRegex,
                                    message: "Enter valid Last Name",
                                  },
                                  {
                                    max: 50,
                                    message: "Last Name limit exceeded!",
                                  },
                                ]}
                                onBlur={(e) => FormatInput(e, form)}
                              >
                                <Input
                                  placeholder="Example: Kumar"
                                  disabled={
                                    type === "edit"
                                      ? editMode
                                        ? false
                                        : true
                                      : false
                                  }
                                />
                              </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={6}>
                              <Form.Item
                                label="Age"
                                name="Age"
                                initialValue={patientData?.Age}
                                rules={[
                                  {
                                    validator: (_, value) => {
                                      if (value !== 0) {
                                        return Promise.resolve();
                                      } else {
                                        return Promise.reject(
                                          "Enter Valid Age!"
                                        );
                                      }
                                    },
                                    message: "Enter Valid Age!",
                                  },
                                  {
                                    required: true,
                                    message: "Enter Age",
                                  },
                                ]}
                              >
                                <InputNumber
                                  placeholder="Enter Age"
                                  onChange={(value) => {}}
                                  min={1}
                                  step={1}
                                  max={150}
                                  style={{ width: "100%" }}
                                  disabled={
                                    type === "edit"
                                      ? editMode
                                        ? false
                                        : true
                                      : false
                                  }
                                />
                              </Form.Item>
                            </Col>
                          </Row>

                          <Row
                            gutter={{
                              xs: 8,
                              sm: 16,
                              md: 24,
                              lg: 32,
                            }}
                          >
                            <Col className="gutter-row" span={8}>
                              <Form.Item
                                label="Diagnosis"
                                name="Diagnosis"
                                initialValue={patientData?.Diagnosis}
                                rules={[
                                  {
                                    max: 500,
                                    message: "Word limit exceeded!",
                                  },
                                  {
                                    required: true,
                                    message: "Enter Diagnosis Details",
                                  },
                                ]}
                                onBlur={(e) => FormatInput(e, form)}
                              >
                                <TextArea
                                  rows={4}
                                  placeholder="Enter Diagnosis Details:"
                                  maxLength={500}
                                  disabled={
                                    type === "edit"
                                      ? editMode
                                        ? false
                                        : true
                                      : false
                                  }
                                />
                              </Form.Item>
                            </Col>

                            <Col className="gutter-row" span={8}>
                              <Form.Item
                                label="Requirements"
                                name="TypeOfWork"
                                initialValue={patientData?.TypeOfWork}
                                rules={[
                                  {
                                    max: 500,
                                    message: "Word limit exceeded!",
                                  },
                                  {
                                    required: true,
                                    message: "Enter Requirement details",
                                  },
                                ]}
                                onBlur={(e) => FormatInput(e, form)}
                              >
                                <TextArea
                                  rows={4}
                                  placeholder="Enter Requirement details: (Eg: Patient Care, Insulin Check, Sponge Bath...)"
                                  maxLength={500}
                                  disabled={
                                    type === "edit"
                                      ? editMode
                                        ? false
                                        : true
                                      : false
                                  }
                                />
                              </Form.Item>
                            </Col>

                            <Col className="gutter-row" span={8}>
                              <Form.Item
                                label="Preferred Languages"
                                name="Languages"
                                initialValue={patientData?.Languages}
                                rules={[
                                  {
                                    max: 500,
                                    message: "Word limit exceeded!",
                                  },
                                  {
                                    required: true,
                                    message: "Enter Preferred Languages",
                                  },
                                ]}
                                onBlur={(e) => FormatInput(e, form)}
                              >
                                <TextArea
                                  rows={4}
                                  placeholder="Enter Preferred Languages: (Eg: English, Konkani, Hindi...)"
                                  maxLength={500}
                                  disabled={
                                    type === "edit"
                                      ? editMode
                                        ? false
                                        : true
                                      : false
                                  }
                                />
                              </Form.Item>
                            </Col>
                          </Row>

                          <Row
                            gutter={{
                              xs: 8,
                              sm: 16,
                              md: 24,
                              lg: 32,
                            }}
                          >
                            <Col className="gutter-row" span={6}>
                              <Form.Item
                                label="Gender"
                                name="Gender"
                                rules={[
                                  {
                                    required: true,
                                    message: "Select Gender",
                                  },
                                ]}
                                initialValue={patientData?.Gender}
                              >
                                <Select
                                  options={genderDropDownOptions}
                                  defaultValue="disabled"
                                  value={genderType}
                                  onChange={onGenderSelectChange}
                                  disabled={
                                    type === "edit"
                                      ? editMode
                                        ? false
                                        : true
                                      : false
                                  }
                                />
                              </Form.Item>
                            </Col>

                            <Col className="gutter-row" span={6}>
                              <Form.Item
                                label="Weight"
                                name="Weight"
                                initialValue={patientData?.Weight}
                                rules={[
                                  {
                                    required: true,
                                    message: "Enter Weight",
                                  },
                                ]}
                              >
                                <InputNumber
                                  placeholder="Enter Weight"
                                  onChange={(value) => {}}
                                  min={0}
                                  step={0.01}
                                  precision={2}
                                  max={500}
                                  style={{ width: "100%" }}
                                  disabled={
                                    type === "edit"
                                      ? editMode
                                        ? false
                                        : true
                                      : false
                                  }
                                />
                              </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={6}>
                              <Form.Item
                                label="Client Name"
                                name="ClientName"
                                initialValue={patientData?.ClientName}
                                rules={[
                                  {
                                    required: true,
                                    message: "Enter Client Name",
                                  },
                                  {
                                    pattern: AlphabetRegex,
                                    message: "Enter valid Client Name",
                                  },
                                  {
                                    max: 100,
                                    message: "Client Name limit exceeded!",
                                  },
                                ]}
                                onBlur={(e) => FormatInput(e, form)}
                              >
                                <Input
                                  placeholder="Example: Raj Kumar"
                                  disabled={
                                    type === "edit"
                                      ? editMode
                                        ? false
                                        : true
                                      : false
                                  }
                                />
                              </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={6}>
                              <Form.Item
                                label="Primary Phone Number"
                                name="Phone-Primary"
                                initialValue={patientData?.Phone?.Primary}
                                rules={[
                                  {
                                    required: true,
                                    message: "Enter Phone number",
                                  },
                                  {
                                    pattern: PhoneRegex,
                                    message: "Enter valid Phone number",
                                  },
                                ]}
                              >
                                <Input
                                  placeholder="Primary Phone Number"
                                  disabled={
                                    type === "edit"
                                      ? editMode
                                        ? false
                                        : true
                                      : false
                                  }
                                />
                              </Form.Item>
                            </Col>
                          </Row>

                          <Row
                            gutter={{
                              xs: 8,
                              sm: 16,
                              md: 24,
                              lg: 32,
                            }}
                          >
                            {" "}
                            <Col className="gutter-row" span={6}>
                              <Form.Item
                                label="Alternate Phone Number"
                                name="Phone-Alternate"
                                initialValue={patientData?.Phone?.Alternate}
                                rules={[
                                  {
                                    pattern: PhoneRegex,
                                    message: "Enter valid Phone number",
                                  },
                                ]}
                              >
                                <Input
                                  placeholder="Alternate Phone Number"
                                  disabled={
                                    type === "edit"
                                      ? editMode
                                        ? false
                                        : true
                                      : false
                                  }
                                />
                              </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={6}>
                              <Form.Item
                                label="Fee Amount"
                                name="InitialAmount"
                                initialValue={patientData?.InitialAmount}
                                rules={[
                                  {
                                    validator: (_, value) => {
                                      if (value !== 0) {
                                        return Promise.resolve();
                                      } else {
                                        return Promise.reject(
                                          "Enter Valid Fee Amount!"
                                        );
                                      }
                                    },
                                    message: "Enter Valid Fee Amount!",
                                  },
                                  {
                                    required: true,
                                    message: "Enter Fee Amount",
                                  },
                                ]}
                              >
                                <InputNumber
                                  placeholder="Fee Amount"
                                  onChange={(value) => {}}
                                  min={0}
                                  step={1}
                                  max={1000000}
                                  width={500}
                                  style={{ width: "100%" }}
                                  disabled={
                                    type === "edit"
                                      ? editMode
                                        ? false
                                        : true
                                      : false
                                  }
                                />
                              </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={6}>
                              <Form.Item
                                label="Billing Cycle (Days)"
                                name="InitialBillingCycle"
                                initialValue={patientData?.InitialBillingCycle}
                                rules={[
                                  // {
                                  //   validator: (_, value) => {
                                  //     let validatedDatesInBillingCycleMonth =
                                  //       validateDatesInBillingCycleMonth(value);
                                  //     if (
                                  //       value !== 0 &&
                                  //       validatedDatesInBillingCycleMonth
                                  //     ) {
                                  //       return Promise.resolve();
                                  //     } else {
                                  //       return Promise.reject(
                                  //         "Enter Valid Billing Cycle Days!"
                                  //       );
                                  //     }
                                  //   },
                                  //   message: "Enter Valid Billing Cycle Days!",
                                  // },
                                  {
                                    required: true,
                                    message: "Enter Billing Cycle",
                                  },
                                ]}
                              >
                                <InputNumber
                                  placeholder="Billing Cycle: Eg: 28, 30, 31"
                                  onChange={(value) => {}}
                                  min={1}
                                  step={1}
                                  max={60}
                                  width={500}
                                  style={{ width: "100%" }}
                                  disabled={
                                    type === "edit"
                                      ? editMode
                                        ? patientData?.Status === "Unassigned"
                                          ? false
                                          : true
                                        : true
                                      : false
                                  }
                                />
                              </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={6}>
                              <Form.Item
                                label="Date of Joining"
                                name="JoiningDate"
                                rules={[
                                  {
                                    required: true,
                                    message: "Select Joining Date",
                                  },
                                ]}
                                initialValue={dayjs(patientData?.JoiningDate)}
                              >
                                <DatePicker placeholder="Select Joining Date" />
                              </Form.Item>
                            </Col>
                          </Row>

                          <Row
                            gutter={{
                              xs: 8,
                              sm: 16,
                              md: 24,
                              lg: 32,
                            }}
                          >
                            <Col className="gutter-row" span={6}>
                              <Form.Item
                                label="Street Address"
                                name="Address-Street"
                                initialValue={patientData?.Address?.Street}
                                rules={[
                                  {
                                    required: true,
                                    message: "Enter Street Address",
                                  },
                                  {
                                    max: 50,
                                    message: "Street Address limit exceeded!",
                                  },
                                ]}
                                onBlur={(e) => FormatInput(e, form)}
                              >
                                <Input
                                  placeholder="Street Address"
                                  disabled={
                                    type === "edit"
                                      ? editMode
                                        ? false
                                        : true
                                      : false
                                  }
                                />
                              </Form.Item>
                            </Col>

                            <Col className="gutter-row" span={6}>
                              <Form.Item
                                label="City / Town / Village"
                                name="Address-City"
                                initialValue={patientData?.Address?.City}
                                rules={[
                                  {
                                    required: true,
                                    message: "Enter City Address",
                                  },
                                  {
                                    max: 50,
                                    message: "City Address limit exceeded!",
                                  },
                                  {
                                    pattern: AlphabetRegex,
                                    message: "Enter valid City Address",
                                  },
                                ]}
                                onBlur={(e) => FormatInput(e, form)}
                              >
                                <Input
                                  placeholder="City"
                                  disabled={
                                    type === "edit"
                                      ? editMode
                                        ? false
                                        : true
                                      : false
                                  }
                                />
                              </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={6}>
                              <Form.Item
                                label="Taluka / Subdivison"
                                name="Address-Taluka"
                                initialValue={patientData?.Address?.Taluka}
                                rules={[
                                  {
                                    required: true,
                                    message: "Enter Taluka/Subdivison Address",
                                  },
                                  {
                                    max: 50,
                                    message:
                                      "Taluka/Subdivison Address limit exceeded!",
                                  },
                                  {
                                    pattern: AlphabetRegex,
                                    message:
                                      "Enter valid Taluka/Subdivison Address",
                                  },
                                ]}
                                onBlur={(e) => FormatInput(e, form)}
                              >
                                <Input
                                  placeholder="Taluka/Subdivison"
                                  disabled={
                                    type === "edit"
                                      ? editMode
                                        ? false
                                        : true
                                      : false
                                  }
                                />
                              </Form.Item>
                            </Col>

                            <Col className="gutter-row" span={6}>
                              <Form.Item
                                label="District"
                                name="Address-District"
                                initialValue={patientData?.Address?.Taluka}
                                rules={[
                                  {
                                    required: true,
                                    message: "Enter District Address",
                                  },
                                  {
                                    max: 50,
                                    message: "District Address limit exceeded!",
                                  },
                                  {
                                    pattern: AlphabetRegex,
                                    message: "Enter valid District Address",
                                  },
                                ]}
                                onBlur={(e) => FormatInput(e, form)}
                              >
                                <Input
                                  placeholder="Taluka"
                                  disabled={
                                    type === "edit"
                                      ? editMode
                                        ? false
                                        : true
                                      : false
                                  }
                                />
                              </Form.Item>
                            </Col>
                          </Row>

                          <Row
                            gutter={{
                              xs: 8,
                              sm: 16,
                              md: 24,
                              lg: 32,
                            }}
                          >
                            <Col className="gutter-row" span={6}>
                              <Form.Item
                                label="State"
                                name="Address-State"
                                initialValue={patientData?.Address?.State}
                                rules={[
                                  {
                                    required: true,
                                    message: "Enter State Address",
                                  },
                                  {
                                    max: 50,
                                    message: "State Address limit exceeded!",
                                  },
                                  {
                                    pattern: AlphabetRegex,
                                    message: "Enter valid State Address",
                                  },
                                ]}
                                onBlur={(e) => FormatInput(e, form)}
                              >
                                <Input
                                  placeholder="State"
                                  disabled={
                                    type === "edit"
                                      ? editMode
                                        ? false
                                        : true
                                      : false
                                  }
                                />
                              </Form.Item>
                            </Col>

                            <Col className="gutter-row" span={6}>
                              <Form.Item
                                label="Pincode"
                                name="Address-PinCode"
                                initialValue={patientData?.Address?.PinCode}
                                rules={[
                                  {
                                    required: true,
                                    message: "Enter Pincode",
                                  },
                                  {
                                    pattern: PinCodeRegex,
                                    message: "Enter valid PinCode",
                                  },
                                ]}
                              >
                                <Input
                                  placeholder="Pin Code"
                                  disabled={
                                    type === "edit"
                                      ? editMode
                                        ? false
                                        : true
                                      : false
                                  }
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        </>
                      )}
                    </div>
                  </div>
                </Form>
              )}

            {((formStep === 2 && staffSelectionDataReady) ||
              displayAssignedStaffTab) && (
              <Form
                form={form2}
                className={type === "edit" ? "patientForm edit" : "patientForm"}
                layout="vertical"
                onFinish={assignStaffToPatient}
                onFinishFailed={onFinishFailed}
              >
                <div className="patientFormFields">
                  {type === "add" && (
                    <div className="stepDescriptionText">
                      <h5>{stepDescriptionText}</h5>
                    </div>
                  )}
                  <p className="billingCycleDays">
                    Billing Cycle: {patientData?.InitialBillingCycle} days
                  </p>
                  <div className="patientFormFieldsScrollBox">
                    <>
                      <Row
                        gutter={{
                          xs: 8,
                          sm: 16,
                          md: 24,
                          lg: 32,
                        }}
                      >
                        <Col className="gutter-row" span={6}>
                          <Form.Item
                            label="Billing Cycle Start Date"
                            name="StartDate"
                            rules={[
                              {
                                required: true,
                                message: "Select Start Date",
                              },
                            ]}
                            initialValue={dayjs(
                              getBillingCycleStartNEndDates("StartDate")
                            )}
                          >
                            <DatePicker
                              minDate={dayjs().subtract(60, "day")}
                              maxDate={getBillingCycleStartNEndDates(
                                "StartDate"
                              )}
                              onChange={(value) => {
                                form2.setFieldsValue({
                                  StartDate: dayjs(value),
                                  EndDate: dayjs(value).add(
                                    patientData?.InitialBillingCycle - 1,
                                    "day"
                                  ),
                                });
                              }}
                              disabled={
                                patientData?.Status === "Unassigned"
                                  ? false
                                  : true
                              }
                            />
                          </Form.Item>
                        </Col>

                        <Col className="gutter-row" span={6}>
                          <Form.Item
                            label="Billing Cycle End Date"
                            name="EndDate"
                            rules={[
                              {
                                validator: (_, value) => {
                                  let validatedDateRange = validateDateRange(
                                    value?.$d,
                                    form2.getFieldValue("StartDate")?.$d
                                  );
                                  if (validatedDateRange) {
                                    return Promise.resolve();
                                  } else {
                                    return Promise.reject(
                                      "End Date before Start Date!"
                                    );
                                  }
                                },
                                message: "End Date before Start Date!",
                              },
                              {
                                validator: (_, value) => {
                                  let validatedBillingCycleRange =
                                    validateBillingCycleRange(
                                      value?.$d,
                                      form2.getFieldValue("StartDate")?.$d,
                                      patientData?.InitialBillingCycle
                                    );
                                  if (validatedBillingCycleRange) {
                                    return Promise.resolve();
                                  } else {
                                    return Promise.reject(
                                      "Selected Date does not match Billing Cycle!"
                                    );
                                  }
                                },
                                message:
                                  "Selected Date does not match Billing Cycle!",
                              },
                              {
                                required: true,
                                message: "Select End Date",
                              },
                            ]}
                            initialValue={dayjs(
                              getBillingCycleStartNEndDates("EndDate")
                            )}
                          >
                            <DatePicker
                              // minDate={getBillingCycleStartNEndDates(
                              //   "StartDate"
                              // )}
                              // maxDate={getBillingCycleStartNEndDates("EndDate")}
                              // disabled={
                              //   patientData?.Status === "Unassigned"
                              //     ? false
                              //     : true
                              // }
                              disabled={true}
                            />
                          </Form.Item>
                        </Col>

                        <Col className="gutter-row" span={6}>
                          <Form.Item
                            label="Staff Type"
                            name="StaffType"
                            rules={[
                              {
                                validator: (_, value) => {
                                  if (value !== "disabled") {
                                    return Promise.resolve();
                                  } else {
                                    return Promise.reject(
                                      "Please Select Staff Type"
                                    );
                                  }
                                },
                                message: "Please Select Staff Type",
                              },
                              {
                                required: true,
                                message: "Select Staff Type",
                              },
                            ]}
                            initialValue={
                              patientData.Status === "Assigned"
                                ? assignedStaffData?.Designation
                                : "disabled"
                            }
                          >
                            <Select
                              options={staffTypeDropDownOptions}
                              defaultValue="disabled"
                              value={staffType}
                              onChange={onStaffTypeChange}
                              disabled={
                                patientData?.Status === "Unassigned"
                                  ? false
                                  : true
                              }
                            />
                          </Form.Item>
                        </Col>

                        <Col className="gutter-row" span={6}>
                          <Form.Item
                            label="Staff Member"
                            name="StaffSelection"
                            rules={[
                              {
                                validator: (_, value) => {
                                  if (value !== "disabled") {
                                    return Promise.resolve();
                                  } else {
                                    return Promise.reject(
                                      "Please Select Staff Member"
                                    );
                                  }
                                },
                                message: "Please Select Staff Member",
                              },
                              {
                                validator: (_, value) => {
                                  let validatedStaffSelection =
                                    validateStaffSelection(value);
                                  if (validatedStaffSelection) {
                                    return Promise.resolve();
                                  } else {
                                    return Promise.reject(
                                      "Please Select Staff Member"
                                    );
                                  }
                                },
                                message: "Please Select Staff Member",
                              },
                              {
                                required: true,
                                message: "Select Staff",
                              },
                            ]}
                            initialValue={
                              patientData.Status === "Assigned"
                                ? {
                                    value: assignedStaffData?._id,
                                    label:
                                      assignedStaffData?.Name?.FNAME +
                                      " " +
                                      assignedStaffData?.Name?.LName,
                                  }
                                : "disabled"
                            }
                          >
                            <Select
                              showSearch
                              placeholder="Select Staff Member"
                              optionFilterProp="children"
                              options={staffDropDownOptions}
                              defaultValue="disabled"
                              value={staffSelection}
                              onChange={onStaffChange}
                              onSearch={onStaffSearch}
                              filterOption={filterStaffOption}
                              disabled={
                                patientData?.Status === "Unassigned"
                                  ? false
                                  : true
                              }
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      {patientData?.Status === "Assigned" && (
                        <Row
                          gutter={{
                            xs: 8,
                            sm: 16,
                            md: 24,
                            lg: 32,
                          }}
                        >
                          <Col className="gutter-row" span={6}>
                            <Form.Item
                              label="Assignment Start Date"
                              name="AssignmentStartDate"
                              initialValue={dayjs(
                                getAssignmentHistoryData("StartDate")
                              )}
                            >
                              <DatePicker
                                // minDate={dayjs(dateToday.toString(), dateFormat)}
                                onChange={(value) => {
                                  form2.setFieldsValue({
                                    StartDate: dayjs(value),
                                  });
                                }}
                                disabled={true}
                              />
                            </Form.Item>
                          </Col>

                          <Col className="gutter-row" span={6}>
                            <Form.Item
                              label="Assignment End Date"
                              name="AssignmentEndDate"
                              initialValue={dayjs(
                                getAssignmentHistoryData("EndDate")
                              )}
                            >
                              <DatePicker
                                // minDate={dayjs(dateToday.toString(), dateFormat)}
                                disabled={true}
                              />
                            </Form.Item>
                          </Col>

                          <Col className="gutter-row" span={6}>
                            <Form.Item
                              label="Assignment Working Days"
                              name="WorkingDays"
                              initialValue={getAssignmentHistoryData(
                                "Working Days"
                              )}
                            >
                              <InputNumber
                                onChange={(value) => {}}
                                min={1}
                                step={1}
                                max={31}
                                width={500}
                                style={{ width: "100%" }}
                                disabled={true}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      )}
                    </>
                  </div>
                </div>
              </Form>
            )}

            {displayPatientPaymentsTab && (
              <Space
                direction="vertical"
                size="middle"
                style={{
                  display: "flex",
                }}
              >
                <Card className="tableCard">
                  <div className="tableHeader">
                    <div className="searchBar">
                      <Search
                        placeholder="Search here..."
                        allowClear
                        onSearch={(value) => {
                          onTableSearch(value);
                        }}
                        onChange={(e) => {
                          onTableSearch(e.target.value);
                        }}
                        style={{
                          width: 300,
                        }}
                      />
                      {filteredInfo && (
                        <Button
                          className="clearFiltersButton"
                          onClick={clearFilters}
                        >
                          Clear Filters
                        </Button>
                      )}
                      <div className="paymentMonthPicker">
                        <DatePicker onChange={onMonthChange} picker="month" />
                      </div>
                    </div>
                    <div className="recordsCount">
                      <UnorderedListOutlined />
                      <p>
                        Records{": "}
                        {searchData.length > 0
                          ? searchData.length
                          : tableData.length}
                      </p>
                    </div>
                  </div>

                  <hr />
                  <Table
                    className="table"
                    columns={columns}
                    dataSource={
                      searchData.length > 0 ? searchData : paymentTableData
                    }
                    onChange={onChange}
                    loading={dataIsReady ? !dataIsReady : dataIsReady}
                    pagination={{ pageSize: 5 }}
                  />
                </Card>
              </Space>
            )}
          </div>

          <ModalComponent
            openModal={openModal}
            setOpenModal={setOpenModal}
            width={"35%"}
            title={""}
            message={""}
            hasOk={false}
            hasCancel={false}
            formData={
              <UnassignStaffModalForm
                type={"Unassign Staff Member"}
                title={"Unassign Staff Member"}
                action={confirmFormSubmission}
                exitAction={confirmCancel}
                assignedStaffID={patientData?.CurrentStaffID}
                patientID={patientData?._id}
              />
            }
          />
        </div>
      )}
    </>
  );
};

export default PatientFormPage;
