import {
  EditOutlined,
  DeleteOutlined,
  SyncOutlined,
  CheckOutlined,
  ArrowDownOutlined,
  UserAddOutlined,
  UserDeleteOutlined,
} from "@ant-design/icons";
import "./TableActionComponent.scss";

const TableActionComponent = ({
  display,
  setDisplay,
  positions,
  type = null,
  editAction = null,
  deleteAction = null,
  convertAction = null,
  inactiveAction = null,
  activeAction = null,
  paidAction = null,
  downloadAction = null,
}) => {
  let positionX = positions.top + window.scrollY;
  let positionY = positions.left + window.scrollX;

  return (
    <>
      {display && (
        <div
          className="tableActionComponent"
          style={{
            top: `${positionX + 30}px`,
            left: `${positionY - 10}px`,
          }}
        >
          <div className="tableActionItems">
            {editAction && (
              <div
                className="tableActionItem"
                onClick={() => {
                  setDisplay(false);
                  if (editAction) {
                    editAction();
                  }
                }}
              >
                <EditOutlined className="tableActionIcon edit" />
                <span className="tableActionTitle">Edit</span>
              </div>
            )}

            {deleteAction && (
              <div
                className="tableActionItem"
                onClick={() => {
                  setDisplay(false);
                  if (deleteAction) {
                    deleteAction();
                  }
                }}
              >
                <DeleteOutlined className="tableActionIcon delete" />
                <span className="tableActionTitle">Delete</span>
              </div>
            )}

            {type === "leads" && convertAction && (
              <div
                className="tableActionItem"
                onClick={() => {
                  setDisplay(false);
                  if (convertAction) {
                    convertAction();
                  }
                }}
              >
                <SyncOutlined className="tableActionIcon convert" />
                <span className="tableActionTitle">Convert</span>
              </div>
            )}

            {(type === "staff" || type === "patients") && inactiveAction && (
              <div
                className="tableActionItem"
                onClick={() => {
                  setDisplay(false);
                  if (inactiveAction) {
                    inactiveAction();
                  }
                }}
              >
                <UserDeleteOutlined className="tableActionIcon inactive" />
                <span className="tableActionTitle">Inactive</span>
              </div>
            )}

            {(type === "staff" || type === "patients") && activeAction && (
              <div
                className="tableActionItem"
                onClick={() => {
                  setDisplay(false);
                  if (activeAction) {
                    activeAction();
                  }
                }}
              >
                <UserAddOutlined className="tableActionIcon active" />
                <span className="tableActionTitle">Active</span>
              </div>
            )}

            {(type === "salaries" || type === "payments") && paidAction && (
              <>
                <div
                  className="tableActionItem"
                  onClick={() => {
                    setDisplay(false);
                    if (paidAction) {
                      paidAction();
                    }
                  }}
                >
                  <CheckOutlined className="tableActionIcon paid" />
                  <span className="tableActionTitle">Confirm</span>
                </div>
              </>
            )}
            {(type === "salaries" || type === "payments") && downloadAction && (
              <>
                <div
                  className="tableActionItem"
                  onClick={() => {
                    setDisplay(false);
                    if (downloadAction) {
                      downloadAction();
                    }
                  }}
                >
                  <ArrowDownOutlined className="tableActionIcon download" />
                  <span className="tableActionTitle">Download</span>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default TableActionComponent;
