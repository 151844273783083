import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { hideLoading, showLoading } from "../../../redux/LoaderSlice";
import ApiService from "../../../services/ApiService";
import { DataProcessor } from "../../../services/DataProcessor";
import { DataFilter, TableSearch } from "../../../services/DataFilter";
import { Table, Input, Button, Card, Space } from "antd";
import { MoreOutlined, UnorderedListOutlined } from "@ant-design/icons";
import TableActionComponent from "../../../components/tableActionComponent/TableActionComponent";
import toast from "react-hot-toast";
import "./LeadsTablePage.scss";
import { EncodeString } from "../../../services/TableUtilities";
import ModalComponent from "../../../components/modalComponent/ModalComponent";
import ErrorValidation from "../../../services/ErrorValidation";

const { Search } = Input;

const LeadsTablePage = ({ entity }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [dataIsReady, setDataIsReady] = useState(false);
  const [entityTableFilters, setEntityTableFilters] = useState([]);
  const [filterIsReady, setFilterIsReady] = useState(false);
  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const [tableData, setTableData] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [currentRow, setCurrentRow] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const [displayTableActionComponent, setDisplayTableActionComponent] =
    useState(false);
  const [tableActionComponentPostions, setTableActionComponentPostions] =
    useState([]);
  const [clickedElementClassName, setClickedElementClassName] = useState(null);

  let unprocessedData = [];
  let processedData = [];

  const entityName =
    entity !== "Staff" ? entity.toLowerCase() + "s" : entity.toLowerCase();
  const entityTableFilterColumnNames = [
    "Name",
    { Address: ["City", "State"] },
    "Status",
  ];

  const entityButtonLink = `/${entityName}/form`;
  let encodedEntityID = null;

  const getData = async () => {
    dispatch(showLoading());
    const apiURL = `/${entityName}/`;
    const apiResponse = await ApiService("get", apiURL);
    const apiData = apiResponse?.response;
    if (apiData) {
      unprocessedData = apiData;
      processedData = await DataProcessor(unprocessedData);
      processedData = processedData.map((item) => ({
        ...item,
        tableActionButtons: item?._id,
      }));
      setTableData(processedData);
      setEntityTableFilters(getTableFilters());
      setFilterIsReady(true);
      setDataIsReady(true);
      dispatch(hideLoading());
    } else {
      const apiError = apiResponse?.error;
      let errorMessage = ErrorValidation(apiError?.response?.status);
      dispatch(hideLoading());
      toast.error(errorMessage);
    }
  };

  const getTableFilters = () => {
    let tableFilters = [];
    entityTableFilterColumnNames.forEach((filterColumn) => {
      let tableFilter = [];
      if (
        typeof filterColumn === "object" &&
        !Array.isArray(filterColumn) &&
        filterColumn !== null
      ) {
        for (let [key, value] of Object.entries(filterColumn)) {
          tableFilter = DataFilter(unprocessedData, key, value);
          tableFilters[key] = tableFilter;
        }
      } else {
        tableFilter = DataFilter(processedData, filterColumn);
        tableFilters[filterColumn] = tableFilter;
      }
    });
    return tableFilters;
  };

  let columns = [
    // {
    //   title: "Sr. No.",
    //   dataIndex: "key",
    //   key: "key",
    //   render: (value, record) => (value += 1),
    // },
    {
      title: "Name",
      dataIndex: "Name",
      key: "Name",
      filters: filterIsReady ? entityTableFilters["Name"] : null,
      onFilter: (value, record) => onTableFilter(value, record),
      filteredValue: filteredInfo.Name || null,
      sorter: (a, b) => a.Name.length - b.Name.length,
      sortDirections: ["descend"],
      render: (value, record) => (
        (encodedEntityID = EncodeString(record?._id)),
        (
          <Link
            className="tableRowLink"
            to={`../leads/edit_lead/${encodedEntityID}`}
          >
            {value}
          </Link>
        )
      ),
    },
    {
      title: "Phone",
      dataIndex: "Phone",
      key: "Phone",
      sorter: (a, b) => a.Phone - b.Phone,
    },
    {
      title: "Email",
      dataIndex: "Email",
      key: "Email",
      sorter: (a, b) => a.Email.length - b.Email.length,
    },
    {
      title: "Address",
      dataIndex: "Address",
      key: "Address",
      filters: filterIsReady ? entityTableFilters["Address"] : null,
      onFilter: (value, record) => onTableFilter(value, record),
      filteredValue: filteredInfo.Address || null,
    },
    {
      title: "Appointment Date",
      dataIndex: "AppointmentDate",
      key: "AppointmentDate",
      sorter: (a, b) => a.AppointmentDate.length - b.AppointmentDate.length,
      render: (value) => new Date(value).toLocaleDateString("en-GB"),
    },
    {
      title: "Lead Source",
      dataIndex: "LeadSource",
      key: "LeadSource",
      sorter: (a, b) => a.LeadSource.length - b.LeadSource.length,
    },
    {
      title: "Status",
      dataIndex: "Status",
      key: "Status",
      filters: filterIsReady ? entityTableFilters["Status"] : null,
      onFilter: (value, record) => onTableFilter(value, record),
      filteredValue: filteredInfo.Status || null,
      sorter: (a, b) => a.Status.length - b.Status.length,
    },
    {
      title: "Action",
      dataIndex: "tableActionButtons",
      key: "operation",
      render: (value, record) => (
        <div
          className="tableMenuDiv"
          onClick={(e) => {
            setDisplayTableActionComponent(!displayTableActionComponent);
            setTableActionComponentPostions(e.target.getBoundingClientRect());
            setCurrentRow(record);
          }}
        >
          <MoreOutlined className="tableMenuIcon" />
        </div>
      ),
      width: 80,
    },
  ];

  const onChange = (pagination, filters, sorter, extra) => {
    setFilteredInfo(filters);
    setSortedInfo(sorter);
  };

  const onTableSearch = (value) => {
    let searchResult = TableSearch(value, tableData);
    if (searchResult?.length > 0) {
      setSearchData(searchResult);
    } else {
      toast.error("No matching records found!");
    }
  };

  const onTableFilter = (value, record) => {
    if (record?.Name?.indexOf(value) === 0) {
      return true;
    }

    if (record?.Address?.includes(value) === true) {
      return true;
    }

    if (record?.Status?.indexOf(value) === 0) {
      return true;
    }
  };

  const clearFilters = () => {
    setFilteredInfo({});
    setSortedInfo({});
  };

  if (displayTableActionComponent && clickedElementClassName) {
    if (
      !clickedElementClassName.includes("SVGAnimatedString") &&
      !clickedElementClassName.includes("tableMenuDiv")
    ) {
      setDisplayTableActionComponent(false);
    }
  }

  const confirmCancel = () => {
    setOpenModal(false);
  };

  const editTableRow = () => {
    let encodedEntityID = EncodeString(currentRow?._id);
    navigate("../leads/edit_lead/" + encodedEntityID);
  };

  const deleteTableRow = async () => {
    let deletedLeadId = currentRow?._id;
    const apiURL = "/leads/" + deletedLeadId;
    const apiResponse = await ApiService("delete", apiURL);
    const apiData = apiResponse?.response;
    if (apiData) {
      setOpenDeleteModal(false);
      dispatch(hideLoading());
      toast.success("Lead Record was deleted successfully!");
      getData();
    } else {
      const apiError = apiResponse?.error;
      let errorMessage = ErrorValidation(apiError?.response?.status);
      dispatch(hideLoading());
      toast.error(errorMessage);
    }
  };

  const convertLeadToPatient = async () => {
    if (currentRow?.Status !== "Converted") {
      dispatch(showLoading());
      let apiURLConvert = "/leads/" + currentRow?._id;
      const apiResponseConvert = await ApiService("get", apiURLConvert);
      const apiDataConvert = apiResponseConvert?.response;
      if (apiDataConvert) {
        let requestObject = {
          PatientRollNo: "",
          Name: apiDataConvert?.Name,
          Age: 0,
          Gender: "",
          Weight: 0,
          Phone: apiDataConvert?.Phone,
          Address: apiDataConvert?.Address,
          Status: "Unassigned",
          TypeOfWork: apiDataConvert?.TypeOfWork
            ? apiDataConvert?.TypeOfWork
            : "",
          Languages: apiDataConvert?.Languages ? apiDataConvert?.Languages : "",
          Diagnosis: "",
          ClientName: "",
          InitialBillingCycle: 0,
          InitialAmount: 0,
          BillingCycleHistory: [],
          Payments: [],
          JoiningDate: apiDataConvert?.AppointmentDate,
          CurrentStaffID: "",
        };

        const apiURL = "/patients/";
        const apiResponse = await ApiService("post", apiURL, requestObject);
        const apiData = apiResponse?.response;
        if (apiData) {
          let requestObject = {
            Status: "Converted",
          };
          const apiURL = "/leads/" + currentRow?._id;
          const apiResponse = await ApiService("put", apiURL, requestObject);
          const apiData = apiResponse?.response;
          if (apiData) {
            toast.success("Lead converted to Patient successfully");
            getData();
            dispatch(hideLoading());
          } else {
            const apiError = apiResponse?.error;
            let errorMessage = ErrorValidation(apiError?.response?.status);
            dispatch(hideLoading());
            toast.error(errorMessage);
          }
        } else {
          const apiError = apiResponse?.error;
          let errorMessage = ErrorValidation(apiError?.response?.status);
          dispatch(hideLoading());
          toast.error(errorMessage);
        }
      } else {
        const apiError = apiResponseConvert?.error;
        let errorMessage = ErrorValidation(apiError?.response?.status);
        dispatch(hideLoading());
        toast.error(errorMessage);
      }
      // console.log(currentRow, unprocessedData, processedData);
    } else {
      toast.error("Lead already converted to Patient!");
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      {dataIsReady && (
        <div
          className="tablePage"
          onClick={(e) => {
            setClickedElementClassName(e.target.className.toString());
          }}
        >
          <div className="header">
            <h3 className="headerText">
              {entity !== "Staff" ? entity + "s" : entity}
            </h3>
            <Button
              className="headerButton"
              onClick={() => {
                navigate("../leads/add_lead/add");
              }}
            >
              ADD {entity.toUpperCase()}
            </Button>
          </div>
          <Space
            direction="vertical"
            size="middle"
            style={{
              display: "flex",
            }}
          >
            <Card className="tableCard">
              <div className="tableHeader">
                <div className="searchBar">
                  <Search
                    placeholder="Search here..."
                    allowClear
                    onSearch={(value) => {
                      onTableSearch(value);
                    }}
                    onChange={(e) => {
                      onTableSearch(e.target.value);
                    }}
                    style={{
                      width: 300,
                    }}
                  />
                  {filteredInfo && (
                    <Button
                      className="clearFiltersButton"
                      onClick={clearFilters}
                    >
                      Clear Filters
                    </Button>
                  )}
                </div>
                <div className="recordsCount">
                  <UnorderedListOutlined />
                  <p>
                    Records{": "}
                    {searchData.length > 0
                      ? searchData.length
                      : tableData.length}
                  </p>
                </div>
              </div>
              <hr />

              <TableActionComponent
                type="leads"
                display={displayTableActionComponent}
                setDisplay={setDisplayTableActionComponent}
                positions={tableActionComponentPostions}
                editAction={editTableRow}
                deleteAction={() => {
                  setOpenDeleteModal(true);
                }}
                convertAction={
                  currentRow?.Status !== "Converted"
                    ? convertLeadToPatient
                    : null
                }
              />

              <Table
                className="table"
                columns={columns}
                dataSource={searchData.length > 0 ? searchData : tableData}
                onChange={onChange}
                pagination={{ pageSize: 5 }}
              />
            </Card>
          </Space>

          <ModalComponent
            openModal={openDeleteModal}
            setOpenModal={setOpenDeleteModal}
            width={"40%"}
            title={`Delete ${entity}`}
            message={`Are you sure you want to delete ${
              entity + " " + currentRow?.Name
            }?`}
            action={deleteTableRow}
            exitAction={confirmCancel}
          />
        </div>
      )}
    </>
  );
};

export default LeadsTablePage;
