import React from "react";
import { BACKEND_URL } from "../../App";
import "./InvalidPage.scss";
import { Link } from "react-router-dom";

const InvalidPage = () => {
  return (
    <div className="invalidPage">
      <div className="invalidBackgroundImg"></div>
      <div className="invalidMessage">
        <div className="invalidTitle">
          <h1>
            This is not the webpage that you are looking for! Click the button
            below to redirect to the Home Page.
          </h1>
        </div>
        <Link className="homeButton" to="/">
          Home
        </Link>
        <div className="invalidBackgroundImg2"></div>
      </div>
    </div>
  );
};

export default InvalidPage;
