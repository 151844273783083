import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { EncodeString } from "../../../services/TableUtilities";
import { showLoading, hideLoading } from "../../../redux/LoaderSlice";
import ApiService from "../../../services/ApiService";
import {
  FormObjectProcessor,
  FormatInput,
  ExtractNewEntityID,
} from "../../../services/DataProcessor";
import ErrorValidation from "../../../services/ErrorValidation";
import {
  AlphabetRegex,
  PhoneRegex,
  PinCodeRegex,
} from "../../../static/RegexPattern";
import {
  Breadcrumb,
  Form,
  Input,
  Select,
  Button,
  Row,
  Col,
  DatePicker,
} from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { ArrowLeftOutlined } from "@ant-design/icons";
import toast from "react-hot-toast";
import "./LeadsFormPage.scss";

const { TextArea } = Input;
const isoFormat = dayjs();
dayjs.extend(customParseFormat);
const dateFormat = "YYYY-MM-DD";
const dateToday = new Date().toJSON().slice(0, 10);

const LeadsFormPage = ({ type }) => {
  const [form] = Form.useForm();
  const { leadID } = useParams();
  var Buffer = require("buffer/").Buffer;
  const decodedLeadIDString = Buffer.from(leadID, "base64").toString();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [leadData, setLeadData] = useState([]);
  const [dataIsReady, setDataIsReady] = useState(false);
  const [access, setAccess] = useState(true);
  const [editMode, setEditMode] = useState(false);
  const [leadType, setLeadType] = useState("");
  const [leadStatus, setLeadStatus] = useState("");
  const [displayLeadsDetails, setDisplayLeadsDetails] = useState(true);

  let responseObj = localStorage.getItem("token");
  if (responseObj) {
    responseObj = JSON.parse(Buffer.from(responseObj, "base64").toString());
  }
  let role = null;

  if (responseObj?.employeeData) {
    role = responseObj?.employeeData?.Role;
  }

  const leadSourceDropDownOptions = [
    { value: "disabled", label: "Select a Source", disabled: true },
    { value: "Reference", label: "Reference" },
    { value: "Website", label: "Website" },
    { value: "Sales", label: "Sales" },
    { value: "Social Media", label: "Social Media" },
  ];

  const leadStatusDropDownOptions = [
    { value: "disabled", label: "Select a Status", disabled: true },
    { value: "Pending", label: "Pending" },
    { value: "In Progress", label: "In Progress" },
  ];

  const onLeadSourceSelectChange = (value) => {
    setLeadType(value);
  };

  const onLeadStatusSelectChange = (value) => {
    setLeadStatus(value);
  };

  let apiURL = "/leads/";
  let apiType = "post";
  if (type === "edit") {
    apiType = "put";
    apiURL = "/leads/" + decodedLeadIDString;
  }

  const getLeadData = async () => {
    dispatch(showLoading());
    const apiResponse = await ApiService("get", apiURL);
    const apiData = apiResponse?.response;
    if (apiData) {
      setLeadData(apiData);
      dispatch(hideLoading());
      setDataIsReady(true);
    } else {
      const apiError = apiResponse?.error;
      let errorMessage = ErrorValidation(apiError?.response?.status);
      toast.error(errorMessage);
    }
  };

  const onFormSubmit = async (values) => {
    if (values) {
      dispatch(showLoading());
      let requestObj = FormObjectProcessor(values);
      const apiResponse = await ApiService(apiType, apiURL, requestObj);
      const apiData = apiResponse?.response;

      if (apiData) {
        setLeadData(apiData);
        setDataIsReady(true);
        if (type === "add") {
          toast.success("Lead was added Successfully!");
          await navigateToNewEntityPage(requestObj);
        } else {
          toast.success("Lead was updated Successfully!");
          dispatch(hideLoading());
        }
      } else {
        const apiError = apiResponse?.error;
        let errorMessage = ErrorValidation(apiError?.response?.status);
        toast.error(errorMessage);
        dispatch(hideLoading());
      }
    }
  };

  const onFinishFailed = (errorInfo) => {
    toast.error("Please fill all required form fields!");
    // console.log("Failed:", errorInfo);
  };

  const navigateToNewEntityPage = async (requestObj) => {
    let extractedEntityID = await ExtractNewEntityID({
      apiURL: apiURL,
      primaryPhoneNumber: requestObj["Phone"]?.Primary,
    });

    if (extractedEntityID) {
      let encodedEntityID = EncodeString(extractedEntityID);
      navigate("/leads/edit_lead/" + encodedEntityID, {
        replace: true,
      });
    }
  };

  const validateUserRole = (type = null) => {
    let validatedUserRole = false;
    if (role === "Admin" || role === "CRM") {
      validatedUserRole = true;
    }

    return validatedUserRole;
  };

  useEffect(() => {
    if (type === "edit") {
      getLeadData();
    }
  }, []);

  return (
    <>
      {(type === "add" || dataIsReady) && (
        <div className="leadsFormContainer">
          <div className="leadsFormDiv">
            <Breadcrumb
              className="breadCrumb"
              items={[
                {
                  title: <a href="../../leads">Leads</a>,
                },
                {
                  title: type === "edit" ? "Edit Leads" : "Add Lead",
                },
              ]}
            />

            <div className={type === "edit" ? "header edit" : "header"}>
              <div className="headerStart">
                <ArrowLeftOutlined
                  className="backArrowButton"
                  onClick={() => {
                    navigate("/leads");
                  }}
                />
                <h3 className="leadsFormTitle">
                  {type === "add"
                    ? "Add Lead"
                    : leadData?.Name?.FNAME + " " + leadData?.Name?.LName}
                </h3>
              </div>

              {displayLeadsDetails && (
                <div>
                  {editMode && (
                    <Button
                      className="leadsFormButton cancel"
                      danger
                      onClick={() => {
                        setEditMode(false);
                      }}
                    >
                      CANCEL
                    </Button>
                  )}

                  {!editMode && type === "edit" && (
                    <Button
                      className="leadsFormButton"
                      type="primary"
                      onClick={() => {
                        let validatedUserRole = validateUserRole();
                        if (validatedUserRole) {
                          setEditMode(true);
                        } else {
                          toast.error("Unauthorized Access!");
                        }
                      }}
                      disabled={access ? false : true}
                    >
                      EDIT
                    </Button>
                  )}

                  {(type === "add" || editMode) && (
                    <Button
                      className="leadsFormButton"
                      type="primary"
                      htmlType={access ? "submit" : null}
                      onClick={() => {
                        form.submit();
                        if (editMode) {
                          setEditMode(false);
                        }
                        dispatch(showLoading());
                        setTimeout(() => {
                          dispatch(hideLoading());
                        }, 500);
                      }}
                      disabled={access ? false : true}
                    >
                      {editMode ? "SAVE" : "ADD"}
                    </Button>
                  )}
                </div>
              )}
            </div>

            {displayLeadsDetails && (
              <Form
                form={form}
                className={type === "edit" ? "leadsForm edit" : "leadsForm"}
                layout="vertical"
                onFinish={onFormSubmit}
                onFinishFailed={onFinishFailed}
              >
                <div className="leadsFormFields">
                  <div className="leadsFormFieldsScrollBox">
                    <Row
                      gutter={{
                        xs: 8,
                        sm: 16,
                        md: 24,
                        lg: 32,
                      }}
                    >
                      <Col className="gutter-row" span={6}>
                        <Form.Item
                          label="Lead Source"
                          name="LeadSource"
                          rules={[
                            {
                              required: true,
                              message: "Select a Lead Source",
                            },
                          ]}
                          initialValue={leadData?.LeadSource}
                        >
                          <Select
                            options={leadSourceDropDownOptions}
                            defaultValue="disabled"
                            value={leadType}
                            onChange={onLeadSourceSelectChange}
                            disabled={
                              type === "edit"
                                ? editMode
                                  ? false
                                  : true
                                : false
                            }
                          />
                        </Form.Item>
                      </Col>

                      <Col className="gutter-row" span={6}>
                        <Form.Item
                          label="Appointment Date"
                          name="AppointmentDate"
                          rules={[
                            {
                              required: true,
                              message: "Select Appointment Date",
                            },
                          ]}
                          initialValue={dayjs(leadData?.AppointmentDate)}
                        >
                          <DatePicker
                            // minDate={dayjs(dateToday.toString(), dateFormat)}
                            disabled={
                              type === "edit"
                                ? editMode
                                  ? false
                                  : true
                                : false
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col className="gutter-row" span={6}>
                        <Form.Item
                          label="First Name"
                          name="Name-FNAME"
                          initialValue={leadData?.Name?.FNAME}
                          rules={[
                            {
                              required: true,
                              message: "Enter First Name",
                            },
                            {
                              pattern: AlphabetRegex,
                              message: "Enter valid First Name",
                            },
                            {
                              max: 50,
                              message: "First Name limit exceeded!",
                            },
                          ]}
                          onBlur={(e) => FormatInput(e, form)}
                        >
                          <Input
                            placeholder="Example: Raj"
                            disabled={
                              type === "edit"
                                ? editMode
                                  ? false
                                  : true
                                : false
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col className="gutter-row" span={6}>
                        <Form.Item
                          label="Last Name"
                          name="Name-LName"
                          initialValue={leadData?.Name?.LName}
                          rules={[
                            {
                              required: true,
                              message: "Enter Last Name",
                            },
                            {
                              pattern: AlphabetRegex,
                              message: "Enter valid Last Name",
                            },
                            {
                              max: 50,
                              message: "Last Name limit exceeded!",
                            },
                          ]}
                          onBlur={(e) => FormatInput(e, form)}
                        >
                          <Input
                            placeholder="Example: Kumar"
                            disabled={
                              type === "edit"
                                ? editMode
                                  ? false
                                  : true
                                : false
                            }
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row
                      gutter={{
                        xs: 8,
                        sm: 16,
                        md: 24,
                        lg: 32,
                      }}
                    >
                      <Col className="gutter-row" span={6}>
                        <Form.Item
                          label="Primary Phone Number"
                          name="Phone-Primary"
                          initialValue={leadData?.Phone?.Primary}
                          rules={[
                            {
                              required: true,
                              message: "Enter Phone number",
                            },
                            {
                              pattern: PhoneRegex,
                              message: "Enter valid Phone number",
                            },
                          ]}
                        >
                          <Input
                            placeholder="Primary Phone Number"
                            disabled={
                              type === "edit"
                                ? editMode
                                  ? false
                                  : true
                                : false
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col className="gutter-row" span={6}>
                        <Form.Item
                          label="Alternate Phone Number"
                          name="Phone-Alternate"
                          initialValue={leadData?.Phone?.Alternate}
                          rules={[
                            {
                              pattern: PhoneRegex,
                              message: "Enter valid Phone number",
                            },
                          ]}
                        >
                          <Input
                            placeholder="Alternate Phone Number"
                            disabled={
                              type === "edit"
                                ? editMode
                                  ? false
                                  : true
                                : false
                            }
                          />
                        </Form.Item>
                      </Col>

                      <Col className="gutter-row" span={6}>
                        <Form.Item
                          label="Email"
                          name="Email"
                          initialValue={leadData?.Email}
                          validateTrigger="onBlur"
                          rules={[
                            {
                              type: "email",
                              message: "Please enter a valid email!",
                            },
                            // {
                            //   required: true,
                            //   message: "Please enter your email",
                            // },
                            {
                              max: 50,
                              message:
                                "Email exceeded permitted length! Please try again...",
                            },
                          ]}
                          onBlur={(e) => FormatInput(e, form, "email")}
                        >
                          <Input
                            placeholder="Enter a registered email"
                            disabled={
                              type === "edit"
                                ? editMode
                                  ? false
                                  : true
                                : false
                            }
                          />
                        </Form.Item>
                      </Col>

                      <Col className="gutter-row" span={6}>
                        <Form.Item
                          label="Lead Status"
                          name="Status"
                          rules={[
                            {
                              required: true,
                              message: "Select a Lead Status",
                            },
                          ]}
                          initialValue={leadData?.Status}
                        >
                          <Select
                            options={leadStatusDropDownOptions}
                            defaultValue="disabled"
                            value={leadStatus}
                            onChange={onLeadStatusSelectChange}
                            disabled={
                              leadData?.Status === "Converted"
                                ? true
                                : type === "edit"
                                ? editMode
                                  ? false
                                  : true
                                : false
                            }
                          />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row
                      gutter={{
                        xs: 8,
                        sm: 16,
                        md: 24,
                        lg: 32,
                      }}
                    >
                      <Col className="gutter-row" span={12}>
                        <Form.Item
                          label="Requirements"
                          name="TypeOfWork"
                          initialValue={leadData ? leadData?.TypeOfWork : ""}
                          rules={[
                            {
                              required: true,
                              message: "Enter details for type of work",
                            },
                            {
                              max: 500,
                              message: "Word limit exceeded!",
                            },
                          ]}
                          onBlur={(e) => FormatInput(e, form)}
                        >
                          <TextArea
                            rows={4}
                            placeholder="Enter type of Work: (Eg: Patient Care, Insulin Check, Sponge Bath...)"
                            maxLength={500}
                            disabled={
                              type === "edit"
                                ? editMode
                                  ? false
                                  : true
                                : false
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col className="gutter-row" span={12}>
                        <Form.Item
                          label="Languages"
                          name="Languages"
                          initialValue={leadData?.Languages}
                          rules={[
                            {
                              required: true,
                              message: "Enter Spoken Languages",
                            },
                            {
                              max: 500,
                              message: "Word limit exceeded!",
                            },
                          ]}
                          onBlur={(e) => FormatInput(e, form)}
                        >
                          <TextArea
                            rows={4}
                            placeholder="Enter Languages: (Eg: English, Konkani, Hindi...)"
                            maxLength={500}
                            disabled={
                              type === "edit"
                                ? editMode
                                  ? false
                                  : true
                                : false
                            }
                          />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row
                      gutter={{
                        xs: 8,
                        sm: 16,
                        md: 24,
                        lg: 32,
                      }}
                    >
                      <Col className="gutter-row" span={6}>
                        <Form.Item
                          label="Street Address"
                          name="Address-Street"
                          initialValue={leadData?.Address?.Street}
                          rules={[
                            {
                              required: true,
                              message: "Enter Street Address",
                            },
                            {
                              max: 50,
                              message: "Street Address limit exceeded!",
                            },
                          ]}
                          onBlur={(e) => FormatInput(e, form)}
                        >
                          <Input
                            placeholder="Street Address"
                            disabled={
                              type === "edit"
                                ? editMode
                                  ? false
                                  : true
                                : false
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col className="gutter-row" span={6}>
                        <Form.Item
                          label="City / Town / Village"
                          name="Address-City"
                          initialValue={leadData?.Address?.City}
                          rules={[
                            {
                              required: true,
                              message: "Enter City Address",
                            },
                            {
                              max: 50,
                              message: "City Address limit exceeded!",
                            },
                            {
                              pattern: AlphabetRegex,
                              message: "Enter valid City Address",
                            },
                          ]}
                          onBlur={(e) => FormatInput(e, form)}
                        >
                          <Input
                            placeholder="City"
                            disabled={
                              type === "edit"
                                ? editMode
                                  ? false
                                  : true
                                : false
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col className="gutter-row" span={6}>
                        <Form.Item
                          label="Taluka / Subdivison"
                          name="Address-Taluka"
                          initialValue={leadData?.Address?.Taluka}
                          rules={[
                            {
                              required: true,
                              message: "Enter Taluka/Subdivison Address",
                            },
                            {
                              max: 50,
                              message:
                                "Taluka/Subdivison Address limit exceeded!",
                            },
                            {
                              pattern: AlphabetRegex,
                              message: "Enter valid Taluka/Subdivison Address",
                            },
                          ]}
                          onBlur={(e) => FormatInput(e, form)}
                        >
                          <Input
                            placeholder="Taluka/Subdivison"
                            disabled={
                              type === "edit"
                                ? editMode
                                  ? false
                                  : true
                                : false
                            }
                          />
                        </Form.Item>
                      </Col>

                      <Col className="gutter-row" span={6}>
                        <Form.Item
                          label="District"
                          name="Address-District"
                          initialValue={leadData?.Address?.District}
                          rules={[
                            {
                              required: true,
                              message: "Enter District Address",
                            },
                            {
                              max: 50,
                              message: "District Address limit exceeded!",
                            },
                            {
                              pattern: AlphabetRegex,
                              message: "Enter valid District Address",
                            },
                          ]}
                          onBlur={(e) => FormatInput(e, form)}
                        >
                          <Input
                            placeholder="District"
                            disabled={
                              type === "edit"
                                ? editMode
                                  ? false
                                  : true
                                : false
                            }
                          />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row
                      gutter={{
                        xs: 8,
                        sm: 16,
                        md: 24,
                        lg: 32,
                      }}
                    >
                      <Col className="gutter-row" span={6}>
                        <Form.Item
                          label="State"
                          name="Address-State"
                          initialValue={leadData?.Address?.State}
                          rules={[
                            {
                              required: true,
                              message: "Enter State Address",
                            },
                            {
                              max: 50,
                              message: "State Address limit exceeded!",
                            },
                            {
                              pattern: AlphabetRegex,
                              message: "Enter valid State Address",
                            },
                          ]}
                          onBlur={(e) => FormatInput(e, form)}
                        >
                          <Input
                            placeholder="State"
                            disabled={
                              type === "edit"
                                ? editMode
                                  ? false
                                  : true
                                : false
                            }
                          />
                        </Form.Item>
                      </Col>

                      <Col className="gutter-row" span={6}>
                        <Form.Item
                          label="Pincode"
                          name="Address-PinCode"
                          initialValue={leadData?.Address?.PinCode}
                          rules={[
                            {
                              required: true,
                              message: "Enter Pincode",
                            },
                            {
                              pattern: PinCodeRegex,
                              message: "Enter valid PinCode",
                            },
                          ]}
                        >
                          <Input
                            placeholder="Pin Code"
                            disabled={
                              type === "edit"
                                ? editMode
                                  ? false
                                  : true
                                : false
                            }
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Form>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default LeadsFormPage;
