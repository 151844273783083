const ErrorValidation = (statusCode) => {
  let message = "Something went wrong! Please try again...";
  if (statusCode === 400) {
    message = "Invalid Input, Please try again...";
  }
  if (statusCode === 404) {
    message = "Resource unavailable, Please try again...";
  }
  if (statusCode === 500) {
    message = "Unable to connect to Server! Please try again...";
  }

  return message;
};

export default ErrorValidation;
