import React, { useEffect, useState } from "react";
import { useNavigate, Outlet } from "react-router-dom";
import { useDispatch } from "react-redux";
import { showLoading, hideLoading } from "../../redux/LoaderSlice";
import TopBar from "../../components/topBar/TopBar";
import SideBar from "../../components/sideBar/SideBar";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import isBetween from "dayjs/plugin/isBetween";
import dayOfYear from "dayjs/plugin/dayOfYear";
import "./Dashboard.scss";
import ApiService from "../../services/ApiService";
import { EncodeString } from "../../services/TableUtilities";
dayjs.extend(customParseFormat);
dayjs.extend(isBetween);
dayjs.extend(dayOfYear);
const dateFormat = "DD-MM-YYYY";
const dateFormat2 = "YYYY-MM-DD";
var Buffer = require("buffer/").Buffer;

function Dashboard() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [collapseSideBar, setCollapseSideBar] = useState(false);
  const [clickedElementClassName, setClickedElementClassName] = useState(null);
  const [logoClicked, setLogoClicked] = useState(false);

  const updateStaffOnLeaveCompletion = async (today) => {
    let apiURL = "/staff/";
    const apiResponse = await ApiService("get", apiURL);
    const apiData = apiResponse?.response;
    if (apiData) {
      for (let i = 0; i < apiData.length; i++) {
        let staffRecord = apiData[i];
        let staffStatus = staffRecord?.Status;

        //Check leaves of staff who are currently on leave and unassigned (have future leaves)
        if (staffStatus === "On Leave" || staffStatus === "Unassigned") {
          let staffID = staffRecord?._id;
          let staffStatus = staffRecord["Status"];
          let staffStatusChange = false;
          let leavesObject = staffRecord?.Leaves;
          let leaveExistToday = false;

          if (Object.keys(leavesObject).length > 0) {
            for (let [key, value] of Object.entries(leavesObject)) {
              if (key === today) {
                leaveExistToday = true;
                break;
              }
            }

            //Sets status of an Unassigned Staff to On Leave
            if (leaveExistToday && staffStatus === "Unassigned") {
              staffStatusChange = true;
              staffRecord["Status"] = "On Leave";
            }

            //Sets status of an  On Leave Staff to Unassigned if no current leave exist
            if (!leaveExistToday && staffStatus === "On Leave") {
              staffStatusChange = true;
              staffRecord["Status"] = "Unassigned";
            }

            if (staffStatusChange) {
              const apiResponse = await ApiService(
                "put",
                apiURL + staffID,
                staffRecord
              );
              const apiData = apiResponse?.response;
              if (apiData) {
                // console.log("daysPassedSinceLastLeaveDate", apiData);
              }
            }
          }
        }
      }
    }
  };

  //If Patient and Staff are still assigned after current billingCycle ends than generate a new billing cycle
  const updateBillingCycleOnCompletion = async () => {
    let apiURL = "/patients/";
    const apiResponse = await ApiService("get", apiURL);
    const apiData = apiResponse?.response;
    if (apiData) {
      for (let i = 0; i < apiData.length; i++) {
        let patientRecord = apiData[i];
        if (
          patientRecord?.Status === "Assigned" &&
          patientRecord?.CurrentStaffID !== ""
        ) {
          let newPatientAssignment = null;
          let newPatientPayment = null;
          let patientPaymentHistory = patientRecord?.Payments;

          //Update Existing Pending Payment
          if (patientPaymentHistory?.length > 0) {
            for (let l = 0; l < patientPaymentHistory.length; l++) {
              let patientPayment = patientPaymentHistory[l];
              if (patientPayment?.PaymentStatus === "Pending") {
                newPatientPayment = patientPayment;
                break;
              }
            }
          } else {
            //Create New Pending Payment incase of missing pending payment. Fail safe
            newPatientPayment = {};
            newPatientPayment["Amount"] = patientRecord?.InitialAmount;
            newPatientPayment["PaymentStatus"] = "Pending";
            newPatientPayment["DueDate"] = dayjs();
            newPatientPayment["PaidOn"] = dayjs();
            newPatientPayment["BillingCycle"] =
              patientRecord?.InitialBillingCycle.toString();
            newPatientPayment["BillingCycleDuration"] = "";
          }

          let patientBillingCycleHistory = patientRecord?.BillingCycleHistory;
          //Check for latest completed billing cycle in progress n update
          for (let j = 0; j < patientBillingCycleHistory.length; j++) {
            let patientBillingCycle = patientBillingCycleHistory[j];
            if (patientBillingCycle?.Status === "In Progress") {
              let patientBillingCycleStartDate = dayjs(
                patientBillingCycle?.StartDate
              );
              let patientBillingCycleEndDate = dayjs(
                patientBillingCycle?.EndDate
              );
              let currentDate = dayjs();
              let hasBillingCycleEndDatePassed = currentDate.isAfter(
                patientBillingCycleEndDate
              );
              if (hasBillingCycleEndDatePassed) {
                let patientAssignmentHistory =
                  patientBillingCycle?.AssignmentHistory;

                //Check for latest completed assignment in progress n update
                for (let k = 0; k < patientAssignmentHistory.length; k++) {
                  let patientAssignment = patientAssignmentHistory[k];
                  if (patientAssignment?.Status === "In Progress") {
                    newPatientAssignment = { ...patientAssignment };
                    let patientAssignmentStartDate = dayjs(
                      patientAssignment?.StartDate
                    );
                    let patientAssignmentEndDate = dayjs(
                      patientAssignment?.EndDate
                    );
                    let hasAssignmentEndDatePassed = currentDate.isAfter(
                      patientAssignmentEndDate
                    );
                    if (hasAssignmentEndDatePassed) {
                      patientAssignment["Status"] = "Completed";
                      patientBillingCycle["Status"] = "Completed";
                      let totalWorked =
                        patientAssignmentEndDate.diff(
                          patientAssignmentStartDate,
                          "day"
                        ) + 1;
                      patientAssignment["TotalWorked"] = totalWorked;
                    }
                  }
                }

                newPatientPayment["PaidOn"] = patientBillingCycleEndDate.add(
                  1,
                  "day"
                );
                newPatientPayment["DueDate"] = patientBillingCycleEndDate.add(
                  5,
                  "day"
                );
                newPatientPayment["BillingCycleDuration"] =
                  patientBillingCycleStartDate.format(dateFormat) +
                  " - " +
                  patientBillingCycleEndDate.format(dateFormat);
              }
            }
          }

          //If a new assignment is available then update patient's billingCycleHistory
          if (newPatientAssignment) {
            let newPatientBillingCycleStartDate = dayjs(
              newPatientAssignment?.EndDate
            ).add(1, "day");
            let newPatientBillingCycleEndDate =
              newPatientBillingCycleStartDate.add(
                patientRecord?.InitialBillingCycle - 1,
                "day"
              );
            newPatientAssignment["StartDate"] = newPatientBillingCycleStartDate;
            newPatientAssignment["EndDate"] = newPatientBillingCycleEndDate;
            newPatientAssignment["TotalWorked"] = 0;
            let newPatientBillingCycle = {
              AssignmentHistory: [newPatientAssignment],
              StartDate: newPatientBillingCycleStartDate,
              EndDate: newPatientBillingCycleEndDate,
              Status: "In Progress",
            };
            patientBillingCycleHistory.push(newPatientBillingCycle);

            let newPaymentHasPaymentId = "PaymentID" in newPatientPayment;

            if (newPaymentHasPaymentId) {
              let responseObj = {
                BillingCycleHistory: patientBillingCycleHistory,
                Payments: patientPaymentHistory,
              };
              await ApiService("put", apiURL + patientRecord?._id, responseObj);
            } else {
              let responseObj = {
                BillingCycleHistory: patientBillingCycleHistory,
              };
              await ApiService("put", apiURL + patientRecord?._id, responseObj);
              apiURL = "/patients/" + patientRecord?._id + "/payments";
              await ApiService("post", apiURL, newPatientPayment);
            }
          }
        }
      }
    }
  };

  const updateBillingCycle = async () => {
    //If first day of new month and patient active
    //create a new billing cycle with same assigned staff and initial billingCycle Value
    //If billing cycle status is completed, create new billing cycle for remaining days of the month
    //create a new billing cycle with same assigned staff and initial billingCycle Value
  };

  const calculateSalaries = async (dateToday) => {
    let today = dateToday.split("-")[0];
    let currentMonth = dayjs(dateToday, dateFormat).month() + 1;
    let currentYear = dayjs(dateToday, dateFormat).year();
    let previousMonth = currentMonth - 1;
    let previousYear = currentYear;

    //To handle year overlap
    if (previousMonth === 12) {
      previousYear -= 1;
    }

    let minSalaryDate = "05-0" + previousMonth + "-" + previousYear;
    let maxSalaryDate = "04-0" + currentMonth + "-" + currentYear;
    let salaryDuration = minSalaryDate + " - " + maxSalaryDate;

    minSalaryDate = dayjs(minSalaryDate, dateFormat);
    maxSalaryDate = dayjs(maxSalaryDate, dateFormat);
    let totalSalaryDays = maxSalaryDate.diff(minSalaryDate, "day") + 1;

    // console.log(
    //   `calculateSalaries`,
    //   today,
    //   dateToday,
    //   previousMonth,
    //   currentMonth,
    //   previousYear,
    //   currentYear,
    //   minSalaryDate,
    //   maxSalaryDate,
    //   totalSalaryDays,
    //   salaryDuration
    // );

    //If fourth day of current month? Get all Assignments for each staff n calculate salaries
    if (today === "04") {
      let apiURL = "/staff/";
      let apiResponse = await ApiService("get", apiURL);
      let apiStaffData = apiResponse?.response;

      apiURL = "/patients/";
      apiResponse = await ApiService("get", apiURL);
      let apiPatientData = apiResponse?.response;

      //Check if a staff has worked for a patient
      if (apiStaffData) {
        for (let i = 0; i < apiStaffData.length; i++) {
          let staffRecord = apiStaffData[i];
          let staffID = staffRecord?._id;
          let staffPayments = staffRecord?.Payment;
          let totalSalaryDays = 0;
          let salaryResponseObject = {};

          //Check if current staff was assigned to each patient
          if (apiPatientData) {
            for (let j = 0; j < apiPatientData.length; j++) {
              let patientRecord = apiPatientData[j];
              let patientBillingCycleHistory =
                patientRecord?.BillingCycleHistory;

              for (let k = 0; k < patientBillingCycleHistory.length; k++) {
                let patientBillingCycle = patientBillingCycleHistory[k];
                let patientBillingCycleStartDate =
                  patientBillingCycle?.StartDate;
                patientBillingCycleStartDate = dayjs(
                  patientBillingCycleStartDate,
                  dateFormat2
                );
                let patientBillingCycleEndDate = patientBillingCycle?.EndDate;
                patientBillingCycleEndDate = dayjs(
                  patientBillingCycleEndDate,
                  dateFormat2
                );

                // patientBillingCycleStartDate.isAfter(minSalaryDate) ||
                // patientBillingCycleEndDate.isBefore(maxSalaryDate)

                if (
                  patientBillingCycleStartDate.isBetween(
                    minSalaryDate,
                    maxSalaryDate,
                    "day",
                    "[]"
                  ) ||
                  patientBillingCycleEndDate.isBetween(
                    minSalaryDate,
                    maxSalaryDate,
                    "day",
                    "[]"
                  )
                ) {
                  let patientAssignmentHistory =
                    patientBillingCycle?.AssignmentHistory;
                  for (let l = 0; l < patientAssignmentHistory.length; l++) {
                    let patientAssignment = patientAssignmentHistory[l];
                    let patientAssignmentStaffID = patientAssignment?.StaffID;
                    let patientAssignmentStartDate = dayjs(
                      patientAssignment?.StartDate,
                      dateFormat2
                    );

                    let patientAssignmentEndDate = dayjs(
                      patientAssignment?.EndDate,
                      dateFormat2
                    );

                    //Assignment staff matches the current staff id
                    if (patientAssignmentStaffID === staffID) {
                      let salaryDays =
                        patientAssignmentEndDate.diff(
                          patientAssignmentStartDate,
                          "day"
                        ) + 1;

                      //If Assignment started before current salary window, remove prev salary window's days
                      if (patientAssignmentStartDate.isBefore(minSalaryDate)) {
                        let preDiff =
                          minSalaryDate.diff(
                            patientAssignmentStartDate,
                            "day"
                          ) - 1;
                        salaryDays = salaryDays - preDiff;
                        totalSalaryDays += salaryDays;
                      }

                      //If Assignment ended after current salary window, remove future salary window's days
                      if (patientAssignmentEndDate.isAfter(maxSalaryDate)) {
                        let postDiff =
                          patientAssignmentEndDate.diff(maxSalaryDate, "day") +
                          1;
                        salaryDays = salaryDays - postDiff;
                        totalSalaryDays += salaryDays;
                      }

                      //If Assignment is within current salary window
                      if (
                        patientAssignmentStartDate.isBetween(
                          minSalaryDate,
                          maxSalaryDate,
                          "day",
                          "[]"
                        ) &&
                        patientAssignmentEndDate.isBetween(
                          minSalaryDate,
                          maxSalaryDate,
                          "day",
                          "[]"
                        )
                      ) {
                        totalSalaryDays += salaryDays;
                      }
                    }
                  }
                }
              }
            }
          }

          if (totalSalaryDays > 0 && totalSalaryDays <= 31) {
            //Check for duplicate salaries, maintain unique slips
            let salarySlipExists = false;

            for (let j = 0; j < staffPayments.length; j++) {
              let staffPayment = staffPayments[j];
              if (
                dayjs(staffPayment?.PaidOn).format(dateFormat).toString() ===
                maxSalaryDate.format(dateFormat).toString()
              ) {
                salarySlipExists = true;
                break;
              }
            }

            //Generate salary slip
            if (!salarySlipExists) {
              salaryResponseObject["Amount"] = staffRecord?.Salary;
              salaryResponseObject["PaymentStatus"] = "Pending";
              salaryResponseObject["PaidOn"] = maxSalaryDate.add(1, "day");
              salaryResponseObject["TotalWorked"] = parseInt(totalSalaryDays);
              staffPayments.push(salaryResponseObject);

              const apiResponse = await ApiService(
                "put",
                "/staff/" + staffID,
                staffRecord
              );
            }
          }
        }
      }
    }
  };

  const activatePendingBillingCycleByLeaves = async () => {
    //Foreach patient, check Assignment History. If Future Leaves Start date in effect, set Status to complete.
    //Set enddate to datenow - 1, Set new Staff Pending Assignment Status to 'In Progress'. Create new pending
    //Assignment with startdate as leaveEndate + 1 till billingCycle enddate for swapped staff joining back;
  };

  const reassignSwappedStaff = async () => {
    //Foreach patient, check Assignment History. If Leaves completed, mark swapped staff as unassigned
    //Reassign old staff. If current date > last date of Leaves, Take effect
  };

  const createPaymentsForNewYear = async () => {
    //If first day of new year, generate new pending payment objects
  };

  const removeInvalidAssignmentHistoryObjects = async () => {
    //If status is completed and totalworkingdays = 0
  };

  useEffect(() => {
    let responseObj = localStorage.getItem("token");
    if (responseObj) {
      responseObj = JSON.parse(Buffer.from(responseObj, "base64").toString());
      let lastActiveDate = responseObj?.dateToday;
      let runAutoFunctions = responseObj?.runAutoFunctions;
      let today = dayjs().format(dateFormat);

      //Handles logged in or new login scenario
      if (today !== lastActiveDate || runAutoFunctions) {
        updateBillingCycleOnCompletion();
        updateStaffOnLeaveCompletion(today);
        calculateSalaries(today);
        //Sets auto functions to false or last active to current date once processing is complete
        if (runAutoFunctions) {
          responseObj["runAutoFunctions"] = false;
        }
        if (today !== lastActiveDate) {
          responseObj["dateToday"] = today;
        }
        let localStorageObject = EncodeString(JSON.stringify(responseObj));
        localStorage.setItem("token", localStorageObject);
      }
    }

    if (!responseObj?.employeeLoginStatus) {
      navigate("/login");
    }
    dispatch(showLoading());
    setTimeout(() => {
      dispatch(hideLoading());
    }, 1000);
  }, []);

  return (
    <div
      className="dashboard"
      onClick={(e) => {
        setClickedElementClassName(e.target.className.toString());
      }}
    >
      <TopBar
        clickElementClassName={clickedElementClassName}
        setLogoClicked={setLogoClicked}
      />
      <div className="appBody">
        <SideBar
          collapseSideBar={collapseSideBar}
          setCollapseSideBar={setCollapseSideBar}
          logoClicked={logoClicked}
        />
        <div
          id="contentBody"
          className={
            collapseSideBar ? "contentBody expandContentBody" : "contentBody"
          }
        >
          <div className="outletBorder">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
