import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import SpinnerComponent from "./components/spinnerComponent/SpinnerComponent";
import toast, { Toaster } from "react-hot-toast";
import Login from "./pages/Login/Login";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import InvalidPage from "./pages/InvalidPage/InvalidPage";
import Dashboard from "./pages/Dashboard/Dashboard";
import Home from "./pages/Home/Home";
import Employees from "./pages/Employees/Employees";
import EmployeeAccount from "./pages/Employees/EmployeeAccount/EmployeeAccount";
import EmployeesTablePage from "./pages/Employees/EmployeesTablePage/EmployeesTablePage";
import EmployeeFormPage from "./pages/Employees/EmployeeForm/EmployeeFormPage";
import Staff from "./pages/Staff/Staff";
import StaffTablePage from "./pages/Staff/StaffTablePage/StaffTablePage";
import StaffFormPage from "./pages/Staff/StaffForm/StaffFormPage";
import StaffAttendanceNLeavesForm from "./pages/Staff/StaffAttendanceNLeavesForm/StaffAttendanceNLeavesForm";
import Patients from "./pages/Patients/Patients";
import PatientsTablePage from "./pages/Patients/PatientsTablePage/PatientsTablePage";
import PatientFormPage from "./pages/Patients/PatientForm/PatientFormPage";
import Payments from "./pages/Payments/Payments";
import PaymentsTablePage from "./pages/Payments/PaymentsTablePage/PaymentsTablePage";
import Leads from "./pages/Leads/Leads";
import LeadsTablePage from "./pages/Leads/LeadsTablePage/LeadsTablePage";
import LeadsFormPage from "./pages/Leads/LeadsForm/LeadsFormPage";
import SalariesTablePage from "./pages/Salaries/SalariesTablePage/SalariesTablePage";
import SalariesFormPage from "./pages/Salaries/SalariesForm/SalariesFormPage";
import PaymentsFormPage from "./pages/Payments/PaymentsForm/PaymentsFormPage";
import Salaries from "./pages/Salaries/Salaries";
import Logout from "./pages/Logout/Logout";
import "./App.scss";

const FRONTEND_URL = process.env.REACT_APP_FRONTEND_URL;
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
const IMAGE_SERVER_URL = process.env.REACT_APP_IMAGE_SERVER_URL;

function App() {
  const { loading } = useSelector((state) => state.loader);
  let onlineAccess = window?.navigator?.onLine;

  useEffect(() => {
    if (!onlineAccess) {
      toast.error("Cannot connect to Server! No Internet Access!");
    } else {
      // toast.success("Connected to Server Successfully!");
    }
  }, [onlineAccess]);

  return (
    <BrowserRouter>
      {loading && <SpinnerComponent />}
      <Toaster position="top-center" reverseOrder={false} />
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/account" element={<EmployeeAccount type={"add"} />} />
        <Route
          path="/resetpassword/:passwordResetLink"
          element={<ResetPassword />}
        />
        <Route path="/" element={<Dashboard />}>
          <Route index path="" element={<Home />} />
          <Route path="employees" element={<Employees />}>
            <Route path="account" element={<EmployeeAccount type={"edit"} />} />
            <Route
              path="resetpassword/:passwordResetLink"
              element={<ResetPassword type={"edit"} />}
            />
            <Route
              path=""
              element={<EmployeesTablePage entity={"Employee"} />}
            />
            <Route
              path="employee/:employeeID"
              element={<EmployeeFormPage type={"edit"} />}
            />
          </Route>
          <Route path="staff" element={<Staff />}>
            <Route path="" element={<StaffTablePage entity={"Staff"} />} />
            <Route
              path="add_staff/:staffID"
              element={<StaffFormPage type={"add"} />}
            />
            <Route
              path="edit_staff/:staffID"
              element={<StaffFormPage type={"edit"} />}
            />
            <Route
              path="edit_staffAttendance/:attendanceIndex"
              element={<StaffAttendanceNLeavesForm type={"edit"} />}
            />
          </Route>
          <Route path="patients/:filterPage" element={<Patients />}>
            <Route path="" element={<PatientsTablePage entity={"Patient"} />} />
            <Route
              path="add_patient/:patientID"
              element={<PatientFormPage type={"add"} />}
            />
            <Route
              path="edit_patient/:patientID"
              element={<PatientFormPage type={"edit"} />}
            />
          </Route>

          <Route path="payments/:filterPage" element={<Payments />}>
            <Route path="" element={<PaymentsTablePage entity={"Payment"} />} />
            <Route
              path="add_payment/:paymentID"
              element={<PaymentsFormPage type="add" />}
            />
            <Route
              path="edit_payment/:paymentID"
              element={<PaymentsFormPage type="edit" />}
            />
          </Route>

          <Route path="salaries" element={<Salaries />}>
            <Route path="" element={<SalariesTablePage entity={"Salarie"} />} />
            <Route
              path="add_salary/:salaryID"
              element={<SalariesFormPage type="add" />}
            />
            <Route
              path="edit_salary/:salaryID"
              element={<SalariesFormPage type="edit" />}
            />
          </Route>

          <Route path="leads" element={<Leads />}>
            <Route path="" element={<LeadsTablePage entity={"Lead"} />} />
            <Route
              path="add_lead/:leadID"
              element={<LeadsFormPage type="add" />}
            />
            <Route
              path="edit_lead/:leadID"
              element={<LeadsFormPage type="edit" />}
            />
          </Route>
          <Route path="logout" element={<Logout />} />
        </Route>
        <Route path="*" exact={true} element={<InvalidPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export { BACKEND_URL, FRONTEND_URL, IMAGE_SERVER_URL };
export default App;
