import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { hideLoading, showLoading } from "../../../redux/LoaderSlice";
import ApiService from "../../../services/ApiService";
import ErrorValidation from "../../../services/ErrorValidation";
import { DataProcessor } from "../../../services/DataProcessor";
import { DataFilter, TableSearch } from "../../../services/DataFilter";
import { EncodeString } from "../../../services/TableUtilities";
import { Table, Input, Button, Card, Space } from "antd";
import { UnorderedListOutlined, DeleteOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import ModalComponent from "../../../components/modalComponent/ModalComponent";
import ModalForm from "../../../components/modalForm/ModalForm";
import toast from "react-hot-toast";
import "./StaffAttendanceNLeavesTable.scss";

const { Search } = Input;
const dateFormat = "DD-MM-YYYY";

const StaffAttendanceNLeavesTable = ({ staffID, type }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [dataIsReady, setDataIsReady] = useState(false);
  const [entityTableFilters, setEntityTableFilters] = useState([]);
  const [filterIsReady, setFilterIsReady] = useState(false);
  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const [tableData, setTableData] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [currentRow, setCurrentRow] = useState(null);
  const [displayTableActionComponent, setDisplayTableActionComponent] =
    useState(false);
  const [clickedElementClassName, setClickedElementClassName] = useState(null);

  let unprocessedData = [];
  let processedData = [];

  let encodedEntityID = null;

  const getData = async () => {
    dispatch(showLoading());
    const apiURL = `/patients/`;
    const apiResponse = await ApiService("get", apiURL);
    const apiData = apiResponse?.response;
    if (apiData) {
      unprocessedData = apiData;
      processedData = await DataProcessor(unprocessedData);
      let processedAttendanceData = await parseAttendanceData(processedData);
      let processedLeavesData = await parseLeavesData();
      if (type === "attendance") {
        setTableData(processedAttendanceData);
      } else {
        setTableData(processedLeavesData);
      }
      // setEntityTableFilters(getTableFilters());
      // setFilterIsReady(true);
      setDataIsReady(true);
      dispatch(hideLoading());
    } else {
      toast.error(`No Staff Data Found!`);
    }
  };

  const parseAttendanceData = async (attendanceData) => {
    let processedAttendanceData = [];
    for (let i = 0; i < attendanceData.length; i++) {
      let patientData = attendanceData[i];
      let patientID = patientData?._id;
      let patientName = patientData?.Name;
      let billingCycleHistory = patientData?.BillingCycleHistory;
      for (let j = 0; j < billingCycleHistory.length; j++) {
        let billingCycleData = billingCycleHistory[j];
        let billingCycleDataIndex = j;
        let billingCycleStatus = billingCycleData?.Status;
        let billingCycleStartDate = dayjs(billingCycleData?.StartDate).format(
          dateFormat
        );
        let billingCycleEndDate = dayjs(billingCycleData?.EndDate).format(
          dateFormat
        );
        let billingCycleTitle =
          billingCycleStartDate + " - " + billingCycleEndDate;
        let billingCycleAssignmentHistory = billingCycleData?.AssignmentHistory;
        for (let k = 0; k < billingCycleAssignmentHistory.length; k++) {
          let assignmentHistoryData = billingCycleAssignmentHistory[k];
          if (assignmentHistoryData?.StaffID === staffID) {
            let assignmentHistoryDataIndex = k;
            let assignmentHistoryStatus = assignmentHistoryData?.Status;
            let assignmentHistoryStartDate = dayjs(
              assignmentHistoryData?.StartDate
            ).format(dateFormat);
            let assignmentHistoryEndDate = dayjs(
              assignmentHistoryData?.EndDate
            ).format(dateFormat);
            let assignmentHistoryTitle =
              assignmentHistoryStartDate + " - " + assignmentHistoryEndDate;
            let assignmentHistoryLeaves = assignmentHistoryData?.Leaves;
            let assignmentHistoryTotalWorked = 0;

            if (assignmentHistoryStatus === "Completed") {
              assignmentHistoryTotalWorked =
                dayjs(assignmentHistoryEndDate, dateFormat).diff(
                  dayjs(assignmentHistoryStartDate, dateFormat),
                  "day"
                ) + 1;
            }

            if (assignmentHistoryStatus === "In Progress") {
              assignmentHistoryTotalWorked =
                dayjs().diff(
                  dayjs(assignmentHistoryStartDate, dateFormat),
                  "day"
                ) + 1;
            }

            // let assignmentHistoryTotalLeaves = Object.keys(
            //   assignmentHistoryLeaves
            // ).length;
            // let assignmentHistoryTotalWorkingDays = dayjs(
            //   assignmentHistoryData?.StartDate
            // ).diff(dayjs(assignmentHistoryData?.EndDate), "day");
            let processedAttendanceDataObject = {
              key: processedAttendanceData.length,
              PatientID: patientID,
              PatientName: patientName,
              BillingCycleHistory: billingCycleHistory,
              BillingCycleTitle: billingCycleTitle,
              BillingCycleStatus: billingCycleStatus,
              BillingCycleHistoryIndex: billingCycleDataIndex,
              AssignmentHistoryTitle: assignmentHistoryTitle,
              AssignmentHistoryStartDate: assignmentHistoryStartDate,
              AssignmentHistoryEndDate: assignmentHistoryEndDate,
              AssignmentHistoryStatus: assignmentHistoryStatus,
              AssignmentHistoryTotalWorkedDays: assignmentHistoryTotalWorked,
              // AssignmentHistoryTotalLeaves: assignmentHistoryTotalLeaves,
              AssignmentHistoryLeaves: assignmentHistoryLeaves,
              AssignmentHistoryIndex: assignmentHistoryDataIndex,
            };
            processedAttendanceData.push(processedAttendanceDataObject);
          }
        }
      }
    }
    return processedAttendanceData;
  };

  const parseLeavesData = async () => {
    let processedLeavesData = [];
    let staffLeavesObject = await getStaffData(staffID);
    staffLeavesObject = staffLeavesObject?.Leaves;
    if (staffLeavesObject) {
      for (let [key, value] of Object.entries(staffLeavesObject)) {
        let processedLeave = {};
        processedLeave["key"] = processedLeavesData.length;
        processedLeave["leaveStartDate"] = key;
        processedLeave["leaveReason"] = value;
        processedLeavesData.push(processedLeave);
      }
    }

    // let leavesDuration = Object.keys(leavesObject)?.length;
    // if (leavesDuration > 0) {
    //   // leaveObject["key"] = processedLeavesData.length;
    //   leaveObject["leaveDuration"] = leavesDuration;
    //   leaveObject["leaveStartDate"] = Object.keys(leavesObject)[0];
    //   leaveObject["leaveEndDate"] =
    //     Object.keys(leavesObject)[Object.keys(leavesObject).length - 1];
    //   leaveObject["leaveReason"] = leavesObject[Object.keys(leavesObject)[0]];
    // }
    return processedLeavesData;
  };

  let attendanceColumns = [
    // {
    //   title: "Sr. No.",
    //   dataIndex: "key",
    //   key: "key",
    //   render: (value, record) => (value += 1),
    // },
    {
      title: "Patient",
      dataIndex: "PatientName",
      key: "PatientName",
      filters: filterIsReady ? entityTableFilters["PatientName"] : null,
      onFilter: (value, record) => onTableFilter(value, record),
      filteredValue: filteredInfo.PatientName || null,
      sorter: (a, b) => a.PatientName.length - b.PatientName.length,
      sortDirections: ["descend"],
      render: (value, record) => (
        (encodedEntityID = EncodeString(record?.PatientID)),
        (
          <Link
            className="tableRowLink"
            to={`../../patients/=/edit_patient/${encodedEntityID}`}
          >
            {value}
          </Link>
        )
      ),
    },
    {
      title: "Billing Cycle",
      dataIndex: "BillingCycleTitle",
      key: "BillingCycleTitle",
      filters: filterIsReady ? entityTableFilters["BillingCycleTitle"] : null,
      onFilter: (value, record) => onTableFilter(value, record),
      filteredValue: filteredInfo.BillingCycleTitle || null,
      render: (value, record) => (
        (encodedEntityID = EncodeString(
          record?.BillingCycleHistoryIndex.toString()
        )),
        (<Link className="tableRowLink">{value}</Link>)
      ),
    },
    {
      title: "Billing Cycle Status",
      dataIndex: "BillingCycleStatus",
      key: "BillingCycleStatus",
      filters: filterIsReady ? entityTableFilters["BillingCycleStatus"] : null,
      onFilter: (value, record) => onTableFilter(value, record),
      filteredValue: filteredInfo.BillingCycleStatus || null,
      sorter: (a, b) =>
        a.BillingCycleStatus.length - b.BillingCycleStatus.length,
    },
    {
      title: "Assignment Dates",
      dataIndex: "AssignmentHistoryTitle",
      key: "AssignmentHistoryTitle",
      sortDirections: ["descend"],
    },
    {
      title: "Assignment Status",
      dataIndex: "AssignmentHistoryStatus",
      key: "AssignmentHistoryStatus",
      filters: filterIsReady
        ? entityTableFilters["AssignmentHistoryStatus"]
        : null,
      onFilter: (value, record) => onTableFilter(value, record),
      filteredValue: filteredInfo.AssignmentHistoryStatus || null,
      sorter: (a, b) =>
        a.AssignmentHistoryStatus.length - b.AssignmentHistoryStatus.length,
    },
    {
      title: "Total Working Days",
      dataIndex: "AssignmentHistoryTotalWorkedDays",
      key: "AssignmentHistoryTotalWorkedDays",
    },
    // {
    //   title: "Action",
    //   dataIndex: "tableActionButtons",
    //   key: "operation",
    //   render: (value, record) => (
    //     <div
    //       className="tableMenuDiv"
    //       onClick={(e) => {
    //         setDisplayTableActionComponent(!displayTableActionComponent);
    //         setTableActionComponentPostions(e.target.getBoundingClientRect());
    //         setCurrentRow(record);
    //       }}
    //     >
    //       <MoreOutlined className="tableMenuIcon" />
    //     </div>
    //   ),
    //   width: 80,
    // },
  ];

  let leaveColumns = [
    // {
    //   title: "Number of Days",
    //   dataIndex: "leaveDuration",
    //   key: "leaveDuration",
    //   sorter: (a, b) => a.leaveDuration.length - b.leaveDuration.length,
    // },
    // {
    //   title: "Sr. No.",
    //   dataIndex: "key",
    //   key: "key",
    //   render: (value, record) => (value += 1),
    // },
    {
      title: "Date",
      dataIndex: "leaveStartDate",
      key: "leaveStartDate",
    },
    // {
    //   title: "End Date",
    //   dataIndex: "leaveEndDate",
    //   key: "leaveEndDate",
    //   sorter: (a, b) => a.leaveEndDate.length - b.leaveEndDate.length,
    //   sortDirections: ["descend"],
    // },
    {
      title: "Reason for Leave",
      dataIndex: "leaveReason",
      key: "leaveReason",
    },
    {
      title: "Action",
      dataIndex: "tableActionButtons",
      key: "operation",
      render: (value, record) => (
        <div
          className="tableRecordDeleteIcon"
          onClick={(e) => {
            setCurrentRow(record);
            setOpenDeleteModal(true);
          }}
        >
          <DeleteOutlined />
        </div>
      ),
    },
  ];

  const onChange = (pagination, filters, sorter, extra) => {
    setFilteredInfo(filters);
    setSortedInfo(sorter);
  };

  const onTableSearch = (value) => {
    let searchResult = TableSearch(value, tableData);
    if (searchResult?.length > 0) {
      setSearchData(searchResult);
    } else {
      toast.error("No matching records found!");
    }
  };

  const onTableFilter = (value, record) => {
    if (record?.Name?.indexOf(value) === 0) {
      return true;
    }

    if (record?.Address?.includes(value) === true) {
      return true;
    }

    if (record?.Role?.indexOf(value) === 0) {
      return true;
    }
  };

  if (displayTableActionComponent && clickedElementClassName) {
    if (
      !clickedElementClassName.includes("SVGAnimatedString") &&
      !clickedElementClassName.includes("tableMenuDiv")
    ) {
      setDisplayTableActionComponent(false);
    }
  }

  const confirmCancel = () => {
    setOpenModal(false);
  };

  const deleteLeaveRecord = async () => {
    let deletedLeaveDate = currentRow?.leaveStartDate;
    let today = dayjs().format(dateFormat);
    let staffObject = await getStaffData(staffID);
    let staffStatus = staffObject?.Status;
    let staffLeavesObject = staffObject?.Leaves;
    let leaveExists = false;

    if (staffLeavesObject) {
      for (let [key, value] of Object.entries(staffLeavesObject)) {
        if (key === deletedLeaveDate) {
          leaveExists = true;
        }
      }
    }

    if (leaveExists) {
      dispatch(showLoading());
      if (deletedLeaveDate === today && staffStatus === "On Leave") {
        staffObject["Status"] = "Unassigned";
      }
      delete staffLeavesObject[deletedLeaveDate];
      const apiURL = "/staff/" + staffID;
      const apiResponse = await ApiService("put", apiURL, staffObject);
      const apiData = apiResponse?.response;
      if (apiData) {
        toast.success("Leave was deleted successfully!");
        getData();
      } else {
        const apiError = apiResponse?.error;
        let errorMessage = ErrorValidation(apiError?.response?.status);
        toast.error(errorMessage);
      }
      dispatch(hideLoading());
    } else {
      toast.error("Something went wrong... Please try again!");
    }
  };

  const getStaffData = async () => {
    let apiURL = "/staff/" + staffID;
    let staffData = null;
    const apiResponse = await ApiService("get", apiURL);
    const apiData = apiResponse?.response;
    if (apiData) {
      staffData = apiData;
    } else {
      const apiError = apiResponse?.error;
    }
    return staffData;
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      {dataIsReady && (
        <div
          className="tablePage"
          onClick={(e) => {
            setClickedElementClassName(e.target.className.toString());
          }}
        >
          <Space
            direction="vertical"
            size="middle"
            style={{
              display: "flex",
            }}
          >
            <Card className="tableCard">
              <div className="tableHeader">
                <div className="searchBar">
                  <Search
                    placeholder="Search here..."
                    allowClear
                    onSearch={(value) => {
                      onTableSearch(value);
                    }}
                    onChange={(e) => {
                      onTableSearch(e.target.value);
                    }}
                    style={{
                      width: 300,
                    }}
                  />
                </div>

                <div className="recordsCount">
                  <UnorderedListOutlined />
                  <p>
                    Records{": "}
                    {searchData.length > 0
                      ? searchData.length
                      : tableData.length}
                  </p>
                </div>
              </div>
              <hr />

              <Table
                className="table"
                columns={
                  type === "attendance" ? attendanceColumns : leaveColumns
                }
                dataSource={searchData.length > 0 ? searchData : tableData}
                onChange={onChange}
                pagination={{ pageSize: 5 }}
              />
            </Card>
          </Space>

          <ModalComponent
            openModal={openDeleteModal}
            setOpenModal={setOpenDeleteModal}
            width={"40%"}
            title={`Delete Leave Record`}
            message={
              `Are you sure you want to delete this Leave for ` +
              currentRow?.leaveStartDate +
              ` ?`
            }
            action={deleteLeaveRecord}
            exitAction={confirmCancel}
          />
        </div>
      )}
    </>
  );
};

export default StaffAttendanceNLeavesTable;
