import React from "react";
import { Link } from "react-router-dom";
import "./DashboardCard.scss";

function DashboardCard({ dashboardItem }) {
  return (
    <div className="dashboardItemCard">
      <span className="dashboardItemCardIcon">{dashboardItem.icon}</span>
      <p className="dashboardItemCardTitle">{dashboardItem.title}</p>
      <p className="dashboardItemCardDescription">
        {dashboardItem.description}
      </p>
      <Link className="dashboardItemLink" to={dashboardItem.link}>
        {dashboardItem.linkButtonText}
      </Link>
    </div>
  );
}

export default DashboardCard;
