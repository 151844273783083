import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { Tooltip } from "antd";
import {
  HomeOutlined,
  TeamOutlined,
  MedicineBoxOutlined,
  ShopOutlined,
  DollarOutlined,
  BankOutlined,
  UserSwitchOutlined,
  LeftCircleOutlined,
  RightCircleOutlined,
} from "@ant-design/icons";
import "./SideBar.scss";

function SideBar({ collapseSideBar, setCollapseSideBar, logoClicked }) {
  const [activeSideBarItem, setActiveSideBarItem] = useState("Home");
  const [arrow, setArrow] = useState("Show");

  const mergedArrow = useMemo(() => {
    if (arrow === "Hide") {
      return false;
    }

    if (arrow === "Show") {
      return true;
    }

    return {
      pointAtCenter: true,
    };
  }, [arrow]);

  const sideBarItems = [
    {
      icon: <HomeOutlined className="sideBarItemIcon" />,
      title: "Home",
      link: "/",
    },
    {
      icon: <TeamOutlined className="sideBarItemIcon" />,
      title: "Employees",
      link: "/employees",
    },
    {
      icon: <MedicineBoxOutlined className="sideBarItemIcon" />,
      title: "Staff",
      link: "/staff",
    },
    {
      icon: <ShopOutlined className="sideBarItemIcon" />,
      title: "Patients",
      link: "/patients/=",
    },
    {
      icon: <DollarOutlined className="sideBarItemIcon" />,
      title: "Payments",
      link: "/payments/=",
    },
    {
      icon: <BankOutlined className="sideBarItemIcon" />,
      title: "Salaries",
      link: "/salaries",
    },
    {
      icon: <UserSwitchOutlined className="sideBarItemIcon" />,
      title: "Leads",
      link: "/leads",
    },
  ];

  const storeActiveSideBarItem = (sideBarItemTitle) => {
    setActiveSideBarItem(sideBarItemTitle);
    localStorage.setItem("lsActSdbarItm", sideBarItemTitle);
  };

  const checkActiveSideBarItem = () => {
    let lastActiveSideBarItem = localStorage.getItem("lsActSdbarItm");
    if (lastActiveSideBarItem) {
      setActiveSideBarItem(lastActiveSideBarItem);
    }
  };

  useEffect(() => {
    checkActiveSideBarItem();
    let collapseSideBarStatus = JSON.parse(
      window.localStorage.getItem("SdbarClpSt")
    );

    if (collapseSideBarStatus) {
      setCollapseSideBar(true);
    } else {
      setCollapseSideBar(false);
    }
  }, []);

  return (
    <div className={collapseSideBar ? "sideBar collapseSideBar" : "sideBar"}>
      <div className="sideBarItems">
        {sideBarItems.map((sideBarItem, i) => {
          return (
            <Link key={i} className="sideBarItemLink" to={sideBarItem.link}>
              <div className="sideBarItemContainer">
                <div
                  key={i}
                  className={
                    activeSideBarItem === sideBarItem?.title
                      ? collapseSideBar
                        ? "sideBarItem activeItem collapseSideBar"
                        : "sideBarItem activeItem"
                      : collapseSideBar
                      ? "sideBarItem collapseSideBar"
                      : "sideBarItem"
                  }
                  onClick={() => {
                    storeActiveSideBarItem(sideBarItem.title);
                  }}
                >
                  <div className="sideBarItemBanner"></div>
                  <div
                    className={
                      collapseSideBar
                        ? "sideBarItemDetails collapseSideBar"
                        : "sideBarItemDetails"
                    }
                  >
                    <Tooltip
                      placement="right"
                      title={collapseSideBar ? sideBarItem?.title : ""}
                      arrow={mergedArrow}
                    >
                      {sideBarItem.icon}
                    </Tooltip>

                    <span
                      className={
                        collapseSideBar
                          ? "sideBarItemTitle collapseSideBar"
                          : "sideBarItemTitle"
                      }
                    >
                      {sideBarItem.title}
                    </span>
                  </div>
                </div>
              </div>
            </Link>
          );
        })}
      </div>

      <div className="collapseSideBarDiv">
        {collapseSideBar && (
          <RightCircleOutlined
            className="collapseSideBarButton"
            onClick={() => {
              setCollapseSideBar(false);
              window.localStorage.setItem("SdbarClpSt", JSON.stringify(false));
            }}
          />
        )}

        {!collapseSideBar && (
          <LeftCircleOutlined
            className="collapseSideBarButton"
            onClick={() => {
              setCollapseSideBar(true);
              window.localStorage.setItem("SdbarClpSt", JSON.stringify(true));
            }}
          />
        )}
      </div>
    </div>
  );
}

export default SideBar;
