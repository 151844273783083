import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { EncodeString } from "../../../services/TableUtilities";
import { showLoading, hideLoading } from "../../../redux/LoaderSlice";
import ApiService from "../../../services/ApiService";
import { ExtractNewEntityID } from "../../../services/DataProcessor";
import ErrorValidation from "../../../services/ErrorValidation";
import {
  Breadcrumb,
  Form,
  Input,
  InputNumber,
  Select,
  Button,
  Row,
  Col,
  DatePicker,
} from "antd";
import dayjs from "dayjs";
import { ArrowLeftOutlined } from "@ant-design/icons";
import toast from "react-hot-toast";
import "./PaymentsFormPage.scss";
import { PhoneRegex } from "../../../static/RegexPattern";
const dateFormat = "YYYY-MM-DD";

const PaymentsFormPage = ({ type }) => {
  const [form] = Form.useForm();
  const { paymentID } = useParams();
  var Buffer = require("buffer/").Buffer;
  let decodedPatientIDNPaymentIndexString = Buffer.from(
    paymentID,
    "base64"
  ).toString();
  let decodedPatientID = decodedPatientIDNPaymentIndexString.split("?")[0];
  let paymentIndex = decodedPatientIDNPaymentIndexString.split("?")[1];
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [paymentData, setPaymentData] = useState([]);
  const [patientData, setPatientData] = useState([]);
  const [dataIsReady, setDataIsReady] = useState(false);
  const [access, setAccess] = useState(true);
  const [editMode, setEditMode] = useState(false);
  const [allPatientData, setAllPatientData] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState("disabled");
  const [selectedPatientID, setSelectedPatientID] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("");
  const [patientDropDownOptions, setPatientDropDownOptions] = useState([
    { value: "disabled", label: "Select a Patient", disabled: true },
  ]);

  let responseObj = localStorage.getItem("token");
  if (responseObj) {
    responseObj = JSON.parse(Buffer.from(responseObj, "base64").toString());
  }

  let role = null;
  if (responseObj?.employeeData) {
    role = responseObj?.employeeData?.Role;
  }

  const paymentStatusDropDownOptions = [
    { value: "disabled", label: "Select a Payment Status", disabled: true },
    { value: "Pending", label: "Pending" },
    { value: "Paid", label: "Paid" },
  ];

  const onPatientSelectChange = async (value) => {
    setSelectedPatient(value);
    let requestObj = await getSelectedPatientData(value);
    setSelectedPatientID(value);
    let formatObj = {};
    formatObj["PhonePrimary"] = requestObj["Phone"]?.Primary;
    form.setFieldsValue(formatObj);
  };

  const onPaymentStatusSelectChange = (value) => {
    setPaymentStatus(value);
  };

  let apiURL = "/patients/";
  let apiType = "put";
  if (type === "edit") {
    apiURL += decodedPatientID;
  }

  const getPatientPaymentData = async () => {
    dispatch(showLoading());
    const apiResponse = await ApiService("get", apiURL);
    const apiData = apiResponse?.response;
    if (apiData) {
      setPatientData(apiData);
      setPaymentData(apiData["Payments"][paymentIndex]);
      setDataIsReady(true);
      dispatch(hideLoading());
    } else {
      const apiError = apiResponse?.error;
      let errorMessage = ErrorValidation(apiError?.response?.status);
      toast.error(errorMessage);
      dispatch(hideLoading());
    }
  };

  const getAllPatientData = async () => {
    dispatch(showLoading());
    const apiResponse = await ApiService("get", apiURL, null);
    const apiData = apiResponse?.response;
    if (apiData) {
      setAllPatientData(apiData);
      let patientDropDownOptionsValues = await parsePatientData();
      setPatientDropDownOptions(patientDropDownOptionsValues);
      setDataIsReady(true);
      dispatch(hideLoading());
    } else {
      const apiError = apiResponse?.error;
      let errorMessage = ErrorValidation(apiError?.response?.status);
      toast.error(errorMessage);
      dispatch(hideLoading());
    }
  };

  const onFormSubmit = async (values) => {
    if (values) {
      dispatch(showLoading());
      let paymentObject = values;
      delete paymentObject["PatientSelection"];
      delete paymentObject["PhonePrimary"];
      if (type === "edit") {
        paymentObject["PaymentID"] = paymentData["PaymentID"];
      }
      paymentObject["BillingCycle"] = paymentObject["BillingCycle"].toString();
      paymentObject["PaidOn"] = paymentObject["PaidOn"].format(dateFormat);
      paymentObject["DueDate"] = paymentObject["DueDate"].format(dateFormat);
      let paymentsArray = [];
      let requestObj = null;
      if (type === "edit") {
        requestObj = await getSelectedPatientData(selectedPatientID);
        paymentsArray = requestObj["Payments"];
        paymentsArray[paymentIndex] = paymentObject;
        requestObj["Payments"] = paymentsArray;
      } else {
        requestObj = paymentObject;
        apiType = "post";
        apiURL += selectedPatient + "/payments";
      }

      const apiResponse = await ApiService(apiType, apiURL, requestObj);
      const apiData = apiResponse?.response;
      if (apiData) {
        if (type === "add") {
          toast.success("Payment was added Successfully!");
          dispatch(hideLoading());
          navigateToNewEntityPage(null, null);
        } else {
          toast.success("Payment was updated Successfully!");
          dispatch(hideLoading());
          navigateToNewEntityPage(requestObj, paymentIndex);
        }
      } else {
        const apiError = apiResponse?.error;
        let errorMessage = ErrorValidation(apiError?.response?.status);
        toast.error(errorMessage);
        dispatch(hideLoading());
      }
    }
  };

  const onFinishFailed = (errorInfo) => {
    toast.error("Please fill all required form fields!");
  };

  const getSelectedPatientData = async (selectedPatientID) => {
    if (type === "add") {
      apiURL = "/patients/" + selectedPatientID;
    }
    const apiResponse = await ApiService("get", apiURL);
    const apiData = apiResponse?.response;
    if (apiData) {
      return apiData;
    }
  };

  const parsePatientData = async () => {
    let patientSelectionData = [
      { value: "disabled", label: "Select a Patient", disabled: true },
    ];
    for (let i = 0; i < allPatientData.length; i++) {
      let record = allPatientData[i];
      let extractedPatient = {};
      extractedPatient["value"] = record["_id"];
      extractedPatient["label"] =
        record["Name"]?.FNAME + " " + record["Name"]?.LName;
      patientSelectionData.push(extractedPatient);
    }

    return patientSelectionData;
  };

  const navigateToNewEntityPage = async (requestObj, currentPaymentIndex) => {
    let extractedEntityID = null;
    if (type === "edit") {
      extractedEntityID = await ExtractNewEntityID({
        apiURL: apiURL,
        primaryPhoneNumber: requestObj["Phone"]?.Primary,
      });
    } else {
      extractedEntityID = selectedPatientID;
      requestObj = await getSelectedPatientData(selectedPatientID);
      currentPaymentIndex = requestObj["Payments"]?.length;
    }

    if (extractedEntityID) {
      let encodedEntityID = EncodeString(
        extractedEntityID + "?" + currentPaymentIndex
      );
      navigate("/payments/edit_payment/=/" + encodedEntityID, {
        replace: true,
      });
    }
  };

  const onPatientSearch = (value) => {};

  const filterPatientOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const validatePatientSelection = (value) => {
    let validatedPatientSelection = false;
    if (value?.value !== "disabled") {
      validatedPatientSelection = true;
    }

    return validatedPatientSelection;
  };

  const validateUserRole = (type = null) => {
    let validatedUserRole = false;
    if (role === "Admin" || role === "Accountant") {
      validatedUserRole = true;
    }

    return validatedUserRole;
  };

  useEffect(() => {
    if (role === "HR") {
      toast.error("Unauthorized Access!");
      navigate("/");
    } else {
      if (!allPatientData) {
        getAllPatientData();
      }

      if (type === "add" && !dataIsReady) {
        getAllPatientData();
      }

      if (type === "edit") {
        getPatientPaymentData();
      }
    }
  }, [allPatientData]);

  return (
    <>
      {((dataIsReady && patientData && paymentData) ||
        (dataIsReady && type === "add")) && (
        <div className="paymentsFormContainer">
          <div className="paymentsFormDiv">
            <Breadcrumb
              className="breadCrumb"
              items={[
                {
                  title: <a href="../../=">Payments</a>,
                },
                {
                  title: type === "edit" ? "Edit Payment" : "Add Payment",
                },
              ]}
            />

            <div className={type === "edit" ? "header edit" : "header"}>
              <div className="headerStart">
                <ArrowLeftOutlined
                  className="backArrowButton"
                  onClick={() => {
                    navigate("/payments/=");
                  }}
                />
                <h3 className="paymentsFormTitle">
                  {type === "add"
                    ? "Add Payment"
                    : Object.keys(patientData).length > 0
                    ? patientData?.Name?.FNAME + " " + patientData?.Name?.LName
                    : "Edit Payment"}
                </h3>
              </div>

              <div>
                {editMode && (
                  <Button
                    className="paymentsFormButton cancel"
                    danger
                    onClick={() => {
                      setEditMode(false);
                    }}
                  >
                    CANCEL
                  </Button>
                )}

                {!editMode && type === "edit" && (
                  <Button
                    className="paymentsFormButton"
                    type="primary"
                    onClick={() => {
                      let validatedUser = validateUserRole();
                      if (validatedUser) {
                        setEditMode(true);
                      } else {
                        toast.error("Unauthorized Access!");
                      }
                    }}
                    disabled={access ? false : true}
                  >
                    EDIT
                  </Button>
                )}

                {(type === "add" || editMode) && (
                  <Button
                    className="paymentsFormButton"
                    type="primary"
                    htmlType={access ? "submit" : null}
                    onClick={() => {
                      form.submit();
                      if (editMode) {
                        setEditMode(false);
                      }
                      dispatch(showLoading());
                      setTimeout(() => {
                        dispatch(hideLoading());
                      }, 500);
                    }}
                    disabled={access ? false : true}
                  >
                    {editMode ? "SAVE" : "ADD"}
                  </Button>
                )}
              </div>
            </div>

            <Form
              form={form}
              className={type === "edit" ? "paymentsForm edit" : "paymentsForm"}
              layout="vertical"
              onFinish={onFormSubmit}
              onFinishFailed={onFinishFailed}
            >
              <div className="paymentsFormFields">
                <div className="paymentsFormFieldsScrollBox">
                  <Row
                    gutter={{
                      xs: 8,
                      sm: 16,
                      md: 24,
                      lg: 32,
                    }}
                  >
                    <Col className="gutter-row" span={6}>
                      <Form.Item
                        label="Patient"
                        name="PatientSelection"
                        rules={[
                          {
                            validator: (_, value) => {
                              let validatedPatientSelection =
                                validatePatientSelection(value);
                              if (validatedPatientSelection) {
                                return Promise.resolve();
                              } else {
                                return Promise.reject("Please Select Patient");
                              }
                            },
                            message: "Please Select Patient",
                          },
                          {
                            required: true,
                            message: "Select Patient",
                          },
                        ]}
                        initialValue={
                          type === "edit"
                            ? patientData?.Name?.FNAME +
                              " " +
                              patientData?.Name?.LName
                            : "disabled"
                        }
                      >
                        <Select
                          showSearch
                          placeholder="Select Patient"
                          optionFilterProp="children"
                          options={patientDropDownOptions}
                          value={selectedPatient}
                          onChange={onPatientSelectChange}
                          onSearch={onPatientSearch}
                          filterOption={filterPatientOption}
                          disabled={type === "edit" ? true : false}
                        />
                      </Form.Item>
                    </Col>

                    <Col className="gutter-row" span={6}>
                      <Form.Item
                        label="Contact"
                        name="PhonePrimary"
                        initialValue={patientData?.Phone?.Primary}
                        rules={[
                          {
                            required: true,
                            message: "Enter Phone number",
                          },
                          {
                            pattern: PhoneRegex,
                            message: "Enter valid Phone number",
                          },
                        ]}
                      >
                        <Input placeholder="Phone Number" disabled={true} />
                      </Form.Item>
                    </Col>

                    <Col className="gutter-row" span={6}>
                      <Form.Item
                        label="Fee Amount"
                        name="Amount"
                        initialValue={paymentData?.Amount}
                        rules={[
                          {
                            required: true,
                            message: "Enter Fee Amount",
                          },
                        ]}
                      >
                        <InputNumber
                          placeholder="Fee Amount"
                          onChange={(value) => {}}
                          min={0}
                          step={1}
                          max={1000000}
                          width={500}
                          style={{ width: "100%" }}
                          disabled={
                            type === "edit" ? (editMode ? false : true) : false
                          }
                        />
                      </Form.Item>
                    </Col>

                    <Col className="gutter-row" span={6}>
                      <Form.Item
                        label="Billing Cycle (Days)"
                        name="BillingCycle"
                        initialValue={paymentData?.BillingCycle}
                        rules={[
                          {
                            required: true,
                            message: "Enter Billing Cycle",
                          },
                        ]}
                      >
                        <InputNumber
                          placeholder="Billing Cycle: Eg: 28, 30, 31"
                          onChange={(value) => {}}
                          min={0}
                          step={1}
                          max={31}
                          width={500}
                          style={{ width: "100%" }}
                          disabled={
                            type === "edit" ? (editMode ? false : true) : false
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row
                    gutter={{
                      xs: 8,
                      sm: 16,
                      md: 24,
                      lg: 32,
                    }}
                  >
                    <Col className="gutter-row" span={6}>
                      <Form.Item
                        label="Billing Cycle Duration"
                        name="BillingCycleDuration"
                        initialValue={paymentData?.BillingCycleDuration}
                        rules={[
                          {
                            required: true,
                            message: "Enter Billing Cycle Duration",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Eg: 01-05-2024 - 31-05-2024"
                          onChange={(value) => {}}
                          disabled={
                            type === "edit" ? (editMode ? false : true) : false
                          }
                        />
                      </Form.Item>
                    </Col>

                    <Col className="gutter-row" span={6}>
                      <Form.Item
                        label="Due Date"
                        name="DueDate"
                        rules={[
                          {
                            required: true,
                            message: "Select Due Date",
                          },
                        ]}
                        initialValue={
                          paymentData?.PaidOn
                            ? dayjs(
                                (paymentData?.DueDate).toString(),
                                dateFormat
                              )
                            : null
                        }
                      >
                        <DatePicker
                          disabled={
                            type === "edit" ? (editMode ? false : true) : false
                          }
                          format={dateFormat}
                        />
                      </Form.Item>
                    </Col>

                    <Col className="gutter-row" span={6}>
                      <Form.Item
                        label="Payment Date"
                        name="PaidOn"
                        rules={[
                          {
                            required: true,
                            message: "Select Payment Date",
                          },
                        ]}
                        initialValue={
                          paymentData?.PaidOn
                            ? dayjs(
                                (paymentData?.PaidOn).toString(),
                                dateFormat
                              )
                            : null
                        }
                      >
                        <DatePicker
                          // minDate={dayjs(dateToday.toString(), dateFormat)}
                          disabled={
                            type === "edit" ? (editMode ? false : true) : false
                          }
                          format={dateFormat}
                        />
                      </Form.Item>
                    </Col>

                    <Col className="gutter-row" span={6}>
                      <Form.Item
                        label="Payment Status"
                        name="PaymentStatus"
                        rules={[
                          {
                            required: true,
                            message: "Select a Payment Status",
                          },
                        ]}
                        initialValue={paymentData?.PaymentStatus}
                      >
                        <Select
                          options={paymentStatusDropDownOptions}
                          defaultValue="disabled"
                          value={paymentStatus}
                          onChange={onPaymentStatusSelectChange}
                          disabled={
                            // paymentData?.PaymentStatus === "Paid"
                            //   ? true
                            //   : type === "edit"
                            //   ? editMode
                            //     ? false
                            //     : true
                            //   : false
                            type === "edit" ? (editMode ? false : true) : false
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              </div>
            </Form>
          </div>
        </div>
      )}
    </>
  );
};

export default PaymentsFormPage;
