import axios from "axios";
import { BACKEND_URL, IMAGE_SERVER_URL } from "../App";

const ApiService = async (type, apiURL, requestObj = null) => {
  // console.log(type, apiURL, requestObj);
  let responseObj = {
    response: null,
    error: null,
    trycError: null,
  };

  try {
    await axios({
      method: type,
      url: BACKEND_URL + apiURL,
      data: JSON.stringify(requestObj),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        responseObj.response = response.data;
      })
      .catch((error) => {
        responseObj.error = error;
      });
  } catch (error) {
    responseObj.trycError = error;
  }

  // console.log(`ApiService`, responseObj);
  return responseObj;
};

const ImageService = async (fd) => {
  let savedFileName = null;

  try {
    await axios({
      method: "post",
      url: IMAGE_SERVER_URL + "/images/",
      data: fd,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((response) => {
        if (response.status === 200 && response?.data?.fileData) {
          savedFileName = response?.data?.fileData?.filename;
        }
      })
      .catch((error) => {});
  } catch (error) {}

  return savedFileName;
};

export default ApiService;
export { ImageService };
