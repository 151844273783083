import React from "react";
import {
  UserGroupIcon,
  UserPlusIcon,
  HeartIcon,
  DocumentPlusIcon,
} from "@heroicons/react/24/solid";
import DashboardCard from "../../components/dashboardCard/DashboardCard";
import "./Home.scss";

export default function Home() {
  const dashboardItems = [
    {
      icon: <UserGroupIcon />,
      title: "Invite Employees",
      description: "Invite Admins, Accountants & CRM personnel",
      link: "/employees",
      linkButtonText: "INVITE",
    },
    {
      icon: <UserPlusIcon />,
      title: "Add Staff",
      description: "Add Nurses, Care Takers & Home Nurses",
      link: "/staff",
      linkButtonText: "ADD",
    },
    {
      icon: <HeartIcon />,
      title: "Add Patients",
      description: "Add Patients & assign Care Takers",
      link: "/patients/=",
      linkButtonText: "ADD",
    },
    {
      icon: <DocumentPlusIcon />,
      title: "Register Leads",
      description: "Convert potential Leads to Customers",
      link: "/leads",
      linkButtonText: "REGISTER",
    },
  ];

  return (
    <div className="home">
      {dashboardItems.map((dashboardItem, i) => {
        let index = i + 1;
        return <DashboardCard key={index} dashboardItem={dashboardItem} />;
      })}
    </div>
  );
}
