import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { EncodeString } from "../../../services/TableUtilities";
import { showLoading, hideLoading } from "../../../redux/LoaderSlice";
import { FRONTEND_URL, IMAGE_SERVER_URL } from "../../../App";
import ApiService from "../../../services/ApiService";
import {
  FormObjectProcessor,
  FormatInput,
  ValidateExperience,
  ExtractNewEntityID,
} from "../../../services/DataProcessor";
import ErrorValidation from "../../../services/ErrorValidation";
import {
  AlphabetRegex,
  PhoneRegex,
  AadhaarRegex,
  PinCodeRegex,
} from "../../../static/RegexPattern";
import {
  Breadcrumb,
  Form,
  Input,
  InputNumber,
  Select,
  Button,
  Row,
  Col,
  Tabs,
  Table,
  Card,
  DatePicker,
} from "antd";
import html2canvas from "html2canvas";
import jspdf from "jspdf";
import { ArrowLeftOutlined, FilePdfOutlined } from "@ant-design/icons";
import toast from "react-hot-toast";
import "./StaffFormPage.scss";
import dayjs from "dayjs";
import ModalComponent from "../../../components/modalComponent/ModalComponent";
import ModalForm from "../../../components/modalForm/ModalForm";
import StaffAttendanceNLeavesTable from "../StaffAttendanceNLeavesTable/StaffAttendanceNLeavesTable";
const dateFormat = "DD-MM-YYYY";

const { TextArea } = Input;

const StaffFormPage = ({ type }) => {
  const [form] = Form.useForm();
  const { staffID } = useParams();
  var Buffer = require("buffer/").Buffer;
  const decodedStaffIDString = Buffer.from(staffID, "base64").toString();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [staffData, setStaffData] = useState([]);
  const [dataIsReady, setDataIsReady] = useState(false);
  const [authorized, setAuthorized] = useState(false);
  const [access, setAccess] = useState(true);
  const [editMode, setEditMode] = useState(false);
  const [roleType, setRoleType] = useState("");
  const [displayStaffDetails, setDisplayStaffDetails] = useState(true);
  const [displayStaffAttendance, setDisplayStaffAttendance] = useState(false);
  const [displayStaffLeaves, setDisplayStaffLeaves] = useState(false);
  const [staffAttendanceTableData, setStaffAttendanceTableData] = useState([]);
  const [staffLeavesTableData, setStaffLeavesTableData] = useState([]);
  const [exportStaffCV, setExportStaffCV] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const exportStaffCVRef = useRef();

  let responseObj = localStorage.getItem("token");
  if (responseObj) {
    responseObj = JSON.parse(Buffer.from(responseObj, "base64").toString());
  }
  let role = null;

  if (responseObj?.employeeData) {
    role = responseObj?.employeeData?.Role;
  }

  const dropDownOptions = [
    { value: "disabled", label: "Select a Designation", disabled: true },
    { value: "Nurse", label: "Nurse" },
    { value: "House Maid", label: "House Maid" },
    { value: "Caretaker", label: "Caretaker" },
  ];

  const tabItems = [
    {
      key: "1",
      label: "Details",
      children: "",
    },
    {
      key: "2",
      label: "Attendance",
      children: "",
    },
    {
      key: "3",
      label: "Leaves",
      children: "",
    },
  ];

  const onSelectChange = (value) => {
    setRoleType(value);
  };

  let apiURL = "/staff/";
  let apiType = "post";
  if (type === "edit") {
    apiType = "put";
    apiURL = "/staff/" + decodedStaffIDString;
  }

  const getStaffData = async () => {
    dispatch(showLoading());
    const apiResponse = await ApiService("get", apiURL);
    const apiData = apiResponse?.response;
    if (apiData) {
      setStaffData(apiData);
      dispatch(hideLoading());
      setDataIsReady(true);
    } else {
      const apiError = apiResponse?.error;
      let errorMessage = ErrorValidation(apiError?.response?.status);
      toast.error(errorMessage);
    }
  };

  const onFormSubmit = async (values) => {
    if (values) {
      let requestObj = FormObjectProcessor(values);
      requestObj = await processRequestObj(requestObj);
      dispatch(showLoading());
      const apiResponse = await ApiService(apiType, apiURL, requestObj);
      const apiData = apiResponse?.response;
      if (apiData) {
        setStaffData(apiData);
        setDataIsReady(true);
        if (type === "add") {
          toast.success("Staff was added Successfully!");
          await navigateToNewEntityPage(requestObj);
        } else {
          toast.success("Staff was updated Successfully!");
          dispatch(hideLoading());
        }
      } else {
        const apiError = apiResponse?.error;
        let errorMessage = ErrorValidation(apiError?.response?.status);
        // let errorMessage = "No Changes were detected!";
        toast.error(errorMessage);
        dispatch(hideLoading());
      }
    }
  };

  const onFinishFailed = (errorInfo) => {
    toast.error("Please fill all required form fields!");
    // console.log("Failed:", errorInfo);
  };

  const processRequestObj = (requestObj) => {
    if (type === "add") {
      requestObj["Status"] = "Unassigned";
      requestObj["Payment"] = [];
      requestObj["ProfileImage"] = "";
      requestObj["IdentityProof"] = "";
    }

    if (type === "edit") {
      requestObj["Status"] = staffData?.Status;
      requestObj["Payment"] = staffData?.Payment;
      requestObj["ProfileImage"] = staffData?.ProfileImage;
      requestObj["IdentityProof"] = staffData?.IdentityProof;
      // requestObj["AssignmentHistory"] = staffData?.AssignmentHistory;
    }

    return requestObj;
  };

  // const parseStaffData = async (allStaffData) => {
  //   let extractedAttendance = [];
  //   let extractedLeaves = [];
  //   for (let i = 0; i < allStaffData["AssignmentHistory"]?.length; i++) {
  //     let record = allStaffData["AssignmentHistory"][i];
  //     extractedAttendance.push(record);
  //   }

  //   let staffTempAttendanceTableData = [];
  //   extractedAttendance.map((item) => {
  //     let attendanceRecord = null;
  //     let patientID = item?.PatientID;
  //     let patientName = getPatientName(patientID);
  //     if (patientName) {
  //       attendanceRecord = {
  //         _id: item?._id,
  //         StaffID: item?.StaffID,
  //         PatientID: item?.PatientID,
  //         PatientName: patientName,
  //         TotalWorked: item?.TotalWorked,
  //         StartDate: item?.StartDate,
  //         EndDate: item?.EndDate,
  //       };
  //       staffTempAttendanceTableData.push(attendanceRecord);
  //     }
  //   });
  //   setStaffAttendanceTableData(staffTempAttendanceTableData);
  //   // console.log(`staffTempAttendanceTableData`, staffTempAttendanceTableData);
  // };

  const getPatientName = async (patientID) => {
    const apiResponse = await ApiService("get", "/patients/" + patientID);
    const apiData = apiResponse?.response;
    if (apiData) {
      // console.log(`HereapiData`, apiData);
    }
  };

  const navigateToNewEntityPage = async (requestObj) => {
    let extractedEntityID = await ExtractNewEntityID({
      apiURL: apiURL,
      primaryPhoneNumber: requestObj["Phone"]?.Primary,
    });

    if (extractedEntityID) {
      let encodedEntityID = await EncodeString(extractedEntityID);
      navigate("/staff/edit_staff/" + encodedEntityID, {
        replace: true,
      });
    }
  };

  const onTabChange = async (key) => {
    if (key === "1") {
      setDisplayStaffDetails(true);
      setDisplayStaffAttendance(false);
      setDisplayStaffLeaves(false);
    }

    if (key === "2") {
      // await parseStaffData(staffData);
      // setStaffAttendanceTableData(staticStaffAttendanceTableData);
      setDisplayStaffDetails(false);
      setDisplayStaffAttendance(true);
      setDisplayStaffLeaves(false);
    }

    if (key === "3") {
      // setStaffLeavesTableData(staticStaffLeavesTableData);
      setDisplayStaffDetails(false);
      setDisplayStaffAttendance(false);
      setDisplayStaffLeaves(true);
    }
  };

  const formatAadharNumber = (aadharNumber) => {
    let part1 = aadharNumber.substring(0, 4);
    let part2 = aadharNumber.substring(4, 8);
    let part3 = aadharNumber.substring(8, 12);
    aadharNumber = part1 + "-" + part2 + "-" + part3;
    return aadharNumber;
  };

  const exportStaffCVToPDF = () => {
    dispatch(showLoading());
    setExportStaffCV(true);
    document.getElementById("contentBody").style.overflow = "";
    setTimeout(() => {
      html2canvas(exportStaffCVRef.current, { useCORS: true }).then(
        (canvas) => {
          const imgData = canvas.toDataURL("img/png");
          const doc = new jspdf("p", "mm", "a4");
          const componentWidth = doc.internal.pageSize.getWidth();
          const componentHeight = doc.internal.pageSize.getHeight();
          doc.addImage(
            imgData,
            "PNG",
            0,
            2,
            componentWidth,
            componentHeight - 5
          );
          doc.save(
            `Staff_${
              staffData?.Name?.FNAME + "_" + staffData?.Name?.LName
            }_CV.pdf`
          );
        }
      );
    }, 1000);
    setTimeout(() => {
      document.getElementById("contentBody").style.overflow = "hidden";
      setExportStaffCV(false);
      dispatch(hideLoading());
      toast.success("Staff CV exported successfully");
    }, 1000);
  };

  const confirmFormSubmission = async (values) => {};

  const confirmCancel = () => {
    setOpenModal(false);
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  const validateUserRole = (type = null) => {
    let validatedUserRole = false;
    if (role === "Admin" || role === "HR" || role === "CRM") {
      validatedUserRole = true;
    }

    return validatedUserRole;
  };

  useEffect(() => {
    if (type === "edit") {
      getStaffData();
    }
  }, []);

  return (
    <>
      {(type === "add" || dataIsReady) && (
        <div className="staffFormContainer">
          <div className="staffFormDiv">
            <Breadcrumb
              className="breadCrumb"
              items={[
                {
                  title: <a href="../../staff">Staff</a>,
                },
                {
                  title:
                    type === "edit" ? "Edit Staff Member" : "Add Staff Member",
                },
              ]}
            />

            {type !== "add" && (
              <Tabs
                className="tabs"
                defaultActiveKey="1"
                items={tabItems}
                onChange={onTabChange}
              />
            )}

            <div className={type === "edit" ? "header edit" : "header"}>
              <div className="headerStart">
                <ArrowLeftOutlined
                  className="backArrowButton"
                  onClick={() => {
                    navigate("/staff");
                  }}
                />
                <h3 className="staffFormTitle">
                  {type === "add"
                    ? "Add Staff Member"
                    : staffData?.Name?.FNAME + " " + staffData?.Name?.LName}
                </h3>
                {type === "edit" ? (
                  <span
                    className={
                      staffData?.Status === "Unassigned"
                        ? "staffStatusTag unassigned"
                        : staffData?.Status === "Assigned"
                        ? "staffStatusTag assigned"
                        : "staffStatusTag inactive"
                    }
                  >
                    Status: {staffData?.Status}
                  </span>
                ) : null}
              </div>

              {displayStaffDetails && (
                <div>
                  {editMode && (
                    <Button
                      className="staffFormButton cancel"
                      danger
                      onClick={() => {
                        setEditMode(false);
                      }}
                    >
                      CANCEL
                    </Button>
                  )}

                  {!editMode && type !== "add" && (
                    <Button
                      className="staffFormButton cv"
                      onClick={() => {
                        let validatedUserRole = validateUserRole();
                        if (validatedUserRole) {
                          exportStaffCVToPDF();
                        } else {
                          toast.error("Unauthorized Access!");
                        }
                      }}
                    >
                      Download C.V
                    </Button>
                  )}

                  {!editMode && type === "edit" && (
                    <Button
                      className="staffFormButton"
                      type="primary"
                      onClick={() => {
                        let validatedUserRole = validateUserRole();
                        if (validatedUserRole) {
                          setEditMode(true);
                        } else {
                          toast.error("Unauthorized Access!");
                        }
                      }}
                      disabled={access ? false : true}
                    >
                      EDIT
                    </Button>
                  )}

                  {(type === "add" || editMode) && (
                    <Button
                      className="staffFormButton"
                      type="primary"
                      htmlType={access ? "submit" : null}
                      onClick={() => {
                        let validatedUserRole = validateUserRole();
                        if (validatedUserRole) {
                          form.submit();
                          if (editMode) {
                            setEditMode(false);
                          }
                          dispatch(showLoading());
                          setTimeout(() => {
                            dispatch(hideLoading());
                          }, 500);
                        } else {
                          toast.error("Unauthorized Access!");
                        }
                      }}
                      disabled={access ? false : true}
                    >
                      {editMode ? "SAVE" : "ADD"}
                    </Button>
                  )}
                </div>
              )}
              {displayStaffAttendance && (
                <></>
                // <Link
                //   className="headerLink"
                //   onClick={() => {
                //     let validatedUserRole = validateUserRole();
                //     if (validatedUserRole) {
                //       setOpenModal(true);
                //     } else {
                //       toast.error("Unauthorized Access!");
                //     }
                //   }}
                // >
                //   ADD ATTENDANCE
                // </Link>
              )}
            </div>

            {displayStaffDetails && (
              <Form
                form={form}
                className={type === "edit" ? "staffForm edit" : "staffForm"}
                layout="vertical"
                onFinish={onFormSubmit}
                onFinishFailed={onFinishFailed}
              >
                <div className="staffFormFields">
                  {type === "edit" && (
                    <div className="uploadImagePlaceholder">
                      <img
                        className="uploadImageIcon"
                        src={
                          staffData?.ProfileImage !== ""
                            ? IMAGE_SERVER_URL +
                              "/uploads/staff/" +
                              staffData?.ProfileImage?.split(".")[0] +
                              "/" +
                              staffData?.ProfileImage
                            : `${FRONTEND_URL}/assets/user.png`
                        }
                        alt="upload_image"
                      />
                      {staffData?.IdentityProof !== "" && (
                        <Link
                          to={
                            IMAGE_SERVER_URL +
                            "/uploads/staff/" +
                            staffData?.IdentityProof?.split(".")[0] +
                            "/" +
                            staffData?.IdentityProof
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <FilePdfOutlined className="pdfIcon" />
                        </Link>
                      )}

                      <Button
                        className="uploadImageButton"
                        onClick={(e) => {
                          let validatedUserRole = validateUserRole();
                          if (validatedUserRole) {
                            setOpenModal(true);
                          } else {
                            toast.error("Unauthorized Access!");
                          }
                        }}
                      >
                        Upload Staff Documents
                      </Button>
                    </div>
                  )}

                  <div className="staffFormFieldsScrollBox">
                    <Row
                      gutter={{
                        xs: 8,
                        sm: 16,
                        md: 24,
                        lg: 32,
                      }}
                    >
                      <Col className="gutter-row" span={6}>
                        <Form.Item
                          label="First Name"
                          name="Name-FNAME"
                          initialValue={staffData?.Name?.FNAME}
                          rules={[
                            {
                              required: true,
                              message: "Enter First Name",
                            },
                            {
                              pattern: AlphabetRegex,
                              message: "Enter valid First Name",
                            },
                            {
                              max: 50,
                              message: "First Name limit exceeded!",
                            },
                          ]}
                          onBlur={(e) => FormatInput(e, form)}
                        >
                          <Input
                            placeholder="Example: Raj"
                            disabled={
                              type === "edit"
                                ? editMode
                                  ? false
                                  : true
                                : false
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col className="gutter-row" span={6}>
                        <Form.Item
                          label="Last Name"
                          name="Name-LName"
                          initialValue={staffData?.Name?.LName}
                          rules={[
                            {
                              required: true,
                              message: "Enter Last Name",
                            },
                            {
                              pattern: AlphabetRegex,
                              message: "Enter valid Last Name",
                            },
                            {
                              max: 50,
                              message: "Last Name limit exceeded!",
                            },
                          ]}
                          onBlur={(e) => FormatInput(e, form)}
                        >
                          <Input
                            placeholder="Example: Kumar"
                            disabled={
                              type === "edit"
                                ? editMode
                                  ? false
                                  : true
                                : false
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col className="gutter-row" span={6}>
                        <Form.Item
                          label="Designation"
                          name="Designation"
                          rules={[
                            {
                              required: true,
                              message: "Select a Designation",
                            },
                          ]}
                          initialValue={staffData?.Designation}
                        >
                          <Select
                            options={dropDownOptions}
                            defaultValue="disabled"
                            value={roleType}
                            onChange={onSelectChange}
                            disabled={
                              type === "edit"
                                ? editMode
                                  ? false
                                  : true
                                : false
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col className="gutter-row" span={6}>
                        <Form.Item
                          label="Aadhaar Number"
                          name="AadharNo"
                          initialValue={staffData?.AadharNo}
                          rules={[
                            {
                              required: true,
                              message: "Enter Aadhaar number",
                            },
                            {
                              pattern: AadhaarRegex,
                              message: "Enter a valid Aadhaar number",
                            },
                          ]}
                        >
                          <Input
                            placeholder="Enter Aadhaar number"
                            disabled={
                              type === "edit"
                                ? editMode
                                  ? false
                                  : true
                                : false
                            }
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row
                      gutter={{
                        xs: 8,
                        sm: 16,
                        md: 24,
                        lg: 32,
                      }}
                    >
                      <Col className="gutter-row" span={6}>
                        <Form.Item
                          label="Primary Phone Number"
                          name="Phone-Primary"
                          initialValue={staffData?.Phone?.Primary}
                          rules={[
                            {
                              required: true,
                              message: "Enter Phone number",
                            },
                            {
                              pattern: PhoneRegex,
                              message: "Enter valid Phone number",
                            },
                          ]}
                        >
                          <Input
                            placeholder="Primary Phone Number"
                            disabled={
                              type === "edit"
                                ? editMode
                                  ? false
                                  : true
                                : false
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col className="gutter-row" span={6}>
                        <Form.Item
                          label="Alternate Phone Number"
                          name="Phone-Alternate"
                          initialValue={staffData?.Phone?.Alternate}
                          rules={[
                            {
                              pattern: PhoneRegex,
                              message: "Enter valid Phone number",
                            },
                          ]}
                        >
                          <Input
                            placeholder="Alternate Phone Number"
                            disabled={
                              type === "edit"
                                ? editMode
                                  ? false
                                  : true
                                : false
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col className="gutter-row" span={6}>
                        <Form.Item
                          label="Salary Amount"
                          name="Salary"
                          initialValue={staffData ? staffData?.Salary : 0}
                          rules={[
                            {
                              required: true,
                              message: "Enter Salary Amount",
                            },
                          ]}
                        >
                          <InputNumber
                            placeholder="Enter Salary Amount"
                            onChange={(value) => {}}
                            min={0}
                            step={1}
                            max={1000000}
                            style={{ width: "100%" }}
                            disabled={
                              type === "edit"
                                ? editMode
                                  ? false
                                  : true
                                : false
                            }
                          />
                        </Form.Item>
                      </Col>

                      <Col className="gutter-row" span={6}>
                        <Form.Item
                          label="Experience"
                          name="Experience"
                          initialValue={
                            staffData?.Experience ? staffData?.Experience : 0
                          }
                          rules={[
                            {
                              validator: (_, value) => {
                                let validatedExperience = ValidateExperience(
                                  value,
                                  form
                                );
                                if (validatedExperience) {
                                  return Promise.resolve();
                                } else {
                                  return Promise.reject("Invalid Experience");
                                }
                              },
                              message: "Invalid Experience Value",
                            },
                          ]}
                        >
                          <Input
                            placeholder="Enter Experience: Eg: 0.11, 2.1, 3, 5.11 "
                            onChange={(value) => {}}
                            style={{ width: "100%" }}
                            disabled={
                              type === "edit"
                                ? editMode
                                  ? false
                                  : true
                                : false
                            }
                            onBlur={(e) => FormatInput(e, form)}
                          />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row
                      gutter={{
                        xs: 8,
                        sm: 16,
                        md: 24,
                        lg: 32,
                      }}
                    >
                      <Col className="gutter-row" span={12}>
                        <Form.Item
                          label="Type of Work"
                          name="TypeOfWork"
                          initialValue={staffData ? staffData?.TypeOfWork : ""}
                          rules={[
                            {
                              required: true,
                              message: "Enter details for type of work",
                            },
                            {
                              max: 500,
                              message: "Word limit exceeded!",
                            },
                          ]}
                          onBlur={(e) => FormatInput(e, form)}
                        >
                          <TextArea
                            rows={4}
                            placeholder="Enter type of Work: (Eg: Patient Care, Insulin Check, Sponge Bath...)"
                            maxLength={500}
                            disabled={
                              type === "edit"
                                ? editMode
                                  ? false
                                  : true
                                : false
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col className="gutter-row" span={12}>
                        <Form.Item
                          label="Languages"
                          name="Languages"
                          initialValue={staffData?.Languages}
                          rules={[
                            {
                              required: true,
                              message: "Enter Spoken Languages",
                            },
                            {
                              max: 500,
                              message: "Word limit exceeded!",
                            },
                          ]}
                          onBlur={(e) => FormatInput(e, form)}
                        >
                          <TextArea
                            rows={4}
                            placeholder="Enter Languages: (Eg: English, Konkani, Hindi...)"
                            maxLength={500}
                            disabled={
                              type === "edit"
                                ? editMode
                                  ? false
                                  : true
                                : false
                            }
                          />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row
                      gutter={{
                        xs: 8,
                        sm: 16,
                        md: 24,
                        lg: 32,
                      }}
                    >
                      <Col className="gutter-row" span={6}>
                        <Form.Item
                          label="Date of Joining"
                          name="JoiningDate"
                          rules={[
                            {
                              required: true,
                              message: "Select Joining Date",
                            },
                          ]}
                          initialValue={dayjs(staffData?.JoiningDate)}
                        >
                          <DatePicker placeholder="Select Joining Date" />
                        </Form.Item>
                      </Col>

                      <Col className="gutter-row" span={6}>
                        <Form.Item
                          label="Street Address"
                          name="Address-Street"
                          initialValue={staffData?.Address?.Street}
                          rules={[
                            {
                              required: true,
                              message: "Enter Street Address",
                            },
                            {
                              max: 50,
                              message: "Street Address limit exceeded!",
                            },
                          ]}
                          onBlur={(e) => FormatInput(e, form)}
                        >
                          <Input
                            placeholder="Street Address"
                            disabled={
                              type === "edit"
                                ? editMode
                                  ? false
                                  : true
                                : false
                            }
                          />
                        </Form.Item>
                      </Col>

                      <Col className="gutter-row" span={6}>
                        <Form.Item
                          label="City / Town / Village"
                          name="Address-City"
                          initialValue={staffData?.Address?.City}
                          rules={[
                            {
                              required: true,
                              message: "Enter City Address",
                            },
                            {
                              max: 50,
                              message: "City Address limit exceeded!",
                            },
                            {
                              pattern: AlphabetRegex,
                              message: "Enter valid City Address",
                            },
                          ]}
                          onBlur={(e) => FormatInput(e, form)}
                        >
                          <Input
                            placeholder="City"
                            disabled={
                              type === "edit"
                                ? editMode
                                  ? false
                                  : true
                                : false
                            }
                          />
                        </Form.Item>
                      </Col>

                      <Col className="gutter-row" span={6}>
                        <Form.Item
                          label="Taluka / Subdivison"
                          name="Address-Taluka"
                          initialValue={staffData?.Address?.Taluka}
                          rules={[
                            {
                              required: true,
                              message: "Enter Taluka/Subdivison Address",
                            },
                            {
                              max: 50,
                              message:
                                "Taluka/Subdivison Address limit exceeded!",
                            },
                            {
                              pattern: AlphabetRegex,
                              message: "Enter valid Taluka/Subdivison Address",
                            },
                          ]}
                          onBlur={(e) => FormatInput(e, form)}
                        >
                          <Input
                            placeholder="Taluka/Subdivison"
                            disabled={
                              type === "edit"
                                ? editMode
                                  ? false
                                  : true
                                : false
                            }
                          />
                        </Form.Item>
                      </Col>

                      <Col className="gutter-row" span={6}>
                        <Form.Item
                          label="District"
                          name="Address-District"
                          initialValue={staffData?.Address?.District}
                          rules={[
                            {
                              required: true,
                              message: "Enter District Address",
                            },
                            {
                              max: 50,
                              message: "District Address limit exceeded!",
                            },
                            {
                              pattern: AlphabetRegex,
                              message: "Enter valid District Address",
                            },
                          ]}
                          onBlur={(e) => FormatInput(e, form)}
                        >
                          <Input
                            placeholder="District"
                            disabled={
                              type === "edit"
                                ? editMode
                                  ? false
                                  : true
                                : false
                            }
                          />
                        </Form.Item>
                      </Col>

                      <Col className="gutter-row" span={6}>
                        <Form.Item
                          label="State"
                          name="Address-State"
                          initialValue={staffData?.Address?.State}
                          rules={[
                            {
                              required: true,
                              message: "Enter State Address",
                            },
                            {
                              max: 50,
                              message: "State Address limit exceeded!",
                            },
                            {
                              pattern: AlphabetRegex,
                              message: "Enter valid State Address",
                            },
                          ]}
                          onBlur={(e) => FormatInput(e, form)}
                        >
                          <Input
                            placeholder="State"
                            disabled={
                              type === "edit"
                                ? editMode
                                  ? false
                                  : true
                                : false
                            }
                          />
                        </Form.Item>
                      </Col>

                      <Col className="gutter-row" span={6}>
                        <Form.Item
                          label="Pincode"
                          name="Address-PinCode"
                          initialValue={staffData?.Address?.PinCode}
                          rules={[
                            {
                              required: true,
                              message: "Enter Pincode",
                            },
                            {
                              pattern: PinCodeRegex,
                              message: "Enter valid PinCode",
                            },
                          ]}
                        >
                          <Input
                            placeholder="Pin Code"
                            disabled={
                              type === "edit"
                                ? editMode
                                  ? false
                                  : true
                                : false
                            }
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Form>
            )}

            {type === "edit" && exportStaffCV && (
              <>
                <div
                  ref={exportStaffCVRef}
                  className={exportStaffCV ? "staffCV export" : "staffCV"}
                >
                  <div className="imagePlaceholder">
                    <img
                      className="imageIcon"
                      src={
                        staffData?.ProfileImage !== ""
                          ? IMAGE_SERVER_URL +
                            "/uploads/staff/" +
                            staffData?.ProfileImage?.split(".")[0] +
                            "/" +
                            staffData?.ProfileImage
                          : `${FRONTEND_URL}/assets/user.png`
                      }
                      alt="upload_image"
                    />
                  </div>

                  <div className="staffDataFieldsContainer">
                    <img
                      className="imageLogo"
                      src={`${FRONTEND_URL + "/assets/logo-banner.png"}`}
                      alt="logo"
                    />
                    <h3 className="header">Staff Details</h3>
                    <div className="staffDataFields">
                      <p>
                        <span>Name: </span>
                        {staffData?.Name?.FNAME + " " + staffData?.Name?.LName}
                      </p>

                      <p>
                        <span>Designation: </span>
                        {staffData?.Designation}
                      </p>

                      <p>
                        <span>Date of Joining: </span>
                        {dayjs(staffData?.JoiningDate).format(dateFormat)}
                      </p>

                      <p>
                        <span>Aadhaar Number: </span>
                        {formatAadharNumber(staffData?.AadharNo)}
                      </p>

                      <p>
                        <span>Primary Phone Number: </span>
                        {staffData?.Phone?.Primary}
                      </p>

                      <p>
                        <span>Alternate Phone Number: </span>
                        {staffData?.Phone?.Alternate}
                      </p>

                      <p>
                        <span>Experience: </span>
                        {parseFloat(staffData?.Experience) > 1
                          ? staffData?.Experience + " years"
                          : staffData?.Experience + " months"}
                      </p>

                      <p>
                        <span>Type of Work: </span>
                        {staffData?.TypeOfWork}
                      </p>
                      <p>
                        <span>Languages: </span>
                        {staffData?.Languages}
                      </p>
                      <p>
                        <span>Address: </span>
                        {staffData?.Address.Street +
                          ", " +
                          staffData?.Address.City +
                          ", " +
                          staffData?.Address.Taluka +
                          ", "}
                      </p>
                      <p>
                        <span></span>
                        {staffData?.Address.District +
                          ", " +
                          staffData?.Address.State +
                          ". PinCode: " +
                          staffData?.Address.PinCode +
                          "."}
                      </p>
                    </div>
                  </div>
                </div>
              </>
            )}

            {displayStaffAttendance && (
              <>
                <StaffAttendanceNLeavesTable
                  staffID={staffData?._id}
                  type={"attendance"}
                />
              </>
            )}

            {displayStaffLeaves && (
              <StaffAttendanceNLeavesTable
                staffID={staffData?._id}
                type={"leaves"}
              />
            )}
          </div>

          <ModalComponent
            openModal={openModal}
            setOpenModal={setOpenModal}
            width={"35%"}
            title={""}
            message={""}
            hasOk={false}
            hasCancel={false}
            formData={
              <ModalForm
                type={"Upload Documents"}
                title={"Upload Documents"}
                action={confirmFormSubmission}
                exitAction={confirmCancel}
                staffID={decodedStaffIDString}
              />
            }
          />
        </div>
      )}
    </>
  );
};

export default StaffFormPage;
