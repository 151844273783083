import React from "react";
import {
  SettingOutlined,
  DollarOutlined,
  UserDeleteOutlined,
  PoweroffOutlined,
} from "@ant-design/icons";
import "./NotificationBox.scss";
import { Link } from "react-router-dom";

const NotificationBox = ({ type, positions = null }) => {
  let positionY = null;
  var Buffer = require("buffer/").Buffer;
  if (type === "displayNotifications") {
    positionY = positions.right;
  }

  const notificationItems = [
    {
      icon: <DollarOutlined className="notificationItemIcon" />,
      title: "Payments Due",
      class: "alertNotification",
      link: "payments/payments_due",
    },
    {
      icon: <UserDeleteOutlined className="notificationItemIcon" />,
      title: "Unassigned Patients",
      class: "errorNotification",
      link: "patients/unassigned_patients",
    },
  ];

  const profileItems = [
    {
      icon: "",
      title: "Profile Details",
      class: "profileDetails",
      link: "",
    },
    {
      icon: <SettingOutlined className="notificationItemIcon" />,
      title: "My Profile",
      // class: "alertNotification",
      link: "employees/account",
    },
    {
      icon: <PoweroffOutlined className="notificationItemIcon" />,
      title: "Logout",
      class: "logoutProfileSetting",
      link: "logout",
    },
  ];

  let responseObj = localStorage.getItem("token");
  if (responseObj) {
    responseObj = JSON.parse(Buffer.from(responseObj, "base64").toString());
  }

  let role = null;
  if (responseObj?.employeeData) {
    role = responseObj?.employeeData?.Role;
  }

  return (
    <div
      className="notificationBox"
      style={
        type === "displayNotifications"
          ? {
              left: `${positionY - 210}px`,
            }
          : null
      }
    >
      {type === "displayNotifications"
        ? notificationItems.map((notificationItem, index) => {
            return (
              <Link
                to={notificationItem?.link}
                className="notificationItemLink"
                key={index}
              >
                <div
                  className={
                    notificationItem?.class
                      ? "notificationItem " + notificationItem?.class
                      : "notificationItem"
                  }
                >
                  {notificationItem?.icon}
                  <span className="notificationItemTitle">
                    {notificationItem?.title}
                  </span>
                </div>
              </Link>
            );
          })
        : type === "displayProfileDetails"
        ? profileItems.map((notificationItem, index) => {
            return notificationItem?.title === "Profile Details" ? (
              <Link className="notificationItemLink" key={index}>
                <div
                  className={
                    notificationItem?.class
                      ? "notificationItem " + notificationItem?.class
                      : "notificationItem"
                  }
                >
                  <span className="notificationItemTitle">Welcome</span>
                  <span className="notificationItemTitle">{role}</span>
                </div>
              </Link>
            ) : (
              <Link
                to={notificationItem?.link}
                className="notificationItemLink"
                key={index}
              >
                <div
                  className={
                    notificationItem?.class
                      ? "notificationItem " + notificationItem?.class
                      : "notificationItem"
                  }
                >
                  {notificationItem?.icon}
                  <span className="notificationItemTitle">
                    {notificationItem?.title}
                  </span>
                </div>
              </Link>
            );
          })
        : null}
    </div>
  );
};

export default NotificationBox;
