const DataFilter = (tableData, filterAttribute, filterSubAttributes = null) => {
  // console.log(tableData, filterAttribute, filterSubAttributes);
  let filters = [];
  if (tableData) {
    let uniqueFiltersIndex = [];
    let uniqueFilterObjects = [];

    if (!filterSubAttributes) {
      tableData.forEach((record, index) => {
        for (let [key, value] of Object.entries(record)) {
          if (key === filterAttribute) {
            if (!uniqueFiltersIndex.includes(value)) {
              let filterObject = {};
              filterObject.text = value;
              filterObject.value = value;
              uniqueFilterObjects.push(filterObject);
              uniqueFiltersIndex.push(value);
            }
          }
        }
      });
      filters = uniqueFilterObjects;
    } else {
      filterSubAttributes.forEach((filterSubAttribute, index) => {
        let subFilterObject = {};
        subFilterObject.text = filterSubAttribute;
        subFilterObject.value = filterSubAttribute;
        tableData.forEach((record, index) => {
          for (let [key, value] of Object.entries(record)) {
            if (key === filterAttribute) {
              if (
                typeof value === "object" &&
                !Array.isArray(value) &&
                value !== null
              ) {
                for (let [key2, value2] of Object.entries(value)) {
                  if (key2 === filterSubAttribute) {
                    if (!uniqueFiltersIndex.includes(value2)) {
                      let subFilterObjectChild = {};
                      subFilterObjectChild.text = value2;
                      subFilterObjectChild.value = value2;
                      uniqueFilterObjects.push(subFilterObjectChild);
                      uniqueFiltersIndex.push(value2);
                    }
                  }
                }
              }
            }
          }
        });
        subFilterObject.children = uniqueFilterObjects;
        filters.push(subFilterObject);
        uniqueFiltersIndex = [];
        uniqueFilterObjects = [];
      });
    }

    if (!filterSubAttributes) {
    }
  }

  return filters;
};

function TableSearch(searchQuery, tableData) {
  let searchData = [];
  for (let i = 0; i < tableData.length; i++) {
    let record = tableData[i];
    for (let [key, value] of Object.entries(record)) {
      if (
        typeof value === "string" &&
        value.toLowerCase().includes(searchQuery.toLowerCase())
      ) {
        searchData.push(record);
        break;
      }

      if (typeof value === "number" && value.toString().includes(searchQuery)) {
        searchData.push(record);
        break;
      }
    }
  }
  return searchData;
}

export { DataFilter, TableSearch };
