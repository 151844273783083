import React, { useState } from "react";
import { Link } from "react-router-dom";
import NotificationBox from "../notificationBox/NotificationBox";
import { UserOutlined, BellOutlined } from "@ant-design/icons";
import "./TopBar.scss";

function TopBar({
  disableLink = false,
  clickElementClassName = null,
  setLogoClicked = null,
}) {
  const [displayNotifications, setDisplayNotifications] = useState(false);
  const [notificationsComponentPostions, setNotificationsComponentPostions] =
    useState([]);
  const [displayProfileDetails, setDisplayProfileDetails] = useState(false);

  if (displayNotifications && clickElementClassName) {
    if (
      !clickElementClassName.includes("SVGAnimatedString") &&
      !clickElementClassName.includes("notificationBell")
    ) {
      setDisplayNotifications(false);
    }
  }

  if (displayProfileDetails && clickElementClassName) {
    if (
      !clickElementClassName.includes("SVGAnimatedString") &&
      !clickElementClassName.includes("userProfile")
    ) {
      setDisplayProfileDetails(false);
    }
  }

  return (
    <div className="topBar">
      <div
        className="logoContainer"
        onClick={setLogoClicked ? () => setLogoClicked(true) : null}
      >
        <Link to={disableLink ? "" : "/"}>
          <div className="appLogo"></div>
        </Link>
      </div>

      <div className="topBarItems">
        <div
          className={
            displayNotifications
              ? "notificationBell active"
              : "notificationBell"
          }
          onClick={(e) => {
            setDisplayNotifications(true);
            setDisplayProfileDetails(false);
            setNotificationsComponentPostions(e.target.getBoundingClientRect());
          }}
        >
          <BellOutlined />
        </div>

        <div
          className={
            displayProfileDetails ? "userProfile active" : "userProfile"
          }
          onClick={() => {
            setDisplayProfileDetails(true);
            setDisplayNotifications(false);
          }}
        >
          <UserOutlined />
        </div>

        {(displayNotifications || displayProfileDetails) && (
          <NotificationBox
            type={
              displayNotifications
                ? "displayNotifications"
                : displayProfileDetails
                ? "displayProfileDetails"
                : null
            }
            positions={
              displayNotifications ? notificationsComponentPostions : null
            }
          />
        )}
      </div>
    </div>
  );
}

export default TopBar;
