import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import Home from "../../pages/Home/Home";
import ModalComponent from "../../components/modalComponent/ModalComponent";
import { useDispatch } from "react-redux";
import { hideLoading, showLoading } from "../../redux/LoaderSlice";

function Logout() {
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [logoutStatus, setLogoutStatus] = useState(false);

  function confirmLogout() {
    setLogoutStatus(true);
    localStorage.removeItem("token");
  }

  function confirmCancel() {
    window.location.href = "/";
  }

  useEffect(() => {
    dispatch(showLoading());
    setTimeout(() => {
      dispatch(hideLoading());
    }, 500);
    setOpenModal(true);
  }, []);

  return (
    <>
      <Home />
      <ModalComponent
        openModal={openModal}
        setOpenModal={setOpenModal}
        width={"30%"}
        title={"Logout"}
        message={"Are you sure you want to Logout?"}
        action={confirmLogout}
        exitAction={confirmCancel}
      />
      {logoutStatus && <Navigate to="/login" replace={true} />}
    </>
  );
}

export default Logout;
