import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import bcryptjs from "bcryptjs";
import { showLoading, hideLoading } from "../../redux/LoaderSlice";
import ApiService from "../../services/ApiService";
import { FormatInput } from "../../services/DataProcessor";
import ErrorValidation from "../../services/ErrorValidation";
import { PasswordRegex } from "../../static/RegexPattern";
import TopBar from "../../components/topBar/TopBar";
import { Form, Input, Button, Row, Col } from "antd";
import { SecurityScanOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import toast from "react-hot-toast";
import "./ResetPassword.scss";

dayjs.extend(customParseFormat);

const ResetPassword = ({ type = null }) => {
  const { passwordResetLink } = useParams();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [employeesData, setEmployeesData] = useState([]);
  const [employeeID, setEmployeeID] = useState(null);
  const [dataIsReady, setDataIsReady] = useState(false);
  var Buffer = require("buffer/").Buffer;

  let apiURL = "/employees/";

  let decodedEntityID = null;

  if (type === "edit") {
    decodedEntityID = Buffer.from(passwordResetLink, "base64").toString();
  }

  const getEmployeesData = async () => {
    dispatch(showLoading());
    const apiResponse = await ApiService("get", apiURL);
    const apiData = apiResponse?.response;
    if (apiData) {
      let employeeExists = false;
      let invalidLink = false;

      apiData.forEach((record) => {
        if (record?.AccessResetLink !== "") {
          if (
            passwordResetLink !== "" &&
            passwordResetLink === record?.AccessResetLink
          ) {
            employeeExists = true;
            let date1 = dayjs(new Date());
            let date2 = dayjs(record?.AccessLinkValidity);
            let diff = date1.diff(date2, "minute");
            if (diff <= 15) {
              setEmployeeID(record?._id);
              setDataIsReady(true);
              dispatch(hideLoading());
            } else {
              toast.error(
                "Password reset link has expired! Please request a new reset link..."
              );
              invalidLink = true;
            }
          }
        }
      });

      if (!employeeExists) {
        toast.error("Password reset link is invalid!");
        invalidLink = true;
      }

      if (invalidLink) {
        navigate("../login");
      }
    } else {
      const apiError = apiResponse?.error;
      let errorMessage = ErrorValidation(apiError?.response?.status);
      toast.error(errorMessage);
    }
  };

  const onFormSubmit = async (values) => {
    if (values && values?.newPassword === values?.confirmPassword) {
      const salt = await bcryptjs.genSalt(10);
      const hashedPassword = await bcryptjs.hash(values?.newPassword, salt);
      let requestObj = {};
      requestObj["Access"] = hashedPassword;
      requestObj["AccessResetLink"] = "";

      if (type === "edit") {
        apiURL = "/employees/" + decodedEntityID;
      } else {
        apiURL = "/employees/" + employeeID;
      }

      dispatch(showLoading());
      const apiResponse = await ApiService("put", apiURL, requestObj);
      const apiData = apiResponse?.response;
      if (apiData) {
        setEmployeesData(apiData);
        setDataIsReady(true);
        dispatch(hideLoading());
        toast.success("Password was reset Successfully!");
        if (!type) {
          navigate("../login");
        }
      } else {
        const apiError = apiResponse?.error;
        let errorMessage = ErrorValidation(apiError?.response?.status);
        toast.error(errorMessage);
        dispatch(hideLoading());
      }
    }
  };

  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    let responseObj = localStorage.getItem("token");
    if (responseObj) {
      responseObj = JSON.parse(Buffer.from(responseObj, "base64").toString());
    }

    let empStatus = responseObj?.employeeLoginStatus;

    if (!type && !empStatus) {
      getEmployeesData();
    } else {
      if (type && empStatus) {
        setEmployeeID(decodedEntityID);
        setDataIsReady(true);
        dispatch(hideLoading());
      } else {
        toast.error("User has already logged in!");
        navigate("/");
      }
    }
  }, []);

  return (
    <>
      {dataIsReady && (
        <div
          className={
            type === "edit"
              ? "resetPasswordContainer edit"
              : "resetPasswordContainer"
          }
        >
          {!type && <TopBar disableLink={true} />}
          <div className="resetPassword">
            <div className="headerStart">
              {type === "edit" && (
                <ArrowLeftOutlined
                  className="backArrowButton"
                  onClick={() => {
                    navigate("../../employees/account");
                  }}
                />
              )}
              <h2 className="resetPasswordTitle">Reset Your Password</h2>
            </div>
            <p>
              Strong Passwords include numbers, lowercase & uppercase letters &
              special symbols
            </p>
            <Form
              form={form}
              className="resetPasswordForm"
              layout="vertical"
              onFinish={onFormSubmit}
              onFinishFailed={onFinishFailed}
            >
              <div className="resetPasswordFormFields">
                <Row
                  gutter={{
                    xs: 8,
                    sm: 16,
                    md: 24,
                    lg: 32,
                  }}
                >
                  <Col className="gutter-row" span={24}>
                    <Form.Item
                      label="Enter new Password"
                      name="newPassword"
                      rules={[
                        {
                          required: true,
                          message: "Enter new Password",
                        },
                        {
                          pattern: PasswordRegex,
                          message: "Password does not match criteria",
                        },
                      ]}
                      onBlur={(e) => FormatInput(e, form)}
                    >
                      <Input.Password
                        prefix={
                          <SecurityScanOutlined className="site-form-item-icon" />
                        }
                        placeholder="Enter new Password"
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row
                  gutter={{
                    xs: 8,
                    sm: 16,
                    md: 24,
                    lg: 32,
                  }}
                >
                  <Col className="gutter-row" span={24}>
                    <Form.Item
                      label="Confirm new Password"
                      name="confirmPassword"
                      rules={[
                        {
                          validator: (_, value) => {
                            let newPassword = form.getFieldValue("newPassword");
                            if (newPassword === value) {
                              return Promise.resolve();
                            } else {
                              return Promise.reject(
                                "Passwords does not match!"
                              );
                            }
                          },
                          message: "Passwords do not match!",
                        },
                        {
                          required: true,
                          message: "Confirm new Password",
                        },
                        {
                          pattern: PasswordRegex,
                          message: "Password does not match criteria",
                        },
                      ]}
                      onBlur={(e) => FormatInput(e, form)}
                    >
                      <Input.Password
                        prefix={
                          <SecurityScanOutlined className="site-form-item-icon" />
                        }
                        placeholder="Confirm new Password"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
              <Button
                className="resetPasswordFormButton"
                type="primary"
                htmlType="submit"
              >
                Reset Password
              </Button>
            </Form>

            {/* )} */}

            {/* {setupComplete && <Navigate to="" replace={true} />} */}
          </div>
        </div>
      )}
    </>
  );
};

export default ResetPassword;
