import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { showLoading, hideLoading } from "../../../redux/LoaderSlice";
import ApiService from "../../../services/ApiService";
import {
  FormObjectProcessor,
  FormatInput,
} from "../../../services/DataProcessor";
import ErrorValidation from "../../../services/ErrorValidation";
import {
  AlphabetRegex,
  PhoneRegex,
  PinCodeRegex,
} from "../../../static/RegexPattern";
import {
  Breadcrumb,
  Form,
  Input,
  Select,
  Button,
  Row,
  Col,
  DatePicker,
} from "antd";
import dayjs from "dayjs";
import { ArrowLeftOutlined } from "@ant-design/icons";
import toast from "react-hot-toast";
import "./EmployeeFormPage.scss";

const dateFormat = "DD-MM-YYYY";

const EmployeeFormPage = ({ type }) => {
  const [form] = Form.useForm();
  const { employeeID } = useParams();
  var Buffer = require("buffer/").Buffer;
  const decodedEmployeeIDString = Buffer.from(employeeID, "base64").toString();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [authorized, setAuthorized] = useState(false);
  const [access, setAccess] = useState(false);
  const [employeeData, setEmployeeData] = useState([]);
  const [dataIsReady, setDataIsReady] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [roleType, setRoleType] = useState("");

  const dropDownOptions = [
    { value: "disabled", label: "Select a Role Type", disabled: true },
    { value: "Admin", label: "Admin" },
    { value: "Accountant", label: "Accountant" },
    { value: "CRM", label: "CRM" },
    { value: "HR", label: "HR" },
  ];

  const onSelectChange = (value) => {
    setRoleType(value);
  };

  let responseObj = localStorage.getItem("token");
  if (responseObj) {
    responseObj = JSON.parse(Buffer.from(responseObj, "base64").toString());
  }
  let role = null;
  let currentEmployeeID = null;
  if (responseObj?.employeeData) {
    role = responseObj?.employeeData?.Role;
    currentEmployeeID = responseObj?.employeeData?._id;
  }

  const apiURL = "/employees/" + decodedEmployeeIDString;

  const getEmployeeData = async () => {
    dispatch(showLoading());
    const apiResponse = await ApiService("get", apiURL, null);
    const apiData = apiResponse?.response;
    if (apiData) {
      setEmployeeData(apiData);
      setDataIsReady(true);
      dispatch(hideLoading());
    } else {
      const apiError = apiResponse?.error;
      let errorMessage = ErrorValidation(apiError?.response?.status);
      toast.error(errorMessage);
    }
  };

  const onFormSubmit = async (values) => {
    // console.log(values);
    setEditMode(false);
    if (values) {
      let requestObj = FormObjectProcessor(values);
      dispatch(showLoading());
      const apiResponse = await ApiService("put", apiURL, requestObj);
      const apiData = apiResponse?.response;
      // console.log(apiData);
      if (apiData) {
        setEmployeeData(apiData);
        setDataIsReady(true);
        dispatch(hideLoading());
        toast.success("Profile Data Saved Successfully!");
        setRoleType(employeeData?.Role);
      } else {
        const apiError = apiResponse?.error;
        // let errorMessage = ErrorValidation(apiError?.response?.status);
        let errorMessage = "No Changes were detected!";
        toast.error(errorMessage);
        dispatch(hideLoading());
      }
    }
  };

  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
  };

  const validateUserRole = () => {
    let validatedUserRole = false;

    if (role === "Admin" && currentEmployeeID === decodedEmployeeIDString) {
      validatedUserRole = true;
      setAuthorized(true);
    }

    if (role === "Admin" && employeeData?.Role !== "Admin") {
      validatedUserRole = true;
      setAuthorized(true);
    }

    if (currentEmployeeID === decodedEmployeeIDString) {
      validatedUserRole = true;
      setAccess(true);
    }

    return validatedUserRole;
  };

  useEffect(() => {
    getEmployeeData();
  }, []);

  return (
    <>
      {dataIsReady && (
        <div className="employeeFormContainer">
          <div className="employeeFormDiv">
            <Breadcrumb
              className="breadCrumb"
              items={[
                {
                  title: <a href="../../employees">Employees</a>,
                },
                {
                  title: "Employee Details",
                },
              ]}
            />
            <div className="header">
              <div className="headerStart">
                <ArrowLeftOutlined
                  className="backArrowButton"
                  onClick={() => {
                    navigate("/employees");
                  }}
                />
                <h3 className="employeeFormTitle">
                  {employeeData
                    ? employeeData?.Name?.FNAME +
                      " " +
                      employeeData?.Name?.LName
                    : "Add Employee"}
                </h3>
              </div>

              {!editMode && (
                <Button
                  className="employeeFormButton"
                  type="primary"
                  onClick={() => {
                    let validatedUserRole = validateUserRole();
                    if (validatedUserRole) {
                      setEditMode(true);
                      dispatch(showLoading());
                      setTimeout(() => {
                        dispatch(hideLoading());
                      }, 1000);
                    } else {
                      toast.error("Unauthorized Access!");
                    }
                  }}
                  // disabled={access ? false : true}
                >
                  EDIT
                </Button>
              )}

              {editMode && (
                <div>
                  <Button
                    className="employeeFormButton cancel"
                    danger
                    onClick={() => {
                      dispatch(showLoading());
                      setEditMode(false);
                      dispatch(hideLoading());
                    }}
                  >
                    CANCEL
                  </Button>

                  <Button
                    className="employeeFormButton"
                    type="primary"
                    htmlType={access ? "submit" : null}
                    onClick={() => {
                      form.submit();
                      dispatch(showLoading());
                      setTimeout(() => {
                        dispatch(hideLoading());
                      }, 500);
                    }}
                    disabled={access || authorized ? false : true}
                  >
                    SAVE
                  </Button>
                </div>
              )}
            </div>
            <Form
              form={form}
              className="employeeForm"
              layout="vertical"
              onFinish={onFormSubmit}
              onFinishFailed={onFinishFailed}
            >
              <div className="employeeFormFields">
                <Row
                  gutter={{
                    xs: 8,
                    sm: 16,
                    md: 24,
                    lg: 32,
                  }}
                >
                  <Col className="gutter-row" span={6}>
                    <Form.Item
                      label="Email"
                      name="Email"
                      initialValue={employeeData?.Email}
                      rules={[
                        {
                          type: "email",
                          message: "Please enter a valid email!",
                        },
                        {
                          required: true,
                          message: "Please enter your email",
                        },
                        {
                          max: 50,
                          message:
                            "Email exceeded permitted length! Please try again...",
                        },
                      ]}
                      onBlur={(e) => FormatInput(e, form, "email")}
                    >
                      <Input
                        placeholder="Email"
                        disabled={authorized ? false : true}
                      />
                    </Form.Item>
                  </Col>
                  <Col className="gutter-row" span={6}>
                    <Form.Item
                      label="Role"
                      name="Role"
                      initialValue={employeeData?.Role}
                      rules={[
                        {
                          required: true,
                          message: "Please select a role",
                        },
                      ]}
                    >
                      <Select
                        options={dropDownOptions}
                        defaultValue={employeeData?.Role}
                        value={roleType}
                        onChange={onSelectChange}
                        disabled={authorized ? false : true}
                      />
                    </Form.Item>
                  </Col>
                  <Col className="gutter-row" span={6}>
                    <Form.Item
                      label="First Name"
                      name="Name-FNAME"
                      initialValue={employeeData?.Name?.FNAME}
                      rules={[
                        {
                          required: true,
                          message: "Enter First Name",
                        },
                        {
                          pattern: AlphabetRegex,
                          message: "Enter valid First Name",
                        },
                        {
                          max: 50,
                          message: "First Name limit exceeded!",
                        },
                      ]}
                      onBlur={(e) => FormatInput(e, form)}
                    >
                      <Input
                        placeholder="Example: Raj"
                        disabled={editMode ? false : true}
                      />
                    </Form.Item>
                  </Col>
                  <Col className="gutter-row" span={6}>
                    <Form.Item
                      label="Last Name"
                      name="Name-LName"
                      initialValue={employeeData?.Name?.LName}
                      rules={[
                        {
                          required: true,
                          message: "Enter Last Name",
                        },
                        {
                          pattern: AlphabetRegex,
                          message: "Enter valid Last Name",
                        },
                        {
                          max: 50,
                          message: "Last Name limit exceeded!",
                        },
                      ]}
                      onBlur={(e) => FormatInput(e, form)}
                    >
                      <Input
                        placeholder="Example: Kumar"
                        disabled={editMode ? false : true}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row
                  gutter={{
                    xs: 8,
                    sm: 16,
                    md: 24,
                    lg: 32,
                  }}
                >
                  <Col className="gutter-row" span={6}>
                    <Form.Item
                      label="Date of Joining"
                      name="JoiningDate"
                      rules={[
                        {
                          required: true,
                          message: "Select Joining Date",
                        },
                      ]}
                      initialValue={dayjs(employeeData?.JoiningDate)}
                    >
                      <DatePicker
                        placeholder="Select Joining Date"
                        disabled={authorized ? false : true}
                      />
                    </Form.Item>
                  </Col>

                  <Col className="gutter-row" span={6}>
                    <Form.Item
                      label="Primary Phone Number"
                      name="Phone-Primary"
                      initialValue={employeeData?.Phone?.Primary}
                      rules={[
                        {
                          required: true,
                          message: "Enter Phone number",
                        },
                        {
                          pattern: PhoneRegex,
                          message: "Enter valid Phone number",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Primary Phone Number"
                        disabled={editMode ? false : true}
                      />
                    </Form.Item>
                  </Col>
                  <Col className="gutter-row" span={6}>
                    <Form.Item
                      label="Alternate Phone Number"
                      name="Phone-Alternate"
                      initialValue={employeeData?.Phone?.Alternate}
                      rules={[
                        {
                          pattern: PhoneRegex,
                          message: "Enter valid Phone number",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Alternate Phone Number"
                        disabled={editMode ? false : true}
                      />
                    </Form.Item>
                  </Col>
                  <Col className="gutter-row" span={6}>
                    <Form.Item
                      label="Street Address"
                      name="Address-Street"
                      initialValue={employeeData?.Address?.Street}
                      rules={[
                        {
                          required: true,
                          message: "Enter Street Address",
                        },
                        {
                          max: 50,
                          message: "Street Address limit exceeded!",
                        },
                      ]}
                      onBlur={(e) => FormatInput(e, form)}
                    >
                      <Input
                        placeholder="Street Address"
                        disabled={editMode ? false : true}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row
                  gutter={{
                    xs: 8,
                    sm: 16,
                    md: 24,
                    lg: 32,
                  }}
                >
                  <Col className="gutter-row" span={6}>
                    <Form.Item
                      label="City / Town / Village"
                      name="Address-City"
                      initialValue={employeeData?.Address?.City}
                      rules={[
                        {
                          required: true,
                          message: "Enter City Address",
                        },
                        {
                          max: 50,
                          message: "City Address limit exceeded!",
                        },
                        {
                          pattern: AlphabetRegex,
                          message: "Enter valid City Address",
                        },
                      ]}
                      onBlur={(e) => FormatInput(e, form)}
                    >
                      <Input
                        placeholder="City"
                        disabled={editMode ? false : true}
                      />
                    </Form.Item>
                  </Col>

                  <Col className="gutter-row" span={6}>
                    <Form.Item
                      label="Taluka / Subdivision"
                      name="Address-Taluka"
                      initialValue={employeeData?.Address?.Taluka}
                      rules={[
                        {
                          required: true,
                          message: "Enter Taluka Address",
                        },
                        {
                          max: 50,
                          message: "Taluka Address limit exceeded!",
                        },
                        {
                          pattern: AlphabetRegex,
                          message: "Enter valid Taluka Address",
                        },
                      ]}
                      onBlur={(e) => FormatInput(e, form)}
                    >
                      <Input
                        placeholder="Taluka"
                        disabled={editMode ? false : true}
                      />
                    </Form.Item>
                  </Col>

                  <Col className="gutter-row" span={6}>
                    <Form.Item
                      label="District"
                      name="Address-District"
                      initialValue={employeeData?.Address?.District}
                      rules={[
                        {
                          required: true,
                          message: "Enter District Address",
                        },
                        {
                          max: 50,
                          message: "District Address limit exceeded!",
                        },
                        {
                          pattern: AlphabetRegex,
                          message: "Enter valid District Address",
                        },
                      ]}
                      onBlur={(e) => FormatInput(e, form)}
                    >
                      <Input
                        placeholder="District"
                        disabled={editMode ? false : true}
                      />
                    </Form.Item>
                  </Col>

                  <Col className="gutter-row" span={6}>
                    <Form.Item
                      label="State"
                      name="Address-State"
                      initialValue={employeeData?.Address?.State}
                      rules={[
                        {
                          required: true,
                          message: "Enter State Address",
                        },
                        {
                          max: 50,
                          message: "State Address limit exceeded!",
                        },
                        {
                          pattern: AlphabetRegex,
                          message: "Enter valid State Address",
                        },
                      ]}
                      onBlur={(e) => FormatInput(e, form)}
                    >
                      <Input
                        placeholder="State"
                        disabled={editMode ? false : true}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row
                  gutter={{
                    xs: 8,
                    sm: 16,
                    md: 24,
                    lg: 32,
                  }}
                >
                  <Col className="gutter-row" span={6}>
                    <Form.Item
                      label="Pincode"
                      name="Address-PinCode"
                      initialValue={employeeData?.Address?.PinCode}
                      rules={[
                        {
                          required: true,
                          message: "Enter Pincode",
                        },
                        {
                          pattern: PinCodeRegex,
                          message: "Enter valid PinCode",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Pin Code"
                        disabled={editMode ? false : true}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            </Form>
          </div>
        </div>
      )}
    </>
  );
};

export default EmployeeFormPage;
