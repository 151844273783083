import React, { useState } from "react";
import { Form, Input, Select, Button, Row, Col, DatePicker } from "antd";
import { PasswordRegex } from "../../static/RegexPattern";
import { FormatInput } from "../../services/DataProcessor";
import {
  UserOutlined,
  MailOutlined,
  SecurityScanOutlined,
  FilePdfOutlined,
} from "@ant-design/icons";
import "./ModalForm.scss";
import axios from "axios";
import toast from "react-hot-toast";
import { EncodeString } from "../../services/TableUtilities";
import { FRONTEND_URL, IMAGE_SERVER_URL } from "../../App";
import ErrorValidation from "../../services/ErrorValidation";
import ApiService, { ImageService } from "../../services/ApiService";

function ModalForm({ type, title, action, exitAction, staffID = null }) {
  const [form] = Form.useForm();

  //Add Employee Form
  const [roleType, setRoleType] = useState("");
  const addEmployeeSelectOptions = [
    { value: "disabled", label: "Select a Role", disabled: true },
    { value: "Admin", label: "Admin" },
    { value: "Accountant", label: "Accountant" },
    { value: "CRM", label: "CRM" },
    { value: "HR", label: "HR" },
  ];

  const onSelectChange = (value) => {
    setRoleType(value);
  };

  const onFinish = async (values) => {
    if (values) {
      action(values);
      form.resetFields();
    }
  };

  //Upload Staff Documents
  const [uploadedImageFile, setUploadedImageFile] = useState(null);
  const [uploadedPDFFile, setUploadedPDFFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState({
    started: false,
    pc: 0,
  });

  const checkUploadedPDFFileType = async (type) => {
    if (uploadedPDFFile) {
      let fileExtension = uploadedPDFFile?.type?.split("/")[1]?.toString();
      if (fileExtension.toString() === type) {
        uploadImageHandler();
      } else {
        toast.error("Invalid File Type... Upload Failed!");
      }
    } else {
      toast.error("No file selected!");
    }
  };

  const checkUploadedNonPDFFileType = async () => {
    if (uploadedImageFile) {
      let fileExtension = uploadedImageFile?.type?.split("/")[1]?.toString();

      if (
        fileExtension === "jpg" ||
        fileExtension === "jpeg" ||
        fileExtension === "png"
      ) {
        uploadImageHandler();
      } else {
        toast.error("Invalid File Type... Upload Failed!");
      }
    } else {
      toast.error("No file selected!");
    }
  };

  const uploadImageHandler = async () => {
    if (uploadedImageFile || uploadedPDFFile) {
      let uploadedFile = null;
      if (uploadedImageFile) {
        uploadedFile = uploadedImageFile;
      }

      if (uploadedPDFFile) {
        uploadedFile = uploadedPDFFile;
      }

      let fileExtension = uploadedFile?.type?.split("/")[1]?.toString();

      if (
        fileExtension === "jpg" ||
        fileExtension === "jpeg" ||
        fileExtension === "png" ||
        fileExtension === "pdf"
      ) {
        let fileSize = uploadedFile?.size;
        if (fileSize < 1048576) {
          const fd = new FormData();
          let regex = new RegExp("=", "g");
          let filename = EncodeString(staffID).toLowerCase();
          filename = filename.replace(regex, "");
          let modifiedFile = new File(
            [uploadedFile],
            filename + "." + fileExtension
          );
          fd.append("file", modifiedFile);
          setUploadProgress((prevState) => {
            return { ...prevState, started: true };
          });

          let savedFileName = null;
          let requestObj = null;

          savedFileName = await ImageService(fd);
          if (savedFileName) {
            toast.success("File Uploaded Successfully!");
            requestObj = await getStaffData();
            if (requestObj && savedFileName) {
              await updateStaff(requestObj, fileExtension, savedFileName);
            } else {
              toast.error("Something went wrong... Please try again...");
            }
          } else {
            toast.error("Upload Failed! Please try again...");
          }
        } else {
          toast.error("File exceeds limit of 1MB... Upload Failed!");
        }
      } else {
        toast.error("Invalid File Type... Upload Failed!");
      }
    } else {
      toast.error("No file selected!");
    }
  };

  const getStaffData = async () => {
    let apiURL = "/staff/" + staffID;
    let requestObj = null;
    const apiResponse = await ApiService("get", apiURL);
    const apiData = apiResponse?.response;
    if (apiData) {
      requestObj = apiData;
    } else {
      const apiError = apiResponse?.error;
      let errorMessage = ErrorValidation(apiError?.response?.status);
      toast.error(errorMessage);
    }

    return requestObj;
  };
  const updateStaff = async (requestObj, fileExtension, savedFileName) => {
    if (fileExtension.toString() === "pdf") {
      requestObj["IdentityProof"] = savedFileName;
    } else {
      requestObj["ProfileImage"] = savedFileName;
    }
    let apiURL = "/staff/" + staffID;
    const apiResponse = await ApiService("put", apiURL, requestObj);
    // console.log(`HereupdateStaff`, apiURL, requestObj);
    const apiData = apiResponse?.response;
    if (apiData) {
      toast.success("Staff Data updated successfully");
    } else {
      const apiError = apiResponse?.error;
      let errorMessage = ErrorValidation(apiError?.response?.status);
      // toast.error(errorMessage);
    }
  };

  return (
    <>
      {type === "Add Employee" ? (
        <div className="modalForm">
          <h3 className="titleText">{title}</h3>
          <Form
            form={form}
            className="addEmployeeForm"
            layout="vertical"
            onFinish={onFinish}
          >
            <div className="employeeAccountFormFields">
              <Form.Item
                label="Email"
                name="Email"
                rules={[
                  {
                    type: "email",
                    message: "Please enter a valid email!",
                  },
                  {
                    required: true,
                    message: "Please enter your email",
                  },
                  {
                    max: 50,
                    message:
                      "Email exceeded permitted length! Please try again...",
                  },
                ]}
                onBlur={(e) => FormatInput(e, form, "email")}
                hasFeedback
              >
                <Input
                  prefix={<MailOutlined className="site-form-item-icon" />}
                  placeholder="Enter Email Address"
                />
              </Form.Item>

              <Form.Item
                label="Role"
                name="Role"
                rules={[
                  {
                    required: true,
                    message: "Please select a Role",
                  },
                ]}
                hasFeedback
              >
                <Select
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  defaultValue="disabled"
                  options={addEmployeeSelectOptions}
                  value={roleType}
                  onChange={onSelectChange}
                />
              </Form.Item>

              <Form.Item
                label="Date of Joining"
                name="JoiningDate"
                rules={[
                  {
                    required: true,
                    message: "Select Joining Date",
                  },
                ]}
              >
                <DatePicker placeholder="Select Joining Date" />
              </Form.Item>

              <Form.Item
                label="Password"
                name="Password"
                rules={[
                  {
                    required: true,
                    message: "Please enter a Password",
                  },
                  {
                    pattern: PasswordRegex,
                    message: "Password does not match criteria!",
                  },
                ]}
                onBlur={(e) => FormatInput(e, form)}
              >
                <Input.Password
                  prefix={
                    <SecurityScanOutlined className="site-form-item-icon" />
                  }
                  placeholder="Enter a  Password"
                />
              </Form.Item>
            </div>

            <div className="addEmployeeFormButton">
              <Button
                className="addEmployeeFormButton"
                type="primary"
                htmlType="submit"
              >
                ADD EMPLOYEE
              </Button>
              <br />
              <Button
                danger
                className="cancelEmployeeFormButton"
                onClick={() => {
                  form.resetFields();
                  exitAction();
                }}
              >
                CANCEL
              </Button>
            </div>
          </Form>
        </div>
      ) : type === "Upload Documents" ? (
        <div className="modalForm">
          <h3 className="titleText">{title}</h3>
          <div className="uploadImagePlaceholder">
            <div className="iconsBar">
              <img
                className="uploadImageIcon"
                src={`${FRONTEND_URL}/assets/user.png`}
                alt="upload_image"
              />
              <FilePdfOutlined className="pdfIcon" />
            </div>

            {/* {uploadProgress.started &&
              setTimeout(() => {
                <div className="progressBar">
                  <progress max="100" value={uploadProgress.pc}></progress>
                  <span>{uploadProgress.pc}%</span>
                </div>;
              }, 500)} */}

            <div className="uploadBarDiv">
              <input
                type="file"
                onChange={(e) => {
                  setUploadedImageFile(e.target.files[0]);
                }}
              />
              <Button
                className="uploadImageButton"
                type="primary"
                onClick={(e) => {
                  checkUploadedNonPDFFileType();
                }}
              >
                Upload Profile Image
              </Button>
            </div>

            <div className="uploadBarDiv">
              <input
                type="file"
                onChange={(e) => {
                  setUploadedPDFFile(e.target.files[0]);
                }}
              />
              <Button
                className="uploadImageButton"
                type="primary"
                onClick={(e) => {
                  checkUploadedPDFFileType("pdf");
                }}
              >
                Upload Identity Proof
              </Button>
            </div>
          </div>
          <div className="uploadFormButtons">
            <br />
            <Button
              danger
              className="cancelUploadFormButton"
              onClick={() => {
                setUploadedImageFile(null);
                setUploadedPDFFile(null);
                exitAction();
              }}
            >
              CANCEL
            </Button>
          </div>
        </div>
      ) : null}
    </>
  );
}

export default ModalForm;
