import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { hideLoading, showLoading } from "../../../redux/LoaderSlice";
import bcryptjs from "bcryptjs";
import ApiService from "../../../services/ApiService";
import ErrorValidation from "../../../services/ErrorValidation";
import { DataProcessor } from "../../../services/DataProcessor";
import { DataFilter, TableSearch } from "../../../services/DataFilter";
import { EncodeString } from "../../../services/TableUtilities";
import { Table, Input, Button, Card, Space } from "antd";
import { MoreOutlined, UnorderedListOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import TableActionComponent from "../../../components/tableActionComponent/TableActionComponent";
import ModalComponent from "../../../components/modalComponent/ModalComponent";
import ModalForm from "../../../components/modalForm/ModalForm";
import toast from "react-hot-toast";
import "./EmployeesTablePage.scss";

const { Search } = Input;
const dateFormat = "DD-MM-YYYY";

const EmployeesTablePage = ({ entity }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const Buffer = require("buffer/").Buffer;
  const [dataIsReady, setDataIsReady] = useState(false);
  const [entityTableFilters, setEntityTableFilters] = useState([]);
  const [filterIsReady, setFilterIsReady] = useState(false);
  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const [tableData, setTableData] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [currentRow, setCurrentRow] = useState(null);
  const [displayTableActionComponent, setDisplayTableActionComponent] =
    useState(false);
  const [tableActionComponentPostions, setTableActionComponentPostions] =
    useState([]);
  const [clickedElementClassName, setClickedElementClassName] = useState(null);

  let unprocessedData = [];
  let processedData = [];

  const entityName =
    entity !== "Staff" ? entity.toLowerCase() + "s" : entity.toLowerCase();
  const entityTableFilterColumnNames = [
    "Name",
    { Address: ["City", "State"] },
    "Role",
  ];
  let encodedEntityID = null;

  let responseObj = localStorage.getItem("token");
  if (responseObj) {
    responseObj = JSON.parse(Buffer.from(responseObj, "base64").toString());
  }
  let role = null;
  let currentEmployeeID = null;
  if (responseObj?.employeeData) {
    role = responseObj?.employeeData?.Role;
    currentEmployeeID = responseObj?.employeeData?._id;
  }

  const getData = async () => {
    dispatch(showLoading());
    const apiURL = `/${entityName}/`;
    const apiResponse = await ApiService("get", apiURL);
    const apiData = apiResponse?.response;
    if (apiData) {
      unprocessedData = apiData;
      processedData = await DataProcessor(unprocessedData);
      processedData = processedData.map((item) => ({
        ...item,
        tableActionButtons: item?._id,
      }));
      setTableData(processedData);
      setEntityTableFilters(getTableFilters());
      setFilterIsReady(true);
      setDataIsReady(true);
      dispatch(hideLoading());
    } else {
      toast.error(`No ${entity} Data Found!`);
    }
  };

  const getTableFilters = () => {
    let tableFilters = [];
    entityTableFilterColumnNames.forEach((filterColumn) => {
      let tableFilter = [];
      if (
        typeof filterColumn === "object" &&
        !Array.isArray(filterColumn) &&
        filterColumn !== null
      ) {
        for (let [key, value] of Object.entries(filterColumn)) {
          tableFilter = DataFilter(unprocessedData, key, value);
          tableFilters[key] = tableFilter;
        }
      } else {
        tableFilter = DataFilter(processedData, filterColumn);
        tableFilters[filterColumn] = tableFilter;
      }
    });
    return tableFilters;
  };

  let columns = [
    // {
    //   title: "Sr. No.",
    //   dataIndex: "key",
    //   key: "key",
    //   render: (value, record) => (value += 1),
    // },
    {
      title: "Name",
      dataIndex: "Name",
      key: "Name",
      filters: filterIsReady ? entityTableFilters["Name"] : null,
      onFilter: (value, record) => onTableFilter(value, record),
      filteredValue: filteredInfo.Name || null,
      sorter: (a, b) => a.Name.length - b.Name.length,
      sortDirections: ["descend"],
      render: (value, record) => (
        (encodedEntityID = EncodeString(record?._id)),
        (
          <Link
            className="tableRowLink"
            to={`../employees/employee/${encodedEntityID}`}
          >
            {value}
          </Link>
        )
      ),
    },
    {
      title: "Email",
      dataIndex: "Email",
      key: "Email",
      sorter: (a, b) => a.Email.length - b.Email.length,
    },
    {
      title: "Role",
      dataIndex: "Role",
      key: "Role",
      filters: filterIsReady ? entityTableFilters["Role"] : null,
      onFilter: (value, record) => onTableFilter(value, record),
      filteredValue: filteredInfo.Role || null,
      sorter: (a, b) => a.Role.length - b.Role.length,
    },

    {
      title: "Phone",
      dataIndex: "Phone",
      key: "Phone",
      sorter: (a, b) => a.Phone - b.Phone,
    },

    {
      title: "Address",
      dataIndex: "Address",
      key: "Address",
      filters: filterIsReady ? entityTableFilters["Address"] : null,
      onFilter: (value, record) => onTableFilter(value, record),
      filteredValue: filteredInfo.Address || null,
    },

    {
      title: "Action",
      dataIndex: "tableActionButtons",
      key: "operation",
      render: (value, record) => (
        <div
          className="tableMenuDiv"
          onClick={(e) => {
            setDisplayTableActionComponent(!displayTableActionComponent);
            setTableActionComponentPostions(e.target.getBoundingClientRect());
            setCurrentRow(record);
          }}
        >
          <MoreOutlined className="tableMenuIcon" />
        </div>
      ),
      width: 80,
    },
  ];

  const onChange = (pagination, filters, sorter, extra) => {
    setFilteredInfo(filters);
    setSortedInfo(sorter);
  };

  const onTableSearch = (value) => {
    let searchResult = TableSearch(value, tableData);
    if (searchResult?.length > 0) {
      setSearchData(searchResult);
    } else {
      toast.error("No matching records found!");
    }
  };

  const onTableFilter = (value, record) => {
    if (record?.Name?.indexOf(value) === 0) {
      return true;
    }

    if (record?.Address?.includes(value) === true) {
      return true;
    }

    if (record?.Role?.indexOf(value) === 0) {
      return true;
    }
  };

  const clearFilters = () => {
    setFilteredInfo({});
    setSortedInfo({});
  };

  if (displayTableActionComponent && clickedElementClassName) {
    if (
      !clickedElementClassName.includes("SVGAnimatedString") &&
      !clickedElementClassName.includes("tableMenuDiv")
    ) {
      setDisplayTableActionComponent(false);
    }
  }

  const confirmFormSubmission = async (values) => {
    // console.log(`Here`, values);
    dispatch(showLoading());
    const salt = await bcryptjs.genSalt(10);
    const hashedPassword = await bcryptjs.hash(values?.Password, salt);
    let requestObject = {
      Name: {
        FNAME: "",
        LName: "",
      },
      Email: values?.Email,
      Phone: {
        Primary: "",
        Alternate: "",
      },
      Address: {
        Street: "",
        City: "",
        Taluka: "",
        District: "",
        State: "",
        PinCode: "",
      },
      Role: values?.Role,
      IsAccountSetup: false,
      JoiningDate: dayjs(values?.JoiningDate),
      Access: hashedPassword,
      AccessLinkValidity: new Date().toJSON(),
      AccessResetLink: "",
    };

    const apiURL = "/employees/";
    const apiResponse = await ApiService("post", apiURL, requestObject);
    const apiData = apiResponse?.response;
    if (apiData) {
      setOpenModal(false);
      dispatch(hideLoading());
      toast.success("Employee was added successfully!");
      getData();
    } else {
      const apiError = apiResponse?.error;
      let errorMessage = ErrorValidation(apiError?.response?.status);
      dispatch(hideLoading());
      toast.error(errorMessage);
    }
  };

  const confirmCancel = () => {
    setOpenModal(false);
  };

  const editTableRow = () => {
    let encodedEntityID = EncodeString(currentRow?._id);
    navigate("employee/" + encodedEntityID);
  };

  const deleteTableRow = async () => {
    if (tableData.length > 1 && role === "Admin") {
      let deletedEmployeeId = currentRow?._id;
      const apiURL = "/employees/" + deletedEmployeeId;
      const apiResponse = await ApiService("delete", apiURL);
      const apiData = apiResponse?.response;
      if (apiData) {
        toast.success("Employee was deleted successfully!");
        getData();
        setOpenDeleteModal(false);
        dispatch(hideLoading());
      } else {
        const apiError = apiResponse?.error;
        let errorMessage = ErrorValidation(apiError?.response?.status);
        toast.error(errorMessage);
      }
    } else {
      toast.error("Cannot delete last employee!");
    }
  };

  const validateUserRole = (type = null) => {
    let validatedUserRole = false;

    if (
      role === "Admin" &&
      currentRow?.Role === "Admin" &&
      currentEmployeeID === currentRow?._id
    ) {
      validatedUserRole = true;
    }

    if (role === "Admin" && type === "add") {
      validatedUserRole = true;
    }

    if (
      role === "Admin" &&
      currentRow?.Role === "Admin" &&
      currentEmployeeID !== currentRow?._id &&
      type === "delete"
    ) {
      validatedUserRole = true;
    }

    if (
      role === "Admin" &&
      currentRow?.Role === "Admin" &&
      currentEmployeeID === currentRow?._id &&
      type === "delete"
    ) {
      validatedUserRole = false;
    }

    if (role === "Admin" && currentRow?.Role !== "Admin") {
      validatedUserRole = true;
    }

    if (
      role !== "Admin" &&
      currentEmployeeID === currentRow?._id &&
      type !== "delete"
    ) {
      validatedUserRole = true;
    }

    return validatedUserRole;
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      {dataIsReady && (
        <div
          className="tablePage"
          onClick={(e) => {
            setClickedElementClassName(e.target.className.toString());
          }}
        >
          <div className="header">
            <h3 className="headerText">
              {entity !== "Staff" ? entity + "s" : entity}
            </h3>
            <Link
              className="headerLink"
              onClick={() => {
                let validatedUserRole = validateUserRole("add");
                if (validatedUserRole) {
                  setOpenModal(true);
                } else {
                  toast.error("Unauthorized Access!");
                }
              }}
            >
              ADD {entity.toUpperCase()}
            </Link>
          </div>
          <Space
            direction="vertical"
            size="middle"
            style={{
              display: "flex",
            }}
          >
            <Card className="tableCard">
              <div className="tableHeader">
                <div className="searchBar">
                  <Search
                    placeholder="Search here..."
                    allowClear
                    onSearch={(value) => {
                      onTableSearch(value);
                    }}
                    onChange={(e) => {
                      onTableSearch(e.target.value);
                    }}
                    style={{
                      width: 300,
                    }}
                  />
                  {filteredInfo && (
                    <Button
                      className="clearFiltersButton"
                      onClick={clearFilters}
                    >
                      Clear Filters
                    </Button>
                  )}
                </div>
                <div className="recordsCount">
                  <UnorderedListOutlined />
                  <p>
                    Records{": "}
                    {searchData.length > 0
                      ? searchData.length
                      : tableData.length}
                  </p>
                </div>
              </div>
              <hr />
              <TableActionComponent
                display={displayTableActionComponent}
                setDisplay={setDisplayTableActionComponent}
                positions={tableActionComponentPostions}
                editAction={() => {
                  let validatedUserRole = validateUserRole();
                  if (validatedUserRole) {
                    editTableRow();
                  } else {
                    toast.error("Unauthorized Access!");
                  }
                }}
                deleteAction={() => {
                  let validatedUserRole = validateUserRole("delete");
                  if (validatedUserRole) {
                    setOpenDeleteModal(true);
                  } else {
                    toast.error("Unauthorized Access!");
                  }
                }}
              />
              <Table
                className="table"
                columns={columns}
                dataSource={searchData.length > 0 ? searchData : tableData}
                onChange={onChange}
                pagination={{ pageSize: 5 }}
              />
            </Card>
          </Space>

          <ModalComponent
            openModal={openModal}
            setOpenModal={setOpenModal}
            width={"25%"}
            title={""}
            message={""}
            hasOk={false}
            hasCancel={false}
            formData={
              <ModalForm
                type={"Add Employee"}
                title={"Add Employee"}
                action={confirmFormSubmission}
                exitAction={confirmCancel}
              />
            }
          />

          <ModalComponent
            openModal={openDeleteModal}
            setOpenModal={setOpenDeleteModal}
            width={"40%"}
            title={`Delete ${entity}`}
            message={`Are you sure you want to delete ${
              entity + " " + currentRow?.Name
            }?`}
            action={deleteTableRow}
            exitAction={confirmCancel}
          />
        </div>
      )}
    </>
  );
};

export default EmployeesTablePage;
