import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { EncodeString } from "../../../services/TableUtilities";
import { showLoading, hideLoading } from "../../../redux/LoaderSlice";
import ApiService from "../../../services/ApiService";
import { ExtractNewEntityID } from "../../../services/DataProcessor";
import ErrorValidation from "../../../services/ErrorValidation";
import {
  Breadcrumb,
  Form,
  InputNumber,
  Select,
  Button,
  Row,
  Col,
  DatePicker,
} from "antd";
import dayjs from "dayjs";
import { ArrowLeftOutlined } from "@ant-design/icons";
import toast from "react-hot-toast";
import "./SalariesFormPage.scss";

const dateFormat = "YYYY-MM-DD";

const SalariesFormPage = ({ type }) => {
  const [form] = Form.useForm();
  const { salaryID } = useParams();
  var Buffer = require("buffer/").Buffer;
  let decodedStaffIDNPaymentIndexString = Buffer.from(
    salaryID,
    "base64"
  ).toString();
  let decodedStaffID = decodedStaffIDNPaymentIndexString.split("?")[0];
  let salaryPaymentIndex = decodedStaffIDNPaymentIndexString.split("?")[1];
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [salaryData, setSalaryData] = useState([]);
  const [staffData, setStaffData] = useState([]);
  const [dataIsReady, setDataIsReady] = useState(false);
  const [access, setAccess] = useState(true);
  const [editMode, setEditMode] = useState(false);
  const [allStaffData, setAllStaffData] = useState([]);
  const [staffSelectionDataReady, setStaffSelectionDataReady] = useState(false);
  const [selectedStaff, setSelectedStaff] = useState("");
  const [salaryStatus, setSalaryStatus] = useState("");
  const [designationType, setDesignationType] = useState("");
  const [staffDropDownOptions, setStaffDropDownOptions] = useState([]);

  let responseObj = localStorage.getItem("token");
  if (responseObj) {
    responseObj = JSON.parse(Buffer.from(responseObj, "base64").toString());
  }

  let role = null;
  if (responseObj?.employeeData) {
    role = responseObj?.employeeData?.Role;
  }

  const defaultStaffDropDownOptions = [
    { value: "disabled", label: "Select a Staff Member", disabled: true },
  ];

  const designationDropDownOptions = [
    { value: "disabled", label: "Select a Designation", disabled: true },
    { value: "Nurse", label: "Nurse" },
    { value: "House Maid", label: "House Maid" },
    { value: "Caretaker", label: "Caretaker" },
  ];

  const salaryStatusDropDownOptions = [
    { value: "disabled", label: "Select a Payment Status", disabled: true },
    { value: "Pending", label: "Pending" },
    { value: "Paid", label: "Paid" },
  ];

  const onStaffSelectChange = (value) => {
    setSelectedStaff(value);
  };

  const onSalaryStatusSelectChange = (value) => {
    setSalaryStatus(value);
  };

  const onDesignationSelectChange = (value) => {
    form.setFieldsValue({
      StaffSelection: {
        value: "disabled",
        label: "Select a Staff Member",
        disabled: true,
      },
    });
    parseStaffData(value);
  };

  let apiURL = "/staff/";
  let apiType = "put";

  const getAllStaffSalaryData = async () => {
    dispatch(showLoading());
    const apiResponse = await ApiService("get", apiURL + decodedStaffID);
    const apiData = apiResponse?.response;
    if (apiData) {
      setStaffData(apiData);
      setSelectedStaff(apiData?._id);
      if (apiData["Payment"]?.length > 0) {
        setSalaryData(apiData["Payment"][salaryPaymentIndex]);
      }
      setStaffSelectionDataReady(true);
      setStaffDropDownOptions([
        {
          value: "disabled",
          label: "Select Staff Member",
          disabled: true,
        },
      ]);
      setDataIsReady(true);
      dispatch(hideLoading());
    } else {
      const apiError = apiResponse?.error;
      let errorMessage = ErrorValidation(apiError?.response?.status);
      toast.error(errorMessage);
    }
  };

  const onFormSubmit = async (values) => {
    if (values) {
      let paymentObject = values;
      delete paymentObject["StaffSelection"];
      delete paymentObject["Designation"];
      dispatch(showLoading());
      // paymentObject["PaidOn"] = paymentObject["PaidOn"];
      let requestObj = await getSelectedStaffData();
      let paymentsArray = [];
      let currentPaymentIndex = 0;
      if (requestObj) {
        paymentsArray = requestObj["Payment"];
        if (type === "edit") {
          paymentsArray[salaryPaymentIndex] = paymentObject;
          currentPaymentIndex = salaryPaymentIndex;
        } else {
          paymentsArray.push(paymentObject);
        }
        requestObj["Payment"] = paymentsArray;
      }

      apiURL = "/staff/" + selectedStaff;

      const apiResponse = await ApiService(apiType, apiURL, requestObj);
      const apiData = apiResponse?.response;
      if (apiData) {
        if (type === "add") {
          toast.success("Salary was added Successfully!");
          dispatch(hideLoading());
          navigateToNewEntityPage(requestObj, currentPaymentIndex);
        } else {
          toast.success("Salary was updated Successfully!");
          dispatch(hideLoading());
        }
      } else {
        const apiError = apiResponse?.error;
        let errorMessage = ErrorValidation(apiError?.response?.status);
        toast.error(errorMessage);
        dispatch(hideLoading());
      }
    }
  };

  const onFinishFailed = (errorInfo) => {
    toast.error("Please fill all required form fields!");
  };

  const getAllStaffData = async () => {
    dispatch(showLoading());
    const apiResponse = await ApiService("get", apiURL);
    const apiData = apiResponse?.response;
    if (apiData) {
      setAllStaffData(apiData);
      // setStaffDropDownOptions(await parseStaffData());
      setStaffDropDownOptions([
        { value: "disabled", label: "Select a Staff Member", disabled: true },
      ]);

      if (type !== "edit") {
        setStaffSelectionDataReady(true);
        setDataIsReady(true);
      }
      dispatch(hideLoading());
    } else {
      const apiError = apiResponse?.error;
      let errorMessage = ErrorValidation(apiError?.response?.status);
      toast.error(errorMessage);
      dispatch(hideLoading());
    }
  };

  const getSelectedStaffData = async () => {
    const apiResponse = await ApiService("get", apiURL + selectedStaff);
    const apiData = apiResponse?.response;
    if (apiData) {
      return apiData;
    }
  };

  const parseStaffData = async (selectedStaffDesignation) => {
    let staffSelectionData = [
      { value: "disabled", label: "Select Staff Member", disabled: true },
    ];
    for (let i = 0; i < allStaffData.length; i++) {
      let record = allStaffData[i];
      let extractedStaff = {};
      if (record["Designation"] === selectedStaffDesignation) {
        extractedStaff["value"] = record["_id"];
        extractedStaff["label"] =
          record["Name"]?.FNAME + " " + record["Name"]?.LName;
        staffSelectionData.push(extractedStaff);
      }
    }
    setStaffDropDownOptions(staffSelectionData);
  };

  const navigateToNewEntityPage = async (requestObj, currentPaymentIndex) => {
    let extractedEntityID = await ExtractNewEntityID({
      apiURL: apiURL,
      primaryPhoneNumber: requestObj["Phone"]?.Primary,
    });

    if (extractedEntityID) {
      let encodedEntityID = EncodeString(
        extractedEntityID + "?" + currentPaymentIndex
      );
      navigate("/salaries/edit_salary/" + encodedEntityID, {
        replace: true,
      });
    }
  };

  const onStaffSearch = (value) => {};

  const filterStaffOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const validateStaffSelection = (value) => {
    let validatedStaffSelection = false;
    if (value?.value !== "disabled") {
      validatedStaffSelection = true;
    }

    return validatedStaffSelection;
  };

  const validateUserRole = (type = null) => {
    let validatedUserRole = false;
    if (role === "Admin" || role === "Accountant") {
      validatedUserRole = true;
    }

    return validatedUserRole;
  };

  useEffect(() => {
    if (role === "HR") {
      toast.error("Unauthorized Access!");
      navigate("/");
    } else {
      getAllStaffData();
      if (type === "edit") {
        getAllStaffSalaryData();
      }
    }
  }, []);

  return (
    <>
      {(type === "add" || dataIsReady) && (
        <div className="salariesFormContainer">
          <div className="salariesFormDiv">
            <Breadcrumb
              className="breadCrumb"
              items={[
                {
                  title: <a href="../../salaries">Salaries</a>,
                },
                {
                  title: type === "edit" ? "Edit Salary" : "Add Salary",
                },
              ]}
            />

            <div className={type === "edit" ? "header edit" : "header"}>
              <div className="headerStart">
                <ArrowLeftOutlined
                  className="backArrowButton"
                  onClick={() => {
                    navigate("/salaries");
                  }}
                />
                <h3 className="salariesFormTitle">
                  {type === "add"
                    ? "Add Salary"
                    : Object.keys(staffData).length > 0
                    ? staffData?.Name?.FNAME + " " + staffData?.Name?.LName
                    : "Edit Salary"}
                </h3>
              </div>

              <div>
                {editMode && (
                  <Button
                    className="salariesFormButton cancel"
                    danger
                    onClick={() => {
                      setEditMode(false);
                    }}
                  >
                    CANCEL
                  </Button>
                )}

                {!editMode && type === "edit" && (
                  <Button
                    className="salariesFormButton"
                    type="primary"
                    onClick={() => {
                      let validatedUserRole = validateUserRole();
                      if (validatedUserRole) {
                        setEditMode(true);
                      } else {
                        toast.error("Unauthorized Access");
                      }
                    }}
                    disabled={access ? false : true}
                  >
                    EDIT
                  </Button>
                )}

                {(type === "add" || editMode) && (
                  <Button
                    className="salariesFormButton"
                    type="primary"
                    htmlType={access ? "submit" : null}
                    onClick={() => {
                      form.submit();
                      if (editMode) {
                        setEditMode(false);
                      }
                      dispatch(showLoading());
                      setTimeout(() => {
                        dispatch(hideLoading());
                      }, 500);
                    }}
                    disabled={access ? false : true}
                  >
                    {editMode ? "SAVE" : "ADD"}
                  </Button>
                )}
              </div>
            </div>

            <Form
              form={form}
              className={type === "edit" ? "salariesForm edit" : "salariesForm"}
              layout="vertical"
              onFinish={onFormSubmit}
              onFinishFailed={onFinishFailed}
            >
              <div className="salariesFormFields">
                <div className="salariesFormFieldsScrollBox">
                  <Row
                    gutter={{
                      xs: 8,
                      sm: 16,
                      md: 24,
                      lg: 32,
                    }}
                  >
                    <Col className="gutter-row" span={6}>
                      <Form.Item
                        label="Designation"
                        name="Designation"
                        rules={[
                          {
                            required: true,
                            message: "Select a Designation",
                          },
                        ]}
                        initialValue={staffData?.Designation}
                      >
                        <Select
                          options={designationDropDownOptions}
                          defaultValue="disabled"
                          value={designationType}
                          onChange={onDesignationSelectChange}
                          disabled={type === "edit" ? true : false}
                        />
                      </Form.Item>
                    </Col>

                    <Col className="gutter-row" span={6}>
                      <Form.Item
                        label="Staff Member"
                        name="StaffSelection"
                        rules={[
                          {
                            validator: (_, value) => {
                              let validatedStaffSelection =
                                validateStaffSelection(value);
                              if (validatedStaffSelection) {
                                return Promise.resolve();
                              } else {
                                return Promise.reject(
                                  "Please Select Staff Member"
                                );
                              }
                            },
                            message: "Please Select Staff Member",
                          },
                          {
                            required: true,
                            message: "Select Staff Member",
                          },
                        ]}
                        initialValue={
                          type === "edit"
                            ? staffData?.Name?.FNAME +
                              " " +
                              staffData?.Name?.LName
                            : "disabled"
                        }
                      >
                        <Select
                          showSearch
                          placeholder="Select Staff Member"
                          optionFilterProp="children"
                          options={
                            staffDropDownOptions
                              ? staffDropDownOptions
                              : defaultStaffDropDownOptions
                          }
                          value={selectedStaff}
                          onChange={onStaffSelectChange}
                          onSearch={onStaffSearch}
                          filterOption={filterStaffOption}
                          disabled={type === "edit" ? true : false}
                        />
                      </Form.Item>
                    </Col>

                    <Col className="gutter-row" span={6}>
                      <Form.Item
                        label="Total Working Days"
                        name="TotalWorked"
                        initialValue={salaryData?.TotalWorked}
                        rules={[
                          {
                            required: true,
                            message: "Enter Total Working Days",
                          },
                        ]}
                      >
                        <InputNumber
                          placeholder="Total Working Days"
                          onChange={(value) => {}}
                          min={1}
                          step={1}
                          max={31}
                          width={500}
                          style={{ width: "100%" }}
                          disabled={
                            type === "edit" ? (editMode ? false : true) : false
                          }
                        />
                      </Form.Item>
                    </Col>

                    <Col className="gutter-row" span={6}>
                      <Form.Item
                        label="Fee Amount"
                        name="Amount"
                        initialValue={salaryData?.Amount}
                        rules={[
                          {
                            required: true,
                            message: "Enter Fee Amount",
                          },
                        ]}
                      >
                        <InputNumber
                          placeholder="Fee Amount"
                          onChange={(value) => {}}
                          min={0}
                          step={1}
                          max={1000000}
                          width={500}
                          style={{ width: "100%" }}
                          disabled={
                            type === "edit" ? (editMode ? false : true) : false
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row
                    gutter={{
                      xs: 8,
                      sm: 16,
                      md: 24,
                      lg: 32,
                    }}
                  >
                    <Col className="gutter-row" span={6}>
                      <Form.Item
                        label="Payment Date"
                        name="PaidOn"
                        rules={[
                          {
                            required: true,
                            message: "Select Payment Date",
                          },
                        ]}
                        initialValue={
                          salaryData?.PaidOn
                            ? dayjs((salaryData?.PaidOn).toString(), dateFormat)
                            : null
                        }
                      >
                        <DatePicker
                          disabled={
                            type === "edit" ? (editMode ? false : true) : false
                          }
                          format={dateFormat}
                        />
                      </Form.Item>
                    </Col>

                    <Col className="gutter-row" span={6}>
                      <Form.Item
                        label="Payment Status"
                        name="PaymentStatus"
                        rules={[
                          {
                            required: true,
                            message: "Select a Payment Status",
                          },
                        ]}
                        initialValue={salaryData?.PaymentStatus}
                      >
                        <Select
                          options={salaryStatusDropDownOptions}
                          defaultValue="disabled"
                          value={salaryStatus}
                          onChange={onSalaryStatusSelectChange}
                          disabled={
                            // salaryData?.PaymentStatus === "Paid"
                            //   ? true
                            //   : type === "edit"
                            //   ? editMode
                            //     ? false
                            //     : true
                            //   : false
                            type === "edit" ? (editMode ? false : true) : false
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              </div>
            </Form>
          </div>
        </div>
      )}
    </>
  );
};

export default SalariesFormPage;
