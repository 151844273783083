import React, { useState } from "react";
import { Button, Modal } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import "./ModalComponent.scss";

function ModalComponent({
  openModal,
  setOpenModal,
  width,
  title,
  message,
  action,
  exitAction,
  buttonObj,
  hasOk = true,
  hasCancel = true,
  hasCustomButton = false,
  customButtonText = "",
  customButtonTrigger = null,
  formData = null,
}) {
  return (
    <>
      <Modal
        icon={<ExclamationCircleFilled />}
        title={title}
        centered
        width={width}
        open={openModal}
        onOk={() => {
          if (hasOk) {
            action();
            setOpenModal(false);
          }
        }}
        onCancel={() => {
          if (hasCancel) {
            exitAction();
            setOpenModal(false);
          }
        }}
        closable={false}
        footer={(_, { OkBtn, CancelBtn }) => (
          <>
            {hasCustomButton && (
              <Button
                type="primary"
                onClick={() => {
                  const validResponse = customButtonTrigger();
                  // console.log(validResponse);
                  if (validResponse) {
                    setOpenModal(false);
                  }
                }}
              >
                {customButtonText}
              </Button>
            )}
            {hasCancel && <CancelBtn className="modalCancelButton" />}
            {hasOk && <OkBtn />}
          </>
        )}
      >
        <p className="modalMessage">{message}</p>
        {formData}
      </Modal>
    </>
  );
}

export default ModalComponent;
