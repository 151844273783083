import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import "./Staff.scss";
import { useDispatch } from "react-redux";
import { hideLoading, showLoading } from "../../redux/LoaderSlice";

function Staff() {
  const dispatch = useDispatch();
  const entityName = "Staff";

  useEffect(() => {
    dispatch(showLoading());
    setTimeout(() => {
      dispatch(hideLoading());
    }, 500);
  }, []);

  return (
    <div className="staff">
      <Outlet context={entityName} />
    </div>
  );
}

export default Staff;
