import React from "react";
import { render } from "react-dom";
import App from "./App";
import { Provider } from "react-redux";
import Store from "./redux/Store";

const root = document.getElementById("root");
render(
  <Provider store={Store}>
    <App />
  </Provider>,
  root
);
