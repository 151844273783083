import React from "react";
import "./SpinnerComponent.scss";

const SpinnerComponent = () => {
  return (
    <div className="spinner-parent">
      <div className="spinner-border" role="status"></div>
    </div>
  );
};

export default SpinnerComponent;
