import ApiService from "../services/ApiService";

const DataProcessor = async (records) => {
  let processedRecords = [];
  await records.forEach((record, index) => {
    let processedRecord = {};
    processedRecord.key = index;
    for (let [key, value] of Object.entries(record)) {
      if (
        typeof value === "object" &&
        !Array.isArray(value) &&
        value !== null
      ) {
        let joinedValue = "";
        let delimiter = "";

        for (let [childKey, childValue] of Object.entries(value)) {
          if (childValue !== "") {
            if (joinedValue !== "") {
              if (key === "Name") {
                delimiter = " ";
              }
              if (key === "Phone") {
                delimiter = " / ";
              }
              if (key === "Address") {
                delimiter = ", ";
              }

              joinedValue = joinedValue + delimiter + childValue;
            } else {
              joinedValue = childValue;
            }
          }
        }
        processedRecord[key] = joinedValue;
      } else {
        processedRecord[key] = value;
      }
    }
    processedRecords.push(processedRecord);
  });
  return processedRecords;
};

const FormatInput = (event, form, type = null) => {
  let formatObj = {};
  let key = event.target.id;
  let inputValue = event.target.value.trim();
  const inputArray = inputValue.split(" ");

  if (type !== "email") {
    if (inputArray.length > 1) {
      inputArray.forEach((word, index) => {
        inputArray[index] = word.charAt(0).toUpperCase() + word.slice(1);
      });
      let newInputValue = inputArray.join(" ");
      inputValue = newInputValue;
    } else {
      inputValue = inputValue.charAt(0).toUpperCase() + inputValue.slice(1);
    }
  }

  formatObj[key] = inputValue;
  form.setFieldsValue(formatObj);
};

const FormObjectProcessor = (formValues) => {
  let requestObj = {};
  for (let [key, value] of Object.entries(formValues)) {
    if (key.includes("-")) {
      let parentChildKeys = key.split("-");
      let parentKey = parentChildKeys[0];
      let childKey = parentChildKeys[1];

      if (value === undefined) {
        value = "";
      }

      if (parentKey in requestObj) {
        let valueObj = requestObj[parentKey];
        valueObj[childKey] = value;
      } else {
        let valueObj = {};
        valueObj[childKey] = value;
        requestObj[parentKey] = valueObj;
      }
    } else {
      requestObj[key] = value;
    }
  }

  return requestObj;
};

const ValidateExperience = (value, form) => {
  let validatedExperience = false;
  if (/^[0-9.]+$/.test(value)) {
    if (value.includes(".")) {
      let valueArray = value?.split(".");

      if (valueArray[1].charAt(0).includes("0")) {
        valueArray[1] = valueArray[1]?.replace("0", "");
        form.setFieldsValue({
          Experience:
            valueArray[1] === "" ? valueArray[0] : valueArray.join("."),
        });
      }

      if (
        valueArray.length === 2 &&
        parseInt(valueArray[0]) <= 50 &&
        parseInt(valueArray[1]) <= 11
      ) {
        validatedExperience = true;
      }
    } else {
      if (parseInt(value) <= 50 || value === "" || value === null) {
        validatedExperience = true;
      }
    }
  }
  return validatedExperience;
};

const ExtractNewEntityID = async (newEntityData) => {
  let extractedEntityID = null;
  const apiResponse = await ApiService("get", newEntityData?.apiURL);
  const apiData = apiResponse?.response;

  if (apiData) {
    for (let i = 0; i < apiData.length; i++) {
      let record = apiData[i];
      if (record["Phone"]?.Primary === newEntityData?.primaryPhoneNumber) {
        extractedEntityID = record["_id"];
        break;
      }
    }
  }

  return extractedEntityID;
};

export {
  DataProcessor,
  FormObjectProcessor,
  FormatInput,
  ValidateExperience,
  ExtractNewEntityID,
};
