import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { hideLoading, showLoading } from "../../../redux/LoaderSlice";
import ApiService from "../../../services/ApiService";
import { DataProcessor } from "../../../services/DataProcessor";
import { DataFilter, TableSearch } from "../../../services/DataFilter";
import { EncodeString } from "../../../services/TableUtilities";
import ErrorValidation from "../../../services/ErrorValidation";
import { Table, Input, Button, Card, Space, Tag } from "antd";
import { MoreOutlined, UnorderedListOutlined } from "@ant-design/icons";
import TableActionComponent from "../../../components/tableActionComponent/TableActionComponent";
import ModalComponent from "../../../components/modalComponent/ModalComponent";
import { mkConfig, generateCsv, download } from "export-to-csv";
import toast from "react-hot-toast";
import "./PatientsTablePage.scss";

const { Search } = Input;
const csvConfig = mkConfig({
  filename: "Patients_Data",
  useKeysAsHeaders: true,
});

const PatientsTablePage = ({ entity }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { filterPage } = useParams();
  const Buffer = require("buffer/").Buffer;
  const [dataIsReady, setDataIsReady] = useState(false);
  const [entityTableFilters, setEntityTableFilters] = useState([]);
  const [filterIsReady, setFilterIsReady] = useState(false);
  const [filteredInfo, setFilteredInfo] = useState({});
  const [filteredData, setFilteredData] = useState({});
  const [filteredRowCount, setFilteredRowCount] = useState(0);
  const [sortedInfo, setSortedInfo] = useState({});
  const [tableData, setTableData] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [currentRow, setCurrentRow] = useState(null);
  const [activeInactiveStatus, setActiveInactiveStatus] = useState("Inactive");
  const [openInactiveActiveModal, setOpenInactiveActiveModal] = useState(false);
  const [displayTableActionComponent, setDisplayTableActionComponent] =
    useState(false);
  const [tableActionComponentPostions, setTableActionComponentPostions] =
    useState([]);
  const [clickedElementClassName, setClickedElementClassName] = useState(null);

  let unprocessedData = [];
  let processedData = [];

  const entityName =
    entity !== "Staff" ? entity.toLowerCase() + "s" : entity.toLowerCase();
  const entityTableFilterColumnNames = [
    "Name",
    { Address: ["City", "State"] },
    "Status",
  ];

  let encodedEntityID = null;

  let responseObj = localStorage.getItem("token");
  if (responseObj) {
    responseObj = JSON.parse(Buffer.from(responseObj, "base64").toString());
  }
  let role = null;

  if (responseObj?.employeeData) {
    role = responseObj?.employeeData?.Role;
  }

  const getData = async () => {
    dispatch(showLoading());
    const apiURL = `/${entityName}/`;
    const apiResponse = await ApiService("get", apiURL);
    const apiData = apiResponse?.response;
    if (apiData) {
      unprocessedData = apiData;
      processedData = await DataProcessor(unprocessedData);
      processedData = processedData.map((item) => ({
        ...item,
        tableActionButtons: item?._id,
      }));
      setTableData(processedData);
      setEntityTableFilters(getTableFilters());
      setFilterIsReady(true);
      setDataIsReady(true);
      dispatch(hideLoading());
    } else {
      toast.error(`No ${entity} Data Found!`);
    }
  };

  const getTableFilters = () => {
    let tableFilters = [];
    entityTableFilterColumnNames.forEach((filterColumn) => {
      let tableFilter = [];
      if (
        typeof filterColumn === "object" &&
        !Array.isArray(filterColumn) &&
        filterColumn !== null
      ) {
        for (let [key, value] of Object.entries(filterColumn)) {
          tableFilter = DataFilter(unprocessedData, key, value);
          tableFilters[key] = tableFilter;
        }
      } else {
        tableFilter = DataFilter(processedData, filterColumn);
        tableFilters[filterColumn] = tableFilter;
      }
    });
    return tableFilters;
  };

  let columns = [
    // {
    //   title: "Sr. No.",
    //   dataIndex: "key",
    //   key: "key",
    //   render: (value, record) => (value += 1),
    // },
    {
      title: "ID",
      dataIndex: "PatientRollNo",
      key: "PatientRollNo",
      sorter: (a, b) => a.PatientRollNo - b.PatientRollNo,
    },
    {
      title: "Name",
      dataIndex: "Name",
      key: "Name",
      filters: filterIsReady ? entityTableFilters["Name"] : null,
      onFilter: (value, record) => onTableFilter(value, record),
      filteredValue: filteredInfo.Name || null,
      sorter: (a, b) => a.Name.length - b.Name.length,
      sortDirections: ["descend"],
      render: (value, record) => (
        (encodedEntityID = EncodeString(record?._id)),
        (
          <Link
            className="tableRowLink"
            to={`../patients/=/edit_patient/${encodedEntityID}`}
          >
            {value}
          </Link>
        )
      ),
    },
    {
      title: "Client",
      dataIndex: "ClientName",
      key: "ClientName",
      sorter: (a, b) => a.ClientName.length - b.ClientName.length,
      sortDirections: ["descend"],
    },
    {
      title: "Phone",
      dataIndex: "Phone",
      key: "Phone",
      sorter: (a, b) => a.Phone - b.Phone,
    },
    {
      title: "Diagnosis",
      dataIndex: "Diagnosis",
      key: "Diagnosis",
      render: (value, record) =>
        value.split(",")?.length > 0 ? (
          value.split(",")?.map((item, index) => {
            return (
              <Tag key={index} color="cyan">
                {item}
              </Tag>
            );
          })
        ) : (
          <Tag color="cyan">{value}</Tag>
        ),
      width: 150,
    },
    {
      title: "Requirements",
      dataIndex: "TypeOfWork",
      key: "TypeOfWork",
      render: (value, record) =>
        value.split(",")?.length > 0 ? (
          value.split(",")?.map((item, index) => {
            return (
              <Tag key={index} color="purple">
                {item}
              </Tag>
            );
          })
        ) : (
          <Tag color="purple">{value}</Tag>
        ),
      width: 150,
    },
    {
      title: "Languages",
      dataIndex: "Languages",
      key: "Languages",
      width: 100,
      render: (value, record) =>
        value.split(",")?.length > 0 ? (
          value.split(",")?.map((item, index) => {
            return (
              <Tag key={index} color="blue">
                {item}
              </Tag>
            );
          })
        ) : (
          <Tag color="blue">{value}</Tag>
        ),
    },
    {
      title: "Address",
      dataIndex: "Address",
      key: "Address",
      filters: filterIsReady ? entityTableFilters["Address"] : null,
      onFilter: (value, record) => onTableFilter(value, record),
      filteredValue: filteredInfo.Address || null,
      width: 150,
    },
    {
      title: "Status",
      dataIndex: "Status",
      key: "Status",
      filters: filterIsReady ? entityTableFilters["Status"] : null,
      onFilter: (value, record) => onTableFilter(value, record),
      filteredValue: filteredInfo.Status || null,
      render: (value, record) =>
        record?.Status === "Assigned" ? (
          <Tag color="green">{record?.Status}</Tag>
        ) : record?.Status === "Unassigned" ? (
          <Tag color="gold">{record?.Status}</Tag>
        ) : (
          <Tag color="red">{record?.Status}</Tag>
        ),
    },
    // {
    //   title: "Billing Cycle",
    //   dataIndex: "BillingCycle",
    //   key: "BillingCycle",
    // },
    // {
    //   title: "Payment ID",
    //   dataIndex: "PaymentID",
    //   key: "PaymentID",
    // },
    {
      title: "Action",
      dataIndex: "tableActionButtons",
      key: "operation",
      render: (value, record) => (
        <div
          className="tableMenuDiv"
          onClick={(e) => {
            setDisplayTableActionComponent(!displayTableActionComponent);
            setTableActionComponentPostions(e.target.getBoundingClientRect());
            setCurrentRow(record);
          }}
        >
          <MoreOutlined className="tableMenuIcon" />
        </div>
      ),
      width: 80,
    },
  ];

  const onChange = (pagination, filters, sorter, extra) => {
    setFilteredInfo(filters);
    setFilteredData(extra.currentDataSource);
    setSortedInfo(sorter);
    setFilteredRowCount(extra?.currentDataSource?.length);
  };

  const onTableSearch = (value) => {
    let searchResult = TableSearch(value, tableData);
    if (searchResult?.length > 0) {
      setSearchData(searchResult);
    } else {
      toast.error("No matching records found!");
    }
  };

  const onTableFilter = (value, record) => {
    if (record?.Name?.indexOf(value) === 0) {
      return true;
    }

    if (record?.Gender?.indexOf(value) === 0) {
      return true;
    }

    if (record?.Address?.includes(value) === true) {
      return true;
    }

    if (record?.Status?.indexOf(value) === 0) {
      return true;
    }
  };

  const clearFilters = () => {
    setFilteredInfo({});
    setFilteredData({});
    setSortedInfo({});
  };

  if (displayTableActionComponent && clickedElementClassName) {
    if (
      !clickedElementClassName.includes("SVGAnimatedString") &&
      !clickedElementClassName.includes("tableMenuDiv")
    ) {
      setDisplayTableActionComponent(false);
    }
  }

  const editTableRow = () => {
    let validatedUser = validateUserRole();
    if (validatedUser) {
      let encodedEntityID = EncodeString(currentRow?._id);
      navigate("../patients/=/edit_patient/" + encodedEntityID);
    } else {
      toast.error("Unauthorized Access!");
    }
  };

  const setPatientInactiveState = async () => {
    let validatedUserRole = validateUserRole("highLevelAction");
    if (validatedUserRole) {
      setOpenInactiveActiveModal(true);
      setActiveInactiveStatus("Inactive");
    } else {
      toast.error("Unauthorized Access!");
    }
  };

  const setPatientActiveState = async () => {
    let validatedUserRole = validateUserRole("highLevelAction");
    if (validatedUserRole) {
      setOpenInactiveActiveModal(true);
      setActiveInactiveStatus("Active");
    } else {
      toast.error("Unauthorized Access!");
    }
  };

  const getPatientData = async (patientID) => {
    let requestObject = {};
    const apiURL = `/patients/` + patientID;
    const apiResponse = await ApiService("get", apiURL);
    const apiData = apiResponse?.response;
    if (apiData) {
      requestObject = apiData;
    } else {
      toast.error(`No ${entity} Data Found!`);
    }
    return requestObject;
  };

  const updatePatientActiveInactive = async () => {
    dispatch(showLoading());
    let requestObj = await getPatientData(currentRow?._id);
    const apiURL = `/patients/` + currentRow?._id;
    if (activeInactiveStatus === "Active") {
      requestObj["Status"] = "Unassigned";
    } else {
      requestObj["Status"] = activeInactiveStatus;

      //Delete pending payments for inactive patient
      let Payments = requestObj["Payments"];
      let deletedPaymentIDList = [];
      for (let i = 0; i < Payments.length; i++) {
        if (Payments[i]?.PaymentStatus === "Pending") {
          deletedPaymentIDList.push(Payments[i]?.PaymentID);
        }
      }

      for (let j = 0; j < deletedPaymentIDList.length; j++) {
        let apiURL = "/patients/payments/" + deletedPaymentIDList[j];
        await ApiService("delete", apiURL, requestObj);
      }
    }

    const apiResponse = await ApiService("put", apiURL, requestObj);
    const apiData = apiResponse?.response;
    if (apiData) {
      toast.success(
        "Patient " +
          currentRow?.Name +
          " is set to " +
          activeInactiveStatus +
          "!"
      );
      window.location.reload();
    } else {
      const apiError = apiResponse?.error;
      let errorMessage = ErrorValidation(apiError?.response?.status);
      toast.error(errorMessage);
      dispatch(hideLoading());
    }
    dispatch(hideLoading());
  };

  const confirmCancel = () => {
    setOpenInactiveActiveModal(false);
  };

  const validateUserRole = (type = null) => {
    let validatedUserRole = false;
    if (type === "highLevelAction") {
      if (role === "Admin") {
        validatedUserRole = true;
      }
    } else {
      if (role === "Admin" || role === "HR" || role === "CRM") {
        validatedUserRole = true;
      }
    }

    return validatedUserRole;
  };

  const exportPatientsData = async () => {
    let parsedExportTableData = [];
    if (tableData.length === 0) {
      toast.error("No Table Data to export!");
    } else {
      let exportedData = filteredData.length > 0 ? filteredData : tableData;
      parsedExportTableData = JSON.parse(JSON.stringify(exportedData));
      for (let i = 0; i < parsedExportTableData.length; i++) {
        let patientRecord = parsedExportTableData[i];
        for (let [key, value] of Object.entries(patientRecord)) {
          if (key === "PatientRollNo") {
            patientRecord["Patient_Roll_Number"] =
              patientRecord["PatientRollNo"];
            delete patientRecord["PatientRollNo"];
          }
          if (key === "ClientName") {
            patientRecord["Client_Name"] = patientRecord["ClientName"];
            delete patientRecord["ClientName"];
          }
          if (key === "InitialBillingCycle") {
            patientRecord["Billing_Cycle"] =
              patientRecord["InitialBillingCycle"];
            delete patientRecord["InitialBillingCycle"];
          }
          if (key === "InitialAmount") {
            patientRecord["Amount"] = patientRecord["InitialAmount"];
            delete patientRecord["InitialAmount"];
          }
          if (key === "TypeOfWork") {
            patientRecord["Requirements"] = patientRecord["TypeOfWork"];
            delete patientRecord["TypeOfWork"];
          }
          if (key === "_id") {
            delete patientRecord["_id"];
          }
          if (key === "key") {
            delete patientRecord["key"];
          }
          if (key === "BillingCycleHistory") {
            delete patientRecord["BillingCycleHistory"];
          }
          if (key === "Payments") {
            delete patientRecord["Payments"];
          }
          if (key === "CurrentStaffID") {
            delete patientRecord["CurrentStaffID"];
          }
          if (key === "ExtraParams") {
            delete patientRecord["ExtraParams"];
          }
          if (key === "tableActionButtons") {
            delete patientRecord["tableActionButtons"];
          }
          if (key === "JoiningDate") {
            patientRecord["Joining_Date"] = value.substr(0, 10);
            delete patientRecord["JoiningDate"];
          }
        }
      }
    }
    return parsedExportTableData;
  };

  useEffect(() => {
    getData();
    if (filterPage === "unassigned_patients") {
      setFilteredInfo({ Address: null, Name: null, Status: ["Unassigned"] });
    } else {
      setFilteredInfo({
        Address: null,
        Name: null,
        Status: ["Unassigned", "Assigned"],
      });
    }
    setTimeout(() => {
      const falseClickToTriggerTableOnChange = document
        .getElementsByClassName("ant-table-column-has-sorters")
        ?.item(0);
      const currentPageTitle = document
        .getElementsByClassName("headerText")
        ?.item(0)?.innerHTML;

      /** This condition triggers the onchange event of the table to set the correct filtered data in export on every render.  **/
      if (falseClickToTriggerTableOnChange && currentPageTitle === "Patients") {
        falseClickToTriggerTableOnChange.click();
        falseClickToTriggerTableOnChange.click();
        falseClickToTriggerTableOnChange.click();
      }
      dispatch(hideLoading());
    }, 3000);
  }, []);

  return (
    <>
      {dataIsReady && (
        <div
          className="tablePage"
          onClick={(e) => {
            setClickedElementClassName(e.target.className.toString());
          }}
        >
          <div className="header">
            <h3 className="headerText">
              {entity !== "Staff" ? entity + "s" : entity}
            </h3>
            <Button
              className="headerButton"
              onClick={() => {
                let validatedUserRole = validateUserRole();
                if (validatedUserRole) {
                  navigate("../patients/=/add_patient/add");
                } else {
                  toast.error("Unauthorized Access!");
                }
              }}
            >
              ADD {entity.toUpperCase()}
            </Button>
          </div>
          <Space
            direction="vertical"
            size="middle"
            style={{
              display: "flex",
            }}
          >
            <Card className="tableCard">
              <div className="tableHeader">
                <div className="searchBar">
                  <Search
                    placeholder="Search here..."
                    allowClear
                    onSearch={(value) => {
                      onTableSearch(value);
                    }}
                    onChange={(e) => {
                      onTableSearch(e.target.value);
                    }}
                    style={{
                      width: 300,
                    }}
                  />
                  {filteredInfo && (
                    <Button
                      className="clearFiltersButton"
                      onClick={clearFilters}
                    >
                      Clear Filters
                    </Button>
                  )}

                  <Button
                    className="clearFiltersButton"
                    onClick={async () => {
                      const exportedPatientsData = await exportPatientsData();
                      if (exportedPatientsData.length > 0) {
                        const csv =
                          generateCsv(csvConfig)(exportedPatientsData);
                        download(csvConfig)(csv);
                      }
                    }}
                  >
                    Export
                  </Button>
                </div>
                <div className="recordsCount">
                  <UnorderedListOutlined />
                  <p>
                    Records{": "}
                    {searchData.length > 0
                      ? searchData.length
                      : filteredRowCount > 0
                      ? filteredRowCount
                      : tableData.length}
                  </p>
                </div>
              </div>
              <hr />

              <TableActionComponent
                type="patients"
                display={displayTableActionComponent}
                setDisplay={setDisplayTableActionComponent}
                positions={tableActionComponentPostions}
                editAction={editTableRow}
                deleteAction={null}
                inactiveAction={
                  currentRow?.Status === "Unassigned"
                    ? setPatientInactiveState
                    : null
                }
                activeAction={
                  currentRow?.Status === "Inactive"
                    ? setPatientActiveState
                    : null
                }
              />

              <Table
                className="table"
                columns={columns}
                dataSource={searchData.length > 0 ? searchData : tableData}
                onChange={onChange}
                pagination={{ pageSize: 3 }}
              />
            </Card>
          </Space>

          <ModalComponent
            openModal={openInactiveActiveModal}
            setOpenModal={setOpenInactiveActiveModal}
            width={"40%"}
            title={"Update Patient Status"}
            message={`Are you sure you want to change Status of ${entity} ${currentRow?.Name} to ${activeInactiveStatus} ?`}
            action={updatePatientActiveInactive}
            exitAction={confirmCancel}
          />
        </div>
      )}
    </>
  );
};

export default PatientsTablePage;
