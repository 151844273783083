import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import "./Patients.scss";
import { hideLoading, showLoading } from "../../redux/LoaderSlice";
import { useDispatch } from "react-redux";

function Patients() {
  const dispatch = useDispatch();
  const entityName = "Patient";

  useEffect(() => {
    dispatch(showLoading());
    setTimeout(() => {
      dispatch(hideLoading());
    }, 500);
  }, []);

  return (
    <div className="patients">
      <Outlet context={entityName} />
    </div>
  );
}

export default Patients;
