import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useDispatch } from "react-redux";
import { hideLoading, showLoading } from "../../redux/LoaderSlice";

const Salaries = () => {
  const dispatch = useDispatch();
  const entityName = "Lead";

  useEffect(() => {
    dispatch(showLoading());
    setTimeout(() => {
      dispatch(hideLoading());
    }, 500);
  }, []);

  return (
    <div className="leads">
      <Outlet context={entityName} />
    </div>
  );
};

export default Salaries;
