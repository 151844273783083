import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { hideLoading, showLoading } from "../../../redux/LoaderSlice";
import { FRONTEND_URL } from "../../../App";
import ApiService from "../../../services/ApiService";
import { DataProcessor } from "../../../services/DataProcessor";
import { DataFilter, TableSearch } from "../../../services/DataFilter";
import { EncodeString } from "../../../services/TableUtilities";
import ErrorValidation from "../../../services/ErrorValidation";
import { Table, Input, Button, Card, Space, Tabs, DatePicker, Tag } from "antd";
import dayjs from "dayjs";
import { MoreOutlined, UnorderedListOutlined } from "@ant-design/icons";
import TableActionComponent from "../../../components/tableActionComponent/TableActionComponent";
import toast from "react-hot-toast";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import ModalComponent from "../../../components/modalComponent/ModalComponent";
import { mkConfig, generateCsv, download } from "export-to-csv";
import "./PaymentsTablePage.scss";

const dateFormat = "DD-MM-YYYY";
const { Search } = Input;
const csvConfig = mkConfig({
  filename: "Payments_Data",
  useKeysAsHeaders: true,
});

const PaymentsTablePage = ({ entity }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { filterPage } = useParams();
  var Buffer = require("buffer/").Buffer;
  const [dataIsReady, setDataIsReady] = useState(false);
  const [entityTableFilters, setEntityTableFilters] = useState([]);
  const [filterIsReady, setFilterIsReady] = useState(false);
  const [filteredInfo, setFilteredInfo] = useState({});
  const [filteredData, setFilteredData] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const [tableData, setTableData] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [paymentTableData, setPaymentTableData] = useState([]);
  const [currentRow, setCurrentRow] = useState(null);
  const [modalMode, setModalMode] = useState("delete");
  const [openModal, setOpenModal] = useState(false);
  const [openConfirmPaymentModal, setOpenConfirmPaymentModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [downloadInvoice, setDownloadInvoice] = useState(false);
  const [displayTableActionComponent, setDisplayTableActionComponent] =
    useState(false);
  const [tableActionComponentPostions, setTableActionComponentPostions] =
    useState([]);
  const [clickedElementClassName, setClickedElementClassName] = useState(null);
  const exportPatientInvoiceRef = useRef();

  let responseObj = localStorage.getItem("token");
  if (responseObj) {
    responseObj = JSON.parse(Buffer.from(responseObj, "base64").toString());
  }

  let role = null;
  if (responseObj?.employeeData) {
    role = responseObj?.employeeData?.Role;
  }

  let unprocessedData = [];
  let processedData = [];
  let processedPaymentTableData = [];
  let encodedEntityIDnPaymentIndex = null;

  const entityName =
    entity !== "Staff" ? entity.toLowerCase() + "s" : entity.toLowerCase();
  const entityTableFilterColumnNames = [
    "Name",
    { Address: ["City", "State"] },
    "Designation",
  ];

  const tabItems = [
    {
      key: "1",
      label: "Upcoming Payments",
      children: "",
    },
    {
      key: "2",
      label: "Pending Payments",
      children: "",
    },
    {
      key: "3",
      label: "Payments History ",
      children: "",
    },
  ];

  const getData = async () => {
    dispatch(showLoading());
    const apiURL = `/patients/`;
    const apiResponse = await ApiService("get", apiURL);
    const apiData = apiResponse?.response;
    if (apiData) {
      unprocessedData = apiData;
      processedData = await DataProcessor(unprocessedData);
      processedPaymentTableData = await parsePaymentData(processedData);
      setTableData(processedPaymentTableData);
      setPaymentTableData(processedPaymentTableData);
      await onTableModeChange("Upcoming");
      setEntityTableFilters(getTableFilters());
      setFilterIsReady(true);
      setDataIsReady(true);
      dispatch(hideLoading());
    } else {
      toast.error(`No ${entity} Data Found!`);
    }
  };

  const parsePaymentData = async (processedData) => {
    if (processedData?.length > 0) {
      let processedPaymentTableData = [];
      processedData.map((item) => {
        if (item?.Payments?.length > 0) {
          item?.Payments?.map((paymentItem, paymentItemIndex) => {
            let paymentTableRecord = null;
            paymentTableRecord = {
              _id: item?._id,
              paymentID: paymentItem?.PaymentID,
              paymentItemIndex: paymentItemIndex,
              Name: item?.Name,
              Phone: item?.Phone,
              Status: paymentItem?.PaymentStatus,
              amount: paymentItem?.Amount,
              paidOn: paymentItem?.PaidOn,
              dueDate: paymentItem?.DueDate,
              billingCycle: paymentItem?.BillingCycle,
              billingCycleDuration: paymentItem?.BillingCycleDuration,
            };
            processedPaymentTableData.push(paymentTableRecord);
          });
        }
      });

      processedPaymentTableData.sort(function (a, b) {
        return new Date(b.paidOn) - new Date(a.paidOn);
      });

      processedPaymentTableData = processedPaymentTableData.map(
        (payment, index) => ({
          key: index,
          ...payment,
        })
      );
      return processedPaymentTableData;
    }
  };

  const getTableFilters = () => {
    let tableFilters = [];
    entityTableFilterColumnNames.forEach((filterColumn) => {
      let tableFilter = [];
      if (
        typeof filterColumn === "object" &&
        !Array.isArray(filterColumn) &&
        filterColumn !== null
      ) {
        for (let [key, value] of Object.entries(filterColumn)) {
          tableFilter = DataFilter(unprocessedData, key, value);
          tableFilters[key] = tableFilter;
        }
      } else {
        tableFilter = DataFilter(processedData, filterColumn);
        tableFilters[filterColumn] = tableFilter;
      }
    });
    return tableFilters;
  };

  let columns = [
    // {
    //   title: "Sr. No.",
    //   dataIndex: "key",
    //   key: "key",
    //   render: (value, record) => (value += 1),
    // },
    {
      title: "Patient Name",
      dataIndex: "Name",
      key: "Name",
      filters: filterIsReady ? entityTableFilters["Name"] : null,
      onFilter: (value, record) => onTableFilter(value, record),
      filteredValue: filteredInfo.Name || null,
      sorter: (a, b) => a.Name.length - b.Name.length,
      sortDirections: ["descend"],
      render: (value, record) => (
        (encodedEntityIDnPaymentIndex = EncodeString(
          record?._id + "?" + record?.paymentItemIndex
        )),
        (
          <Link
            className="tableRowLink"
            to={`../payments/=/edit_payment/${encodedEntityIDnPaymentIndex}`}
          >
            {value}
          </Link>
        )
      ),
    },
    {
      title: "Contact",
      dataIndex: "Phone",
      key: "Phone",
      filters: filterIsReady ? entityTableFilters["Phone"] : null,
      onFilter: (value, record) => onTableFilter(value, record),
      filteredValue: filteredInfo.Phone || null,
      sorter: (a, b) => a.Phone.length - b.Phone.length,
    },
    {
      title: "Fees",
      dataIndex: "amount",
      key: "Amount",
      sorter: (a, b) => a.amount.length - b.amount.length,
      render: (value, record) => "₹ " + record?.amount,
    },
    {
      title: "Billing Cycle",
      dataIndex: "billingCycle",
      key: "billingCycle",
      sorter: (a, b) => a.billingCycle.length - b.billingCycle.length,
      render: (value, record) => record?.billingCycle + " Days",
    },
    {
      title: "Duration",
      dataIndex: "billingCycleDuration",
      key: "billingCycleDuration",
    },
    {
      title: "Due Date",
      dataIndex: "dueDate",
      key: "dueDate",
      sorter: (a, b) => a.dueDate.length - b.dueDate.length,
      render: (value) => dayjs(value).format(dateFormat),
    },
    {
      title: "Paid On",
      dataIndex: "paidOn",
      key: "paidOn",
      sorter: (a, b) => a.paidOn.length - b.paidOn.length,
      render: (value) => dayjs(value).format(dateFormat),
    },
    {
      title: "Status",
      dataIndex: "Status",
      key: "Status",
      filters: filterIsReady ? entityTableFilters["Status"] : null,
      onFilter: (value, record) => onTableFilter(value, record),
      filteredValue: filteredInfo.Status || null,
      sorter: (a, b) => a.Status.length - b.Status.length,
      render: (value, record) =>
        record?.Status === "Paid" ? (
          <Tag color="green">{record?.Status}</Tag>
        ) : (
          <Tag color="red">{record?.Status}</Tag>
        ),
    },
    {
      title: "Action",
      dataIndex: "tableActionButtons",
      key: "operation",
      render: (value, record) => (
        <div
          className="tableMenuDiv"
          onClick={(e) => {
            setDisplayTableActionComponent(!displayTableActionComponent);
            setTableActionComponentPostions(e.target.getBoundingClientRect());
            setCurrentRow(record);
          }}
        >
          <MoreOutlined className="tableMenuIcon" />
        </div>
      ),
      width: 80,
    },
  ];

  const onChange = (pagination, filters, sorter, extra) => {
    setFilteredInfo(filters);
    setFilteredData(extra.currentDataSource);
    setSortedInfo(sorter);
  };

  const onTabChange = async (key) => {
    if (key === "1") {
      onTableModeChange("Upcoming");
    }

    if (key === "2") {
      onTableModeChange("Pending");
    }

    if (key === "3") {
      onTableModeChange("History");
    }
  };

  const onTableSearch = (value) => {
    let searchResult = TableSearch(value, paymentTableData);
    if (searchResult?.length > 0) {
      setSearchData(searchResult);
    } else {
      toast.error("No matching records found!");
    }

    if (value === "") {
      setSearchData([]);
      // setPaymentTableData(tableData);
    }
  };

  const onTableFilter = (value, record) => {
    if (record?.Name?.indexOf(value) === 0) {
      return true;
    }

    if (record?.Address?.includes(value) === true) {
      return true;
    }

    if (record?.Designation?.indexOf(value) === 0) {
      return true;
    }
  };

  const clearFilters = () => {
    setFilteredInfo({});
    setFilteredData({});
    setSortedInfo({});
  };

  if (displayTableActionComponent && clickedElementClassName) {
    if (
      !clickedElementClassName.includes("SVGAnimatedString") &&
      !clickedElementClassName.includes("tableMenuDiv")
    ) {
      setDisplayTableActionComponent(false);
    }
  }

  const onMonthChange = (date, dateString) => {
    if (!date) {
      // getData();
    } else {
      let selectedMonth = dayjs(dateString).month() + 1;
      let selectedYear = dayjs(dateString).year();
      let paymentTableMonthData = [];
      paymentTableData.filter((payment) => {
        let paymentDate = dayjs(payment?.paidOn).format(dateFormat).split("-");
        let paymentMonth = paymentDate[1];
        let paymentYear = paymentDate[2];
        if (
          parseInt(paymentMonth) === selectedMonth &&
          parseInt(paymentYear) === selectedYear
        ) {
          payment["key"] = paymentTableMonthData.length;
          paymentTableMonthData.push(payment);
        }
      });
      if (paymentTableMonthData.length > 0) {
        setPaymentTableData(paymentTableMonthData);
      } else {
        toast.error("No payments were found for selected month!");
      }
    }
  };

  const onTableModeChange = async (tableMode) => {
    let paymentTableTempModeData = [];
    let currentTableData = null;

    if (!dataIsReady && tableData.length === 0 && tableMode === "Upcoming") {
      currentTableData = processedPaymentTableData;
    } else {
      currentTableData = tableData;
    }

    let currentDate = dayjs();
    let paymentDueDate = null;

    currentTableData?.filter((payment) => {
      let paymentStatus = payment?.Status;

      if (
        (tableMode === "Pending" || tableMode === "Upcoming") &&
        paymentStatus === "Pending"
      ) {
        paymentDueDate = dayjs(payment?.dueDate);
        let datesDifference = paymentDueDate.diff(currentDate, "day");

        if (tableMode === "Pending" && datesDifference < 0) {
          payment["key"] = paymentTableTempModeData.length;
          paymentTableTempModeData.push(payment);
        }

        if (tableMode === "Upcoming" && datesDifference >= 0) {
          payment["key"] = paymentTableTempModeData.length;
          paymentTableTempModeData.push(payment);
        }
      }

      if (tableMode === "History" && paymentStatus === "Paid") {
        payment["key"] = paymentTableTempModeData.length;
        paymentTableTempModeData.push(payment);
      }
    });

    if (paymentTableTempModeData.length > 0) {
      setPaymentTableData(paymentTableTempModeData);
    } else {
      setPaymentTableData([]);
    }
  };

  const editTableRow = () => {
    let validatedUser = validateUserRole();
    if (currentRow?.Status === "Paid" && role !== "Admin") {
      validatedUser = false;
    }
    if (validatedUser) {
      let encodedEntityIDnPaymentIndex = EncodeString(
        currentRow?._id + "?" + currentRow?.paymentItemIndex
      );
      navigate("../payments/=/edit_payment/" + encodedEntityIDnPaymentIndex);
    } else {
      toast.error("Unauthorized Access!");
    }
  };

  const confirmCancel = () => {
    setOpenModal(false);
  };

  const exportPatientInvoice = () => {
    let validatedUser = validateUserRole();
    if (validatedUser) {
      dispatch(showLoading());
      if (currentRow && currentRow?.Status === "Paid") {
        setDownloadInvoice(true);
        document.getElementById("contentBody").style.overflow = "hidden";
        setTimeout(() => {
          html2canvas(exportPatientInvoiceRef.current).then((canvas) => {
            const imgData = canvas.toDataURL("img/png");
            const doc = new jsPDF("p", "mm", "a4");
            const componentWidth = doc.internal.pageSize.getWidth();
            const componentHeight = doc.internal.pageSize.getHeight();
            doc.addImage(imgData, "PNG", 0, 2, componentWidth, componentHeight);
            doc.save(`Patient_${currentRow?.Name}_Salary_Slip.pdf`);
          });
          document.getElementById("contentBody").style.overflow = "";
          setDownloadInvoice(false);
          toast.success("Patient Invoice exported successfully");
          dispatch(hideLoading());
        }, 100);
      } else {
        toast.error("Please confirm this payment to download the Invoice!");
        dispatch(hideLoading());
      }
    } else {
      toast.error("Unauthorized Access!");
    }
  };

  const confirmPayment = async () => {
    let validatedUser = validateUserRole();
    if (validatedUser) {
      if (currentRow?.Status !== "Paid") {
        setModalMode("confirm");
        setOpenConfirmPaymentModal(true);
      }
    } else {
      toast.error("Unauthorized Access!");
    }
  };

  const confirmedPayment = async () => {
    if (currentRow?.Status !== "Paid") {
      dispatch(showLoading());
      // console.log(`confirmedPayment`, currentRow);
      let apiURL = "/patients/" + currentRow?._id;
      const apiResponse = await ApiService("get", apiURL);
      let apiData = apiResponse?.response;
      if (apiData) {
        let requestObject = apiData;
        let paymentsArray = requestObject["Payments"];
        let paymentObjectToUpdate = paymentsArray[currentRow?.paymentItemIndex];
        paymentObjectToUpdate["PaymentStatus"] = "Paid";
        paymentsArray.splice(
          currentRow?.paymentItemIndex,
          1,
          paymentObjectToUpdate
        );
        requestObject["Payments"] = paymentsArray;
        let apiURLPayment = "/patients/" + currentRow?._id;
        const apiResponseConfirmPayment = await ApiService(
          "put",
          apiURLPayment,
          requestObject
        );
        const apiDataConfirmPayment = apiResponseConfirmPayment?.response;
        if (apiDataConfirmPayment) {
          toast.success("Payment confirmed for Staff " + currentRow?.Name);
          getData();
          dispatch(hideLoading());
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        } else {
          const apiError = apiResponseConfirmPayment?.error;
          let errorMessage = ErrorValidation(apiError?.response?.status);
          dispatch(hideLoading());
          toast.error(errorMessage);
        }
      }
    }
  };

  const deleteTableRow = async () => {
    let validatedUser = validateUserRole();
    if (currentRow?.Status === "Paid" && role !== "Admin") {
      validatedUser = false;
    }
    if (validatedUser) {
      setOpenDeleteModal(true);
    } else {
      toast.error("Unauthorized Access!");
    }
  };

  const confirmDeletePayment = async () => {
    let apiURL = "/patients/payments/" + currentRow?.paymentID;
    let apiResponse = await ApiService("delete", apiURL);
    let apiData = apiResponse?.response;
    if (apiData) {
      dispatch(hideLoading());
      toast.success("Staff Payment was deleted successfully!");
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } else {
      const apiError = apiResponse?.error;
      let errorMessage = ErrorValidation(apiError?.response?.status);
      dispatch(hideLoading());
      toast.error(errorMessage);
    }
    setOpenDeleteModal(false);
  };

  const validateUserRole = (type = null) => {
    let validatedUserRole = false;
    if (role === "Admin" || role === "Accountant") {
      validatedUserRole = true;
    }

    return validatedUserRole;
  };

  const exportPaymentsData = async () => {
    let parsedExportTableData = [];
    if (paymentTableData.length === 0) {
      toast.error("No Table Data to export!");
    } else {
      let exportedData =
        filteredData.length > 0 ? filteredData : paymentTableData;
      parsedExportTableData = JSON.parse(JSON.stringify(exportedData));
      for (let i = 0; i < parsedExportTableData.length; i++) {
        let paymentRecord = parsedExportTableData[i];
        for (let [key, value] of Object.entries(paymentRecord)) {
          if (key === "_id") {
            delete paymentRecord["_id"];
          }
          if (key === "key") {
            delete paymentRecord["key"];
          }
          if (key === "Payment") {
            delete paymentRecord["Payment"];
          }
          if (key === "ExtraParams") {
            delete paymentRecord["ExtraParams"];
          }
          if (key === "tableActionButtons") {
            delete paymentRecord["tableActionButtons"];
          }
          if (key === "amount") {
            paymentRecord["Amount"] = paymentRecord["amount"];
            delete paymentRecord["amount"];
          }
          if (key === "billingCycle") {
            paymentRecord["Billing_Cycle"] = paymentRecord["billingCycle"];
            delete paymentRecord["billingCycle"];
          }
          if (key === "billingCycleDuration") {
            paymentRecord["Duration"] = paymentRecord["billingCycleDuration"];
            delete paymentRecord["billingCycleDuration"];
          }
          if (key === "dueDate") {
            paymentRecord["Due_Date"] = value.substr(0, 10);
            delete paymentRecord["dueDate"];
          }
          if (key === "paidOn") {
            paymentRecord["Paid_On"] = value.substr(0, 10);
            delete paymentRecord["paidOn"];
          }
          if (key === "paymentID") {
            delete paymentRecord["paymentID"];
          }
          if (key === "paymentItemIndex") {
            delete paymentRecord["paymentItemIndex"];
          }
        }
      }
    }
    return parsedExportTableData;
  };

  useEffect(() => {
    if (role === "HR") {
      toast.error("Unauthorized Access!");
      navigate("/");
    } else {
      if (filterPage === "payments_due") {
        dispatch(showLoading());
        setTimeout(() => {
          const pendingTab = document
            .getElementsByClassName("ant-tabs-nav-list")
            ?.item(0)
            ?.children?.item(1);

          if (pendingTab) {
            pendingTab.click();
          }
          dispatch(hideLoading());
        }, 2000);
      }
      getData();
    }
  }, []);

  return (
    <>
      {dataIsReady && (
        <div
          className="paymentsTablePage"
          onClick={(e) => {
            setClickedElementClassName(e.target.className.toString());
          }}
        >
          <div className="header">
            <h3 className="headerText">
              {entity !== "Staff" ? entity + "s" : entity}
            </h3>
            <Button
              className="headerButton"
              onClick={() => {
                let validatedUser = validateUserRole();
                if (validatedUser) {
                  navigate("../payments/=/add_payment/add");
                } else {
                  toast.error("Unauthorized Access!");
                }
              }}
            >
              ADD PAYMENT
            </Button>
          </div>

          <Tabs
            className="tabs"
            defaultActiveKey="1"
            items={tabItems}
            onChange={onTabChange}
          />

          <Space
            direction="vertical"
            size="middle"
            style={{
              display: "flex",
            }}
          >
            <Card className="tableCard">
              <div className="tableHeader">
                <div className="searchBar">
                  <Search
                    placeholder="Search here..."
                    allowClear
                    onSearch={(value) => {
                      onTableSearch(value);
                    }}
                    onChange={(e) => {
                      onTableSearch(e.target.value);
                    }}
                    style={{
                      width: 300,
                    }}
                  />
                  {filteredInfo && (
                    <Button
                      className="clearFiltersButton"
                      onClick={clearFilters}
                    >
                      Clear Filters
                    </Button>
                  )}
                  <Button
                    className="clearFiltersButton"
                    onClick={async () => {
                      const exportedPaymentsData = await exportPaymentsData();
                      if (exportedPaymentsData.length > 0) {
                        const csv =
                          generateCsv(csvConfig)(exportedPaymentsData);
                        download(csvConfig)(csv);
                      }
                    }}
                  >
                    Export
                  </Button>
                  <div className="paymentMonthPicker">
                    <DatePicker onChange={onMonthChange} picker="month" />
                  </div>
                </div>
                <div className="recordsCount">
                  <UnorderedListOutlined />
                  <p>
                    Records{": "}
                    {searchData.length > 0
                      ? searchData.length
                      : paymentTableData?.length}
                  </p>
                </div>
              </div>
              <hr />

              {downloadInvoice && currentRow && (
                <div
                  ref={exportPatientInvoiceRef}
                  className={downloadInvoice ? "invoice export" : "invoice"}
                >
                  <div className="patientDataFieldsContainer">
                    <img
                      className="imageLogo"
                      src={`${FRONTEND_URL + "/assets/logo-banner.png"}`}
                      alt="logo"
                    />
                    <h3 className="header">Patient Invoice</h3>
                    <div className="patientDataFields">
                      <p>
                        <span>Name: </span>
                        {currentRow?.Name}
                      </p>

                      <p>
                        <span>Contact: </span>
                        {currentRow?.Phone}
                      </p>
                      <p>
                        <span>Billing Cycle: </span>
                        {currentRow?.billingCycle + " days"}
                      </p>
                      <p>
                        <span>Amount: </span>
                        {"₹ " + currentRow?.amount + ".00"}
                      </p>
                      <p>
                        <span>Payment Due Date: </span>
                        {dayjs(currentRow?.dueDate).format(dateFormat)}
                      </p>
                      <p>
                        <span>Date of Payment: </span>
                        {dayjs(currentRow?.paidOn).format(dateFormat)}
                      </p>
                    </div>
                  </div>
                </div>
              )}

              <TableActionComponent
                display={displayTableActionComponent}
                setDisplay={setDisplayTableActionComponent}
                positions={tableActionComponentPostions}
                type="salaries"
                editAction={editTableRow}
                deleteAction={deleteTableRow}
                paidAction={
                  currentRow?.Status !== "Paid" ? confirmPayment : null
                }
                downloadAction={
                  currentRow?.Status === "Paid" ? exportPatientInvoice : null
                }
              />

              <Table
                className="table"
                columns={columns}
                dataSource={
                  searchData.length > 0 ? searchData : paymentTableData
                }
                onChange={onChange}
                loading={dataIsReady ? !dataIsReady : dataIsReady}
                pagination={{ pageSize: 3 }}
              />
            </Card>
          </Space>

          <ModalComponent
            openModal={
              modalMode === "confirm"
                ? openConfirmPaymentModal
                : openDeleteModal
            }
            setOpenModal={
              modalMode === "confirm"
                ? setOpenConfirmPaymentModal
                : setOpenDeleteModal
            }
            width={"40%"}
            title={
              modalMode === "confirm"
                ? `Confirm Salary Payment ?`
                : `Delete Salary Payment?`
            }
            message={
              modalMode === "confirm"
                ? `Confirming this will mark this particular payment for Staff ` +
                  currentRow?.Name +
                  ` as paid in the system.`
                : `Are you sure you want to delete this payment for Staff ` +
                  currentRow?.Name +
                  `?`
            }
            action={
              modalMode === "confirm" ? confirmedPayment : confirmDeletePayment
            }
            exitAction={confirmCancel}
          />
        </div>
      )}
    </>
  );
};

export default PaymentsTablePage;
