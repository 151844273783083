import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { loaderSlice } from "./LoaderSlice";

const rootReducer = combineReducers({
  loader: loaderSlice.reducer,
});

const Store = configureStore({
  reducer: rootReducer,
});

export default Store;
